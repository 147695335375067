export interface CustomerOrder {
    s_no?: number;
    customerId: number;
    customerOrderId?: number;
    custName?: string;
    customerPurchaseOrder: string;
    customerPurchaseDate: Date;
    items: Array<CustomerOrderItem>;
    status: number;
}

export const defaultCustomerOrder: CustomerOrder = {
    customerId: 0,
    custName: '',
    customerPurchaseOrder: '',
    customerPurchaseDate: new Date(),
    items: [],
    status: 0
};

export interface CustomerOrderItem {
    s_no?: number;
    prodId: number;
    prodName?: string;
    prodCode?: string;
    prodSpecs?: string;
    orderQty: number;
    orderUom: number;
    uomCode?: string;
    deliveryDate: Date;
    deliveryLocation: string;
    orderPacking: number;
    packName?: string;
    prodOrdPrice: number;
    hsnCode: string;
    gst: string;
    dispatchInst: string;
}

export const defaultCustomerOrderItem: CustomerOrderItem = {
    prodId: 0,
    prodSpecs: '',
    prodName: '',
    orderQty: 0,
    orderUom: 0,
    deliveryDate: undefined,
    deliveryLocation: '',
    orderPacking: 0,
    prodOrdPrice: 0,
    hsnCode: '',
    gst: '',
    dispatchInst: ''
};
