export interface ProductSpecification {
    s_no?: number;
    prodId: number;
    prodCode: string;
    prodName: string;
    paramId: number;
    paramValue: string;
    paramUOMId: number;
    testMethod: string;
    specStatus: number;
    specsRemarks: string;
    paramCode: string;
    paramUOMDesc: string;
    paramDesc: string;
}

export const defaultProdSpec: ProductSpecification = {
    prodId: 0,
    prodCode: '',
    prodName: '',
    paramId: 0,
    paramValue: '',
    paramUOMId: 0,
    paramUOMDesc: '',
    testMethod: '',
    specStatus: 0,
    specsRemarks: '',
    paramCode: '',
    paramDesc: ''
};
