export interface ProcessStep {
    s_no?: number;
    pcstepId: number;
    pcstepName: string;
    status: number;
}

export const defaultProcessStep: ProcessStep = {
    pcstepId: 0,
    pcstepName: '',
    status: 0
};
