import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Subject } from 'rxjs';
import { CustomerType, ServiceActions } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerTypesService implements ServiceActions {

  public pageName = 'Customer Type';
  public dataList = new Subject<Array<CustomerType>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.customerTypeApi[apiOption];
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const dataArray: Array<CustomerType> = [];
          response.result.Customermaster.map(element => {
            element.status = Number(element.recStatus) === 1 ? true : false;
            dataArray.push(element);
          });
          this.dataList.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public addData(objData: CustomerType) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
  }

  private fillData(objData: CustomerType) {
    const fData = new FormData();
    fData.append('custtypeId', objData.custtypeId + '');
    fData.append('custtypeName', objData.custtypeName );
    fData.append('custtypeDesc', objData.custtypeDesc );
    fData.append('status', objData.status ? '1' : '0' );
    return fData;
  }

  public updateData(objData: CustomerType) {
    const fData = this.fillData(objData);
    return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
  }

  public deleteData(id: number) {
    const fData = new FormData();
    fData.append('custtypeId', id + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
  }

  public updateStatus(objData: CustomerType) {
    const fData = new FormData();
    fData.append('stat', objData.status ? '1' : '0');
    fData.append('custtypeId', objData.custtypeId + '');
    return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
  }

}
