export interface ItemClass {
    s_no?: number;
    itemClassId: number;
    itemClassCode: string;
    itemClassDesc: string;
    status: boolean;
}

export const defaultItemClass = {
    itemClassId: 0,
    itemClassCode: '',
    itemClassDesc: '',
    status: false
};
