import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  defaultMasterFormula,
  MasterFormula,
  ProductMaster,
  FormulaItem
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  MasterFormulaService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-master-formula',
  templateUrl: './master-formula.component.html',
  styleUrls: ['./master-formula.component.scss']
})
export class MasterFormulaComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<FormulaItem> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: MasterFormula = defaultMasterFormula;
  public itemDataObj: FormulaItem = { itemId: 0, quantity: 0, uomId: 0,is_intermediate:0 };
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public productMasterList: Array<SelectItem> = [];
  public selectedProduct: SelectItem;
  public itemMasterList: Array<SelectItem> = [];
  public selectedItem: SelectItem;
  public UOMList: Array<SelectItem> = [];
  public selectedUOM: SelectItem;
  public selectedItemUOM: SelectItem;

  public outputList: Array<{ label: string, value: string }> = [
    { label: 'Unit', value: '0' },
    { label: 'Batch', value: '1' }
  ];

  private output = '0';
  public set selectedOutput(value: string) {
    this.output = value;
    if (value === '0') {
      this.selectedUOM = undefined;
      this.dataObj.batchQty = 0;
      // this.dataObj.uomId = 0;
    }
  }
  public get selectedOutput() {
    return this.output;
  }

  public get isBatch() {
    // tslint:disable-next-line: triple-equals
    return this.dataObj.isBatch == '1';
  }

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'product Name', isVisible: true, isDefault: true },
    { field: 'isBatch', header: 'Output Type', isVisible: true, isDefault: true },
    { field: 'batchQty', header: 'Quantity', isVisible: true, isDefault: true },
    { field: 'UOM', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S.No' },
    { field: 'itemCode', header: 'Item Code' },
    { field: 'itemDesc', header: 'Item Description' },
    { field: 'quantity', header: 'Qty' },
    { field: 'UOM', header: 'UOM' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: MasterFormulaService,
    public util: UtilService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  private loadDropDownLists() {

    this.dataService.getProducts()
      .subscribe(data => {
        this.productMasterList = data.map(ele => {
          return { label: ele.productMasterName, value: ele };
        });
      });

    this.dataService.getItemMasters()
      .subscribe(data => {
        this.itemMasterList = data.map(ele => {
          return { label: ele.itemDesc, value: ele };
        });
      });

    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
      });
  }

  public onProductChange(event) {
    this.dataObj.prodId = (event.value as ProductMaster).productMasterId;
	this.dataService.getItemList((event.value as ProductMaster).productMasterId)
      .subscribe(data => {
        this.itemMasterList = data.map(ele => {
          return { label: ele.itemDesc, value: ele };
        });
      });
  }

  public onOutputChange(event) {
    this.dataObj.isBatch = event.value;
  }

  public onUOMChange(event) {
    this.dataObj.uomId = event.value.uomId;
  }

  public onItemChange(event) {
    this.itemDataObj.itemId = event.value.itemId;
    this.itemDataObj.itemCode = event.value.itemCode;
    this.itemDataObj.itemDesc = event.value.itemDesc;
	this.itemDataObj.is_intermediate = event.value.is_intermediate;
  }

  public onItemUOMChange(event) {
    this.itemDataObj.uomId = event.value.uomId;
    this.itemDataObj.UOM = event.value.uomDesc;
  }

  public addToItemList() {

    if (this.selectedItem === undefined ||
      this.selectedItemUOM === undefined ||
      this.itemDataObj.quantity === 0
    ) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide item and required data to add' });
    } else {

      const obj = Object.assign({}, this.itemDataObj);
      obj.s_no = this.itemDataArray.length + 1;
      const lst = [...this.itemDataArray];
      lst.push(obj);
      this.itemDataArray = lst;

      // Reset item selection data.
      this.selectedItem = undefined;
      this.selectedItemUOM = undefined;
      this.itemDataObj.quantity = 0;      
    this.itemDataObj.itemDesc = '';
	  this.itemDataObj.is_intermediate = 0;
    }
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultMasterFormula);
    this.itemDataArray = [];
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    // tslint:disable-next-line: triple-equals
    const prod = this.productMasterList.find(e => e.value.productMasterId == this.dataObj.prodId);
    if (prod) {
      this.selectedProduct = prod.value;
    }

    this.dataObj.UOM = '';
    // tslint:disable-next-line: triple-equals
    const uom = this.UOMList.find(e => e.value.uomId == this.dataObj.uomId);
    if (uom) {
      this.selectedUOM = uom.value;
      this.dataObj.UOM = uom.value.uomDesc;
    }
    // tslint:disable-next-line: triple-equals
    const outputType = this.isBatch ? 'Batch' : 'Unit';
    // tslint:disable-next-line: triple-equals
    const output = this.outputList.find(e => e.label == outputType);
    if (output) {
      this.selectedOutput = output.value;
    }

    this.itemDataObj.itemDesc = '';
    this.itemDataArray = this.dataObj.items;
    this.itemDataArray.forEach((ele, indx) => { ele.s_no = indx + 1; });
  }

  public updateData() {
    this.dataObj.items = this.itemDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: MasterFormula) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: MasterFormula) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public deleteItemRow(event) {
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.itemId === event.itemId), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.prodId === 0 ||
      (this.isBatch && this.dataObj.uomId === 0) ||
      (this.isBatch && this.dataObj.batchQty === 0) ||
      this.itemDataArray.length === 0) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.dataObj.prodId !== 0) {
      this.dataObj.items = this.itemDataArray;
      this.dataObj.uomId = this.isBatch ? this.dataObj.uomId : 0;
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Product details' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.selectedProduct = undefined;
    this.selectedUOM = undefined;
    this.selectedItemUOM = undefined;
    this.selectedItem = undefined;
    this.itemDataArray = [];
    this.itemDataObj.quantity = 0;
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultMasterFormula);
  }

}
