export interface ItemGrade {
    s_no?: number;
    itemGradeId: number;
    itemGradeCode: string;
    itemGradeDesc: string;
    status: boolean;
}

export const defaultItemGrade = {
    itemGradeId: 0,
    itemGradeCode: '',
    itemGradeDesc: '',
    status: false
};
