import { Injectable } from '@angular/core';
import { ApiActions } from '../../service/common/util.service';
import { Subject } from 'rxjs';
import { RefItemClass } from 'src/app/model/ref-master';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../service/common/app-config.service';
import { MsgService } from '../../service/common/msg.service';
import { CommonDataService } from '../../service/common/common-data.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RefItemClassService {

  
	public pageName = 'Item Class';
	public dataList = new Subject<Array<RefItemClass>>();


	constructor(
		private http: HttpClient,
		private config: AppConfigService,
		private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
	) { }

	private getUrl(apiOption: ApiActions) {
		return this.config.ipAddress + this.config.itemRefItemClassApi[apiOption];
	}

	public getDataRecords() {

		this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
		  .subscribe((response: any) => {
			if (response && response.result) {
			  const dataArray: Array<RefItemClass> = [];
			  response.result.RefItemClass.map(element => {
				element.status = element.itemClassStatus + '' === '1' ? true : false;
				dataArray.push(element);
			  });
			  this.dataList.next(dataArray);
			}
		  },
			err => {
			    this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
			});
	}

	public addData(objData: RefItemClass) {
		const fData = new FormData();
		fData.append('itemClassId', objData.itemClassId + '');
		fData.append('categoryCode', objData.itemClassCode);
		fData.append('categoryDesc', objData.itemClassDesc);
		return this.common.executeAction(fData, this.getUrl(ApiActions.Create), this.pageName);
	}

	public updateData(objData: RefItemClass) {
		const fData = new FormData();
		fData.append('itemClassId', objData.itemClassId + '');		
		fData.append('categoryCode', objData.itemClassCode);
		fData.append('categoryDesc', objData.itemClassDesc);
		return this.common.executeAction(fData, this.getUrl(ApiActions.Update), this.pageName);
	}
	public deleteData(id: number) {
		const fData = new FormData();
		fData.append('itemClassId', id + '');		
		return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
	}

	public updateStatus(objData: RefItemClass) {
			const fData = new FormData();
		fData.append('stat', objData.status ? '1' : '0');
		fData.append('itemClassId', objData.itemClassId + '');
		return this.common.executeAction(fData, this.getUrl(ApiActions.UpdateStatus), this.pageName);
	}
}
