export interface LotPriorityPlanning {
    s_no?: number;
    priorityNum: number;
	plan_id: number;
    customerId: number;
    customerOrderId: number;
    customerPurchaseOrder: string;
    custPODate: Date;
    custCode: string;
    prodCode: string;
    prodId: number;
    orderQuantity: number;
    UOM: string;
    numberOfLots: number;
    deliveryDate: Date;
    dispatchDate: Date;
    transitTime: number;
    dispatchedQty: number;
    batchSize: number;
    prodBatchesNumber: number;
    recWith: number;
    status: number;
	api_prod: number;
	api_id: number;
	finished_qty: number;
	balance_qty: number;
}
