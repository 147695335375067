import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Intermediates ,ProductMaster } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
import { CommonDataService } from '../common/common-data.service';


@Injectable({
  providedIn: 'root'
})
export class IntermediatesService {

    public pageName = 'Intermediates';
  public dataList = new Subject<Array<Intermediates>>();
 constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
	private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.intermediatesApi[apiOption];
  }


  public getDataTableRecords() {
    const dataArray: Array<Intermediates> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
			response.result.productMasterList.map(element => {
				dataArray.push(element);
			});
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
    public getCategories(): BehaviorSubject<Array<ProductMaster>> {
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.getTable];
    const dataArray: Array<ProductMaster> = [];
    const data = new BehaviorSubject<Array<ProductMaster>>([]);
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.productMasterList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
    public getApiproducts(pcId: number): BehaviorSubject<Array<ProductMaster>> {
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.UpdateStatus];
    const dataArray: Array<ProductMaster> = [];
	 const fData = new FormData();
    fData.append('pconfigId', pcId + '');
    const data = new BehaviorSubject<Array<ProductMaster>>([]);
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.productMasterList.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }
  public getDataRecords() {

    const dataArray: Array<Intermediates> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
			response.result.productMasterList.map(element => {
				dataArray.push(element);
			});
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objData: Intermediates) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }


  private Save(action: string, objData: Intermediates) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('make', objData.Make + '');
    fData.append('prodId', objData.productMasterId + '');

	//fData.append('intercepts', objData.items+ '');
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
	public deleteData(id: number) {
		const fData = new FormData();
		fData.append('prodId', id + '');
		return this.common.executeAction(fData, this.getUrl(ApiActions.Delete), this.pageName);
	}

}
