export class Productstepslog {
	
	s_id?: number;
	s_no?: number;
    plan_id: number;
	batch_id: number;
	prod_id: number;
	step_id: number;
	delay_id: number;
    pcstep_name: string;
	reason_for_delay: string;
    eqp_code: string;
	delay_desc: string;
	step_qty: number;
	pcparam_max: number;
	pcparam_min: number;
	step_running_status: number;
	rend_date:Date;
	actual_end_date:Date;
	
}

export const defaultProductstepslog:Productstepslog = {
	
s_id:  0,
plan_id:  0,
batch_id:  0,
prod_id:  0,
eqp_code: '',
delay_desc: '',
pcstep_name: '',
reason_for_delay: '',
step_id:  0,
delay_id:  0,
step_qty:  0,
rend_date: new Date,
actual_end_date: new Date,
pcparam_max: 0,
pcparam_min: 0,
step_running_status: 0
}

export interface ObserveProductSteps {
    s_no?: number;
obs_id : number;
obs_date: string;
obsparam_val: number;


}

export const defaultObserveProductSteps: ObserveProductSteps = {

obs_date: '',
obsparam_val:  0,
obs_id:  0
};