export interface UserRoles {
    s_no?: number;
    userRoleId: number;
    userRole: string;
    userRoleMngr: string;
    userRoleMenuIds: string;
    status: boolean;
}

export const defaultUserRoles: UserRoles = {
    userRoleId: 0,
    userRole: '',
    userRoleMngr: '',
    userRoleMenuIds: '',
    status: false
};
