export interface FieldSeperator {
    s_no?: number;
    fieldId: number;
    fieldName: string;
	fieldSymbal: string;
    status: number;

}
export const defaultFieldSeperator = {
    fieldId: 0,
    fieldName: '',
	fieldSymbal: '',
    status: 0

};