import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = 
[ {
    label: 'Navigation',
    isTitle: true
},
{
    label: 'Dashboard',
    icon: 'home',
    link: 'home'
},
    {
     // label: 'Admin',
      label: 'User Management',
      icon: 'user',
      subItems: [
        { label: 'Users', link: 'admin/user' },
        { label: 'Main Menu', link: 'admin/admin-menu'  },
        // { label: 'Sub Menu', link: 'home' },
        { label: 'User Roles', link: 'admin/user-roles'  },
      ]
    },
    {
      label: 'Masters',
      icon: 'package',
      subItems: [
        { label: 'Product Master', link: 'master/product-master'  },
        { label: 'Product Specification', link: 'master/product-specdoc'  },
        { label: 'Batch Formation', link: 'master/batch-identity'  },
        { label: 'Item Master', link: 'master/item-master'  },
        { label: 'Make(or) Buy', link: 'master/intermediates'  },
        { label: 'Equipment Master', link: 'master/equipment-master'  },
        { label: 'Customer Master', link: 'master/customer-master'  },
     //   { label: 'Unit Master', link: 'master/unit-master'  },
        { label: 'Unit Line Master', link: 'master/unit-line-master'  },
        { label: 'Master Formula', link: 'master/master-formula'  },
        { label: 'Process Configuration', link: 'master/process-config'  }
      ]
      
    },
    {
      label: 'Sub Masters',
      icon: 'codesandbox',
      subItems: [
        { label: 'Batch Fields', link: 'submaster/batch-fields'  },
        { label: 'Batch Seperator', link: 'submaster/batch-seperator'  },
               
        { label: 'Product Category', link: 'submaster/product-category'  },
        { label: 'Product Physical Form', link: 'submaster/product-physical-form'  },
        { label: 'Product Release Profile', link: 'submaster/product-grade'  },
        { label: 'Product Pharmacopiea', link: 'submaster/product-pharmacopiea'  },
        { label: 'Product Parameter', link: 'submaster/product-parameter'  },
        { label: 'Product Type', link: 'submaster/product-type'  },
        { label: 'Item Form', link: 'submaster/item-form'  },
        
        { label: 'Item Category', link: 'submaster/ref-item-category'  },
        { label: 'Item Class', link: 'submaster/ref-item-class'  },
        { label: 'Item Grade', link: 'submaster/ref-item-grade'  },
        { label: 'Item Type', link: 'submaster/item-type'  },
        { label: 'Equipment Type', link: 'submaster/equipment-type'  },
        { label: 'Equipment SubType', link: 'submaster/equipment-sub-type'  },
        { label: 'Line Master', link: 'submaster/line-master'  },
        { label: 'Customer Type', link: 'submaster/customer-type'  },
        { label: 'Process Configuration Steps', link: 'submaster/process-config-steps'  },
        { label: 'Process Configuration Params', link: 'submaster/process-config-params'  }
      ]
    },
    {
      label: 'Inventory',
      icon: 'server',
      subItems: [
        { label: 'Products Inventory', link: 'inventory/products-inventory'},
        { label: 'Raw Materials Inventory', link: 'inventory/raw-materials-inventory'},

      ]
    },
    {
      label: 'Production Scheduling',
      icon: 'clipboard',
      subItems: [
          {
          label: 'Shift Details',
          icon: 'arrow-right-circle',
          subItems: [
              { label: 'Unit wise Shift Timings', link: 'scheduling/unit-wise-shift-timing'  }
          ]
        },
        {
          label: 'Equipment Details',
          icon: 'arrow-right-circle',
          subItems: [
              { label: 'Equipment Operating Timing', link: 'scheduling/equipment-operating-timing'  },
              { label: 'Equipment Line Clearance', link: 'scheduling/equipment-line-clearance-timings'  }
          ]
        },
        { label: 'Customer Order', link: 'scheduling/customer-order'  },
        { label: 'Internal Order', link: 'scheduling/internal-order'  }, 
        { label: 'Order Prioritization', link: 'scheduling/sales-forecast'  },
        { label: 'LOT Priority Planning', link: 'scheduling/lot-priority-planning'  },
        { label: 'Batch Sequence Generator', link: 'scheduling/product-sequence-generator'  },
        { label: 'Batch Schedule', link: 'scheduling/process-scheduler'  },
        { label: 'Plan Comparison', link: 'scheduling/plan-comparison'  },
        {
          label: 'Daily Updates',
          icon: 'arrow-right-circle',
          subItems: [
              { label: 'Daily Production Log', link: 'scheduling/daily-production-log'  },
              { label: 'Dispatch Quantity', link: 'scheduling/dispatch-quantity'  },
          ]
        },
        
      ]
    } 
    
  ] ;
