import { Component, OnInit, OnDestroy } from '@angular/core';
import { UnitMaster, GridHeader, GridHeaderEx, defaultUnitMaster, ProductMaster } from 'src/app/model';
import { UtilService, MsgService, AppConfigService, UserType, UnitMasterService } from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unit-master',
  templateUrl: './unit-master.component.html',
  styleUrls: ['./unit-master.component.scss']
})
export class UnitMasterComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<UnitMaster>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: UnitMaster = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public productMasterList: Array<SelectItem> = [];
  public selectedList: Array<ProductMaster>;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'unitName', header: 'Name', isVisible: true, isDefault: true },
    { field: 'unitDesc', header: 'Description', isVisible: true, isDefault: true },
    { field: 'unitAddress', header: 'Address', isVisible: true, isDefault: true },
    { field: 'unitLicenseNo', header: 'License No', isVisible: true, isDefault: true },
    { field: 'gstinNo', header: 'GSTIN No', isVisible: true, isDefault: true },
    { field: 'noOfLines', header: 'NoOf Lines', isVisible: true, isDefault: true },
    { field: 'manufacturedProds', header: 'Manufactured Prods', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: UnitMasterService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {

    this.dataObj = Object.assign({}, defaultUnitMaster);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getProductMasterRecords().subscribe(data => {
      this.productMasterList = data.map(ele => {
        return { label: ele.productMasterName, value: ele };
      });
    });
  }

  public getProdName(id: string) {
    const prod = this.productMasterList.find(e => e.value.productMasterId + '' === id);
    return prod ? prod.value.productMasterName : '';
  }

  public onProductChange(event) {
    if (this.selectedList) {
      const ids = [];
      this.selectedList.forEach(e => {
        ids.push((e as ProductMaster).productMasterId);
      });
      this.dataObj.manufacturedProds = ids.join(',');
    }
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultUnitMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    const idList =  this.dataObj.manufacturedProds ?  this.dataObj.manufacturedProds.split(',') : [];
    const ids = [];
    for (const id of idList) {
      const item = this.productMasterList.find(e => e.value.productMasterId + '' === id);
      if (item) { ids.push(item.value); }
    }
    this.selectedList = ids;
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: UnitMaster) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: UnitMaster) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    // TODO: get all the list of prod join with comma.

    let result = false;
    if (this.dataObj.unitDesc.trim() === '' ||
      this.dataObj.unitName.trim() === '' ||
      this.dataObj.unitAddress.trim() === '' ||
      this.dataObj.unitLicenseNo.trim() === '' ||
      this.dataObj.manufacturedProds.trim() === '' ||
      this.dataObj.noOfLines === 0) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.dataObj.unitName.trim().length !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Unit code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.selectedList = [];
    this.dataObj = Object.assign({}, defaultUnitMaster);
  }
}
