import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UnitMaster
  , GridHeader
  , GridHeaderEx
  , UnitLineMaster
  , EquipmentMaster
  , defaultUnitLineMaster,
  LineMaster
} from 'src/app/model';
import {
  UtilService
  , MsgService
  , AppConfigService
  , UserType
  , UnitLineMasterService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unit-line-master',
  templateUrl: './unit-line-master.component.html',
  styleUrls: ['./unit-line-master.component.scss']
})
export class UnitLineMasterComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<UnitLineMaster>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: UnitLineMaster = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public unitMasterList: Array<SelectItem> = [];
  public selectedUnit: UnitMaster;
  public lineMasterListMain: Array<SelectItem> = [];
  public lineMasterList: Array<SelectItem> = [];
  public selectedLine: LineMaster;
  public equipmentMasterList: Array<SelectItem> = [];
  public selectedEquipmentList: Array<EquipmentMaster>;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'unitName', header: 'Unit Name', isVisible: true, isDefault: true },
    { field: 'lineName', header: 'Line Name', isVisible: true, isDefault: true },
    { field: 'equipmentNames', header: 'Equipment Names', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: UnitLineMasterService,
    public util: UtilService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultUnitLineMaster);

    this.loadDropDownLists();

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.dataService.getDataRecords();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getManufacturingUnits().subscribe(data => {
      this.unitMasterList = data.map(ele => {
        return { label: ele.unitName, value: ele };
      });
    });

    this.dataService.getEquipments().subscribe(data => {
      this.equipmentMasterList = data.map(ele => {
        return { label: ele.equipmentName+'  ('+ele.equipmentCode+ ') ', value: ele };
      });
    });

    this.dataService.getLineMaster().subscribe(data => {
      this.lineMasterListMain = data.map(ele => {
        return { label: ele.lineName, value: ele };
      });
    });
  }

  public onUnitChange(event) {
    this.dataObj.unitId = this.selectedUnit.unitId;
    this.loadLAsPerNoOfLines();
  }

  private loadLAsPerNoOfLines() {
    const lineCount = this.selectedUnit.noOfLines;
    const items = [];
    let indx = 0;
    while (indx < lineCount) {
      items.push(this.lineMasterListMain[indx++]);
    }
    this.lineMasterList = items;
  }

  public onLineChange(event) {
    this.dataObj.lineId = this.selectedLine.lineId;
  }

  public onEquipmentChange(event) {
    if (this.selectedEquipmentList) {
      const ids = [];
      this.selectedEquipmentList.forEach(e => {
        // tslint:disable-next-line: no-string-literal
        ids.push(e['equipmentId']);
      });
      this.dataObj.equipmentIds = ids.join(',');

      // console.log(this.dataObj, ids, this.selectedEquipmentList);
    }
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultUnitLineMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    // tslint:disable-next-line: triple-equals
    const unit = this.unitMasterList.find(e => e.value.unitId == this.dataObj.unitId);
    this.selectedUnit = unit ? unit.value : unit;

    this.loadLAsPerNoOfLines();

    // tslint:disable-next-line: triple-equals
    const line = this.lineMasterList.find(e => e.value.lineId == this.dataObj.lineId);
    this.selectedLine = line ? line.value : line;

    const idList = this.dataObj.equipmentIds.split(',');
    const ids = [];
    for (const id of idList) {
      const item = this.equipmentMasterList.find(e => e.value.equipmentId + '' === id);
      if (item) { ids.push(item.value); }
    }
    this.selectedEquipmentList = ids;
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: UnitLineMaster) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public deleteData(obj: UnitLineMaster) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.unitId === 0 ||
      this.dataObj.lineId === 0 ||
      this.dataObj.equipmentIds.trim() === '') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.dataObj.unitId !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Unit code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.selectedEquipmentList = [];
    this.selectedUnit = undefined;
    this.selectedLine = undefined;
    this.dataObj = Object.assign({}, defaultUnitLineMaster);
  }
}
