
export interface ProductionOrderSequence {
    s_no?: number;
    priorityNum: number;
    custordId: number;
    custPo: string;
    custPodate: Date;
    prodCode: string;
    prodId: number;
    ordQty: number;
    Lots: string;
    batchNum: string;
    noOfBatches: number;
    seqNos: string;
    planId: number;
    runNo: number;
    runStartDate: Date;
    runEndDate: Date;
    status: number;
}
