export interface ProductMaster {
    s_no?: number;
    productMasterId: number;
    productMasterCode: string;
    productMasterName: string;
    status: number;
    productCategoryId: number;
    productGradeId: number;
    pharmaCopiId: number;
    productUOM: number;
    productSpecDoc: File | string;
	productMsdsDoc: File | string;
    productForm: number;
	productForm_id: number;
    productCategoryDesc?: string | undefined;
	productFormdesc?: string | undefined;
    pharmaCopiDesc?: string | undefined;
    productGrade?: string | undefined;
    pharmaDesc?: string | undefined;
	items: Array<INTItem>;
}

export const defaultProductMaster = {
    productMasterId: 0,
    productMasterCode: '',
    productMasterName: '',
    productCategoryId: 0,
    productGradeId: 0,
    pharmaCopiId: 0,
    productUOM: 0,
    productSpecDoc: '',
	productMsdsDoc: '',
    pharmaCopiDesc: '',
    productForm: 0,
	productForm_id: 0,
    status: 0,
	items: []
};
export interface INTItem {
    s_no?: number;
    INTproductMasterCode: string;
    INTproductMasterName: string;
    INTproductMasterMake: string;
}

export const defaultINTItem: INTItem = {
    INTproductMasterCode: '',
    INTproductMasterName: '',
    INTproductMasterMake: ''
};
