import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  ProductMaster
  , GridHeaderEx
  , defaultProductMaster
  , GridHeader
  , ProductCategory
  , ProductGrade
  , ProductPharmacopiea
  , ProductPhysicalForm
  , ProductUOM
  , ProcessStep
  , ProcessParams
  , INTItem
  , defaultINTItem
} from 'src/app/model';
import { MsgService, ProductMasterService, UtilService, UserType, AppConfigService } from 'src/app/service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent implements OnInit, OnDestroy {
  @ViewChild('specDoc', { static: true }) specDoc: ElementRef;
  @ViewChild('MSDSDoc', { static: true }) MSDSDoc: ElementRef;

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ProductMaster>;
  public itemDataArray: Array<INTItem> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: ProductMaster = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public categoryList: Array<SelectItem> = [];
  public selectedCategory: SelectItem;
  public gradeList: Array<SelectItem> = [];
  public selectedGrade: SelectItem;
  public pharmacopieaList: Array<SelectItem> = [];
  public productphysicalformList: Array<SelectItem> = [];
  public selectedPharmacopiea: SelectItem;
  public selectedProductphysicalform: SelectItem;
  public UOMList: Array<SelectItem> = [];
  public selectedUOM: SelectItem;
  public showPDF = false;
  public pdfSrc = '';
public itemObj: INTItem = defaultINTItem;
  public processStepList: Array<SelectItem> = [];
  public selectedProcessStep: ProcessStep;

  public processParamList: Array<SelectItem> = [];
  public selectedProcessParam: ProcessParams;
  
    public equipmentList: Array<SelectItem> = [];
  public selectedEquipments: Array<ProductMaster> = [];
  
  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }
 public criticalityLevels: Array<SelectItem> =
    [{ label: 'Make', value: 'Make' }, { label: 'Buy', value:'Buy' }];
  public selectedCriticality = this.criticalityLevels[0].value;
  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	{ field: 'productMasterId', header: 'Id', isVisible: false, isDefault: true },
    { field: 'productMasterCode', header: 'Code', isVisible: true, isDefault: true },
    { field: 'productMasterName', header: 'Name', isVisible: true, isDefault: true },
    { field: 'productSpecDoc', header: 'Spec Doc', isVisible: true, isDefault: true },
	{ field: 'productMsdsDoc', header: 'Msds Doc', isVisible: true, isDefault: true },
    { field: 'productFormdesc', header: 'Form', isVisible: true, isDefault: true },
    { field: 'productCategoryDesc', header: 'Category', isVisible: true, isDefault: true },
    { field: 'pharmaCopiDesc', header: 'Copiea', isVisible: true, isDefault: true },
    { field: 'productGrade', header: 'Release Profile', isVisible: false, isDefault: true },
    { field: 'pharmaDesc', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

    public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'INTproductMasterCode', header: 'Intermediate Product Code' },
    { field: 'INTproductMasterName', header: 'Intermediate Product Name' }
 //   { field: 'INTproductMasterMake', header: 'Make/Buy' }
  ];
  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: ProductMasterService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {

    this.dataObj = Object.assign({}, defaultProductMaster);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataTableRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  public deleteItemRow(event) {
	//  console.log(event);
	//  console.log(this.itemDataArray);
    this.itemDataArray.splice( this.itemDataArray.findIndex(e => e.s_no === event.s_no), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }
  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getCategories()
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label: ele.categoryDesc, value: ele };
        });
      });

    this.dataService.getGrades()
      .subscribe(data => {
        this.gradeList = data.map(ele => {
          return { label: ele.gradeDesc, value: ele };
        });
      });

    this.dataService.getPharmacopiea()
      .subscribe(data => {
        this.pharmacopieaList = data.map(ele => {
          return { label: ele.pharmacopieaCode, value: ele };
        });
      });
	  
	this.dataService.getProductphysicalform()
      .subscribe(data => {
        this.productphysicalformList = data.map(ele => {
          return { label: ele.categoryDesc, value: ele };
        });
      });
	  
    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
      });
  }

  public onCategoryChange(event) {
    this.dataObj.productCategoryId = (event.value as ProductCategory).categoryId;
  }

  // public onGradeChange(event) {
    // this.dataObj.productGradeId = (event.value as ProductGrade).gradeId;
  // }

  public onPharmacopieaChange(event) {
    this.dataObj.pharmaCopiId = (event.value as ProductPharmacopiea).pharmacopieaId;
  }

 public onProductphysicalform(event) {
    this.dataObj.productForm_id = (event.value as ProductPhysicalForm).categoryId;
  }
  public onUmoChange(event) {
    this.dataObj.productUOM = (event.value as ProductUOM).uomId;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultProductMaster);
    if (event == null) {
      this.isAddDetails = true;
      this.specDoc.nativeElement.value = '';
      this.MSDSDoc.nativeElement.value = '';
      this.selectedGrade = undefined;
      this.selectedCategory = undefined;
      this.selectedPharmacopiea = undefined;
      this.selectedProductphysicalform = undefined;
      this.selectedUOM = undefined;
      this.dataObj = Object.assign({}, defaultProductMaster);
      this.itemDataArray = [];
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {

   this.dataService.getItemsList(this.dataObj.productMasterId)
      .subscribe(dataList => {
        this.itemDataArray = dataList.map((ele, indx) => {
          // tslint:disable-next-line: no-string-literal
          ele['s_no'] = indx + 1;
          return ele;
        });
      });
    if (this.dataObj.productCategoryId) {
      this.selectedCategory = this.categoryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.categoryId == this.dataObj.productCategoryId).value;
    }
    // if (this.dataObj.productGradeId) {
      // this.selectedGrade = this.gradeList
        // // tslint:disable-next-line: triple-equals
        // .find(e => e.value.gradeId == this.dataObj.productGradeId).value;
    // }

    if (this.dataObj.pharmaCopiId + '' !== '0') {
console.log(this.pharmacopieaList);
	this.selectedPharmacopiea = this.pharmacopieaList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.pharmacopieaId + '' == this.dataObj.pharmaCopiId + '').value;
    }
	if (this.dataObj.productForm_id + '' !== '0') {
		console.log(this.productphysicalformList);
      this.selectedProductphysicalform = this.productphysicalformList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.categoryId + '' === this.dataObj.productForm_id + '').value;
    }
    if (this.dataObj.productUOM + '' !== '0') {
      this.selectedUOM = this.UOMList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.uomId + '' == this.dataObj.productUOM + '').value;
    }
  }

  public updateData() {
	  this.dataObj.items = this.itemDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: ProductMaster) {
    this.dataObj = obj;
    this.isViewDetails = true;
	 this.dataService.getItemsList(this.dataObj.productMasterId)
      .subscribe(dataList => {
        this.itemDataArray = dataList.map((ele, indx) => {
          // tslint:disable-next-line: no-string-literal
          ele['s_no'] = indx + 1;
          return ele;
        });
      });
  }



  public isFormValid() {
    let result = false;
    if (this.dataObj.productMasterName.trim() === '' ||
      this.dataObj.productCategoryId === 0 ||
      this.dataObj.productGradeId === 0 ||
      this.dataObj.pharmaCopiId === 0 ||
	  this.dataObj.productForm_id === 0 ||
      this.dataObj.productUOM === 0 ||
      this.dataObj.productSpecDoc === undefined) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.productMasterId).subscribe((data) => {
		this.dataService.getDataTableRecords();
    });
	console.log(this.dataObj.productMasterId);
    this.isDeleteDetails = false;
  }
  
	public deleteData(obj: ProductMaster) {
		this.dataObj = obj;
		this.isDeleteDetails = true;
	}

  public saveAsDraft() {
    if (this.dataObj.productMasterName.trim().length !== 0) {
		this.dataObj.items = this.itemDataArray;
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Product Name' });
    }
  }

	private resetFormData() {
		this.isViewDetails = false;
		this.isEditShow = false;
		this.specDoc.nativeElement.value = '';
		this.MSDSDoc.nativeElement.value = '';
		this.dataService.getDataTableRecords();
		this.selectedGrade = undefined;
		this.selectedCategory = undefined;
		this.selectedPharmacopiea = undefined;
		this.selectedProductphysicalform = undefined;
		this.selectedUOM = undefined;
		this.dataObj = Object.assign({}, defaultProductMaster);
		this.itemDataArray = [];
	}

	public fileUpload(event) {
		const file = event.target.files[0];
		this.dataObj.productSpecDoc = file;
	}
	public fileMSDSUpload(event) {
		const file = event.target.files[0];
		this.dataObj.productMsdsDoc = file;
	}
	

  public viewPdf(file: string) {
    return this.config.ipAddress + '/uploads/specs_doc/' + file;
  }
   INTproductMasterCode: any = {};  
   INTproductMasterName: any = {};
  
  public addToItemList() {

    if ( 
      this.itemObj.INTproductMasterCode === undefined ||
      this.itemObj.INTproductMasterName === undefined
    ) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide valid data to add' });
    } else {
    //  this.itemObj.INTproductMasterCode = this.INTproductMasterCode;
     // this.itemObj.INTproductMasterName = this.INTproductMasterName;
      const tmp = Object.assign({}, this.itemObj);
      tmp.s_no = this.itemDataArray.length + 1;
      const list = [... this.itemDataArray];
      list.push(tmp);
      this.itemDataArray = list;
console.log(list);
      this.itemObj.INTproductMasterCode = undefined;
	  this.itemObj.INTproductMasterName = undefined;
      //this.itemObj.INTproductMasterMake = undefined;
    }
  }
  public onCriticalityChange(event) {
    this.itemObj.INTproductMasterMake = event.value;
  }
}
