export interface EquipmentType {
    s_no?: number;
    equipmentId: number;
    equipmentCode: string;
    equipmentDesc: string;
    status: boolean;
}

export const defaultEquipmentType = {
    equipmentId: 0,
    equipmentCode: '',
    equipmentDesc: '',
    status: false
};
