import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  CustomerOrder,
  defaultCustomerOrder,
  ProductUOM,
  Customer,
  CustomerOrderItem,
  PackMaster,
  defaultCustomerOrderItem
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  AppConfigService,
  CustomerOrderService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-order',
  templateUrl: './customer-order.component.html',
  styleUrls: ['./customer-order.component.scss']
})
export class CustomerOrderComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<any> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: CustomerOrder = defaultCustomerOrder;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public itemObj: CustomerOrderItem = Object.assign({}, defaultCustomerOrderItem);

  public productMasterList: Array<SelectItem> = [];
  public selectedProduct: SelectItem;

  public UOMList: Array<SelectItem> = [];
  public selectedUOM: ProductUOM;

  public customerList: Array<SelectItem> = [];
  public selectedCustomer: Customer;

  public packingList: Array<SelectItem> = [];
  public selectedPack: PackMaster;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'custName', header: 'Customer Name', isVisible: true, isDefault: true },
    { field: 'custCode', header: 'Customer Code', isVisible: true, isDefault: true },
    { field: 'customerPurchaseOrder', header: 'Purchase Order', isVisible: true, isDefault: true },
    { field: 'customerPurchaseDate', header: 'Purchase Order Date', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prodCode', header: 'Product Code', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'prodSpecs', header: 'Specifications', isVisible: true, isDefault: true },
    { field: 'orderQty', header: 'Qty', isVisible: true, isDefault: true },
    { field: 'uomCode', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'deliveryDate', header: 'Delivery Date', isVisible: true, isDefault: true },
    { field: 'deliveryLocation', header: 'Delivery Location', isVisible: true, isDefault: true },
    { field: 'packName', header: 'Packing', isVisible: true, isDefault: true },
    { field: 'prodOrdPrice', header: 'Price', isVisible: true, isDefault: true },
    { field: 'gst', header: 'GST', isVisible: true, isDefault: true },
    { field: 'hsnCode', header: 'HSN/SAC Code', isVisible: true, isDefault: true },
    { field: 'dispatchInst', header: 'Dispatch Instructions', isVisible: true, isDefault: true }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: CustomerOrderService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public onCustomerChange(event) {
    this.dataObj.customerId = event.value.custId;
    this.dataObj.custName = event.value.custName;
  }

  public onProductChange(event) {
    const prod = event.value;
    this.itemObj.prodId = prod.prod_id;
    this.itemObj.prodName = prod.productMasterName;
    this.itemObj.prodCode = prod.prod_code;
  }

  public onUOMChange(event) {
    this.itemObj.orderUom = event.value.uomId;
    this.itemObj.uomCode = event.value.uomCode;
  }

  public onPackingChange(event) {
    this.itemObj.orderPacking = event.value.packId;
    this.itemObj.packName = event.value.packName;
  }

  public deleteItemRow(event) {
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.s_no === event.s_no), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  private loadDropDownLists() {

    this.dataService.getProductsData()
      .subscribe(data => {
        this.productMasterList = data.map(ele => {
          return { label: ele.productMasterName, value: ele };
        });
      });

    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
      });

    this.dataService.getPackMasterData()
      .subscribe(data => {
        this.packingList = data.map(ele => {
          return { label: ele.packName, value: ele };
        });
      });

    this.dataService.getCustomers()
      .subscribe(data => {
        this.customerList = data.map(ele => {
          return { label: ele.custCode, value: ele };
        });
      });
  }

  public updateData() {
    this.dataObj.items = this.itemDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: CustomerOrder) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultCustomerOrder);

    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    const cust = this.customerList
      // tslint:disable-next-line: triple-equals
      .find(e => e.value.custId == this.dataObj.customerId);

    this.selectedCustomer = cust ? cust.value : undefined;

    this.itemDataArray = this.dataObj.items.map((ele, indx) => {
      // tslint:disable-next-line: no-string-literal
      ele['s_no'] = indx + 1;
      return ele;
    });

  }

  public deleteData(obj: CustomerOrder) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (
      this.dataObj.customerId === 0 ||
      this.dataObj.customerPurchaseOrder.length === 0 ||
      this.dataObj.customerPurchaseDate === undefined ||
      this.dataObj.items.length === 0
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  
  private isItemObjValid() {
    if (
      this.itemObj.prodId === 0 ||
      this.itemObj.orderUom === 0 ||
      this.itemObj.orderPacking === 0 ||
      this.itemObj.orderQty === 0 ||
      this.itemObj.gst.trim() === '' ||
      this.itemObj.hsnCode.trim() === '' ||
      this.itemObj.prodOrdPrice === 0 ||
      this.itemObj.deliveryLocation.trim() === '' ||
      this.itemObj.deliveryDate === undefined) { return false; }
      if ((this.itemObj.deliveryDate !== null && this.dataObj.customerPurchaseDate !== null) && this.itemObj.deliveryDate > this.dataObj.customerPurchaseDate) {
        return  true;
      }else{
        return false;
      }
    return true;
  }

  public addToItemList() {
    if (!this.isItemObjValid()) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide valid data to add' });
    } else {
      const tmp = Object.assign({}, this.itemObj);
      tmp.s_no = this.itemDataArray.length + 1;
      const list = [... this.itemDataArray];
      list.push(tmp);
      this.itemDataArray = list;

      // Reset all the properties.
      this.selectedUOM = undefined;
      this.selectedProduct = undefined;
      this.selectedPack = undefined;
      this.itemObj = Object.assign({}, defaultCustomerOrderItem);
    }
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    this.dataObj.items = this.itemDataArray;
    if (this.dataObj.customerId !== 0 && this.dataObj.customerPurchaseOrder.trim() !== '') {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Process config details' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.selectedProduct = undefined;
    this.itemDataArray = [];
    this.selectedCustomer = undefined;
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultCustomerOrder);
    this.itemObj = Object.assign({}, defaultCustomerOrderItem);
  }

}
