import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeader
  , ProductSpecification
  , GridHeaderEx
  , ProductMaster
  , ProductUOM,
  ProductParameter,
  defaultProdSpec,
  defaultProductMaster
} from 'src/app/model';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  MsgService
  , ProductSpecificationService
  , UtilService
  , AppConfigService
  , UserType
} from 'src/app/service';

@Component({
  selector: 'app-product-specification',
  templateUrl: './product-specification.component.html',
  styleUrls: ['./product-specification.component.scss']
})
export class ProductSpecificationComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ProductSpecification>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: ProductSpecification = undefined;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public productMasterList: Array<SelectItem> = [];
  public selectedProductMaster: SelectItem;
  public UOMList: Array<SelectItem> = [];
  public selectedUOM: SelectItem;
  public parameterList: Array<SelectItem> = [];
  public selectedParameter: SelectItem;

  public showPDF = false;
  public pdfSrc = '';

  public productObjData: ProductMaster = defaultProductMaster;

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }
  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product Desc', isVisible: true, isDefault: true },
    { field: 'paramCode', header: 'Parameter', isVisible: true, isDefault: true },
    { field: 'paramValue', header: 'Value', isVisible: true, isDefault: true },
    { field: 'paramUOMCode', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'testMethod', header: 'Test Method', isVisible: true, isDefault: true },
    { field: 'specsRemarks', header: 'Remarks', isVisible: true, isDefault: true },
    { field: 'specStatus', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: ProductSpecificationService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultProdSpec);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.dataService.dataList.subscribe(data => {
      this.dataArray = [];
      if (data && data.length) {
        this.dataArray = data
          .map((e, indx) => {
            e.s_no = indx + 1;
            const action = this.util.getRoleLevel(e.specStatus);
            // tslint:disable-next-line: no-string-literal
            e['canDelete'] = action.canDelete;
            // tslint:disable-next-line: no-string-literal
            e['canEdit'] = action.canEdit;
            return e;
          });
      }
    });
    this.dataService.getProductSpecRecords();
    this.dataService.getProductMasterRecords().subscribe(data => {
      this.productMasterList = data.map(ele => {
        return { label: ele.productMasterName, value: ele };
      });
    });

    this.dataService.getParameters().subscribe(val => {
      this.parameterList = val.map(ele => {
        return { label: ele.parameterDesc, value: ele };
      });
    });

    this.dataService.getUOMCodes().subscribe(data => {
      this.UOMList = data.map(ele => {
        return { label: ele.uomCode, value: ele };
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  public onProductMasterChange(event: SelectItem) {
    this.productObjData = event.value as ProductMaster;
    this.dataObj.prodId = this.productObjData.productMasterId;
  }

  public onUmoChange(event: SelectItem) {
    this.dataObj.paramUOMId = (event.value as ProductUOM).uomId;
  }

  public onParameterChange(event: SelectItem) {
    this.dataObj.paramId = (event.value as ProductParameter).parameterId;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.prodId === 0 ||
      this.dataObj.paramId === 0 ||
      this.dataObj.paramUOMId === 0 ||
      this.dataObj.paramValue.trim() === '' ||
      this.dataObj.testMethod.trim() === '' ||
      this.dataObj.specsRemarks.trim() === '') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultProdSpec);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      // tslint:disable-next-line: triple-equals
      this.productObjData = this.productMasterList.filter(e => e.value.productMasterId == this.dataObj.prodId)[0].value;
      // tslint:disable-next-line: triple-equals
      this.selectedProductMaster = this.productMasterList.filter(e => e.value.productMasterId == this.dataObj.prodId)[0].value;
      // tslint:disable-next-line: triple-equals
      const UOM = this.UOMList.find(e => e.value.uomId + '' == this.dataObj.paramUOMId + '');
      // console.log(this.dataObj, this.UOMList, this.parameterList, UOM);
      this.selectedUOM = UOM ? UOM.value : undefined;
      // tslint:disable-next-line: triple-equals
      const parameter = this.parameterList.find(e => e.value.parameterId + '' == this.dataObj.paramId + '');
      this.selectedParameter = parameter ? parameter.value : undefined;
    }
  }

  public viewData(obj: ProductSpecification) {
    this.dataObj = obj;
    // console.log('from View:', this.dataObj, this.productMasterList);
    // tslint:disable-next-line: triple-equals
    this.productObjData = this.productMasterList.filter(e => e.value.productMasterId == this.dataObj.prodId)[0].value;
    this.isViewDetails = true;
  }

  public saveAsDraft() {
    if (this.dataObj.prodId !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => {
        this.resetFormData();
      });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided required data.' });
    }
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.selectedProductMaster = undefined;
    this.selectedParameter = undefined;
    this.selectedUOM = undefined;
    this.dataObj = Object.assign({}, defaultProdSpec);
    this.productObjData = Object.assign({}, defaultProductMaster);
    this.dataService.getProductSpecRecords();
  }

  public cancelDlg() {
    this.resetFormData();
  }

  public viewPdf() {
    const file = this.productObjData.productSpecDoc.toString();
    if (this.productObjData.productSpecDoc != 'null' && file && file.length) {
      return this.config.ipAddress + 'uploads/specs_doc/' + file;
    } else {
      return '#';
    }
  }
}
