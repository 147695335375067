import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductionOrderSequence, GridHeader } from 'src/app/model';
import { UserType
  , MsgService
  , UtilService
  , AppConfigService
  , ProductionOrderSequenceGeneratorService } from 'src/app/service';

@Component({
  selector: 'app-production-order-sequence-generator',
  templateUrl: './production-order-sequence-generator.component.html',
  styleUrls: ['./production-order-sequence-generator.component.scss']
})
export class ProductionOrderSequenceGeneratorComponent implements OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ProductionOrderSequence>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj = {};
  public orderFromDate: Date = new Date();
  public orderToDate: Date = new Date();

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  public confirmCols: Array<GridHeader> = [
    { field: 's_no', header: 'Run Number' },
    { field: 'custPo', header: 'Customer Purchase Order' },
    { field: 'custPodate', header: 'OrderDate' },
    { field: 'prodCode', header: 'Product Code' },
    { field: 'Lots', header: 'LOT No.' },
    { field: 'noOfBatches', header: 'No.Of Batches' },
    { field: 'batchNum', header: 'Sequence Numbers' },
    { field: 'runStartDate', header: 'Run StartDate' },
    { field: 'runEndDate', header: 'Run EndDate' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: ProductionOrderSequenceGeneratorService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    // this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
	  const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getTableData() {
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getDataRecords(this.orderFromDate, this.orderToDate);
    }
  }

  public updateData() {
    this.dataService.updateData(this.dataArray).subscribe((data) => {
      this.getTableData();
    });
  }

  public cancelDlg() {
    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
    this.orderToDate = new Date();
    this.dataArray = [];
  }

}
