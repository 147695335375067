export interface UnitLineMaster {
    s_no?: number;
    unitLineId: number;
    unitId: number;
    lineId: number;
    equipmentIds: string;
    equipmentNames?: string;
    unitName?: string;
    lineName?: string;
    status: number;
}

export const defaultUnitLineMaster = {
    unitLineId: 0,
    unitId: 0,
    lineId: 0,
    lineName: '',
    equipmentIds: '',
    status: 0
};
