import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProductUOM,
  ProcessConfig,
  EquipmentMaster,
  ProcessParams,
  ProcessStep,
  PCItem
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProcessConfigService {

  public pageName = 'Process Configuration';
  public dataList = new Subject<Array<ProcessConfig>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router:Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.processConfigApi[apiOption];
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.UnitsOfMeasurement;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getProductsData() {
    const resultList = new Subject<Array<ProcessConfig>>();
    const url = this.config.ipAddress + this.config.processConfigProductsApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productList) {
          const list = response.result.productList.map(e => {
            //  tslint:disable-next-line: no-string-literal
            e['stdBatchSize'] = 0;
            return e;
          });
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getEquipmentMasters() {
    const resultList = new Subject<Array<EquipmentMaster>>();
    let data = [];
    const url = this.config.ipAddress + this.config.equipmentMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.equipmentMasterList) {
          data = response.result.equipmentMasterList;
          resultList.next(data);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getProcessConfigParams() {
    const url = this.config.ipAddress + this.config.processParamsApi[ApiActions.Get];
    const data = new Subject<Array<ProcessParams>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Processconfigurationparams;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getProcessConfigSteps() {
    const url = this.config.ipAddress + this.config.processStepsApi[ApiActions.Get];
    const data = new Subject<Array<ProcessStep>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.Processconfigurationsteps;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getDataRecords() {
    let resultList = new Array<ProcessConfig>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.Processconfiguration) {
          resultList = response.result.Processconfiguration;
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getItemsList(pcId: number) {
    const url = this.config.ipAddress + this.config.processStepDetailsApi[ApiActions.Get];
    const data = new Subject<Array<PCItem>>();
    const fData = new FormData();
    fData.append('pconfigId', pcId + '');
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.pconfigStepList;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public updateData(objData: ProcessConfig) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: ProcessConfig) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: ProcessConfig) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('status', objData.status + '');
    fData.append('prodId', objData.prodId + '');
    fData.append('batchSize', objData.batchSize + '');
    fData.append('stdBatchUom', objData.stdBatchUom + '');
    fData.append('stdBatchSize', objData.stdBatchSize + '');
    fData.append('batchUom', objData.prodUom + '');

    const items = objData.items.map(e => {
      return {
        pcstepId: e.pcstepId,
        equipmentIds: e.equipmentIds,
        pcparamId: e.pcparamId,
        pcparamMin: e.pcparamMin,
        pcparamMax: e.pcparamMax,
        pcparamUom: e.pcparamUom,
        criticality: e.stepCriticality,
        cycleTime: e.cycleTime,
        noOfPeople: e.noOfPeople
      };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }
}
