export interface RefItemCategory {
	s_no?: number;
    RefItemCategoryId: number;
	itemCatId: number;
    itemCatCode: string;
    itemCatDesc: string;
    status: boolean;
	
}

export const defaultRefItemCategory = {
    RefItemCategoryId: 0,
	itemCatId: 0,
    itemCatCode: '',
    itemCatDesc: '',
    status: false
};