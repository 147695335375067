export interface EquipmentSubType {
    s_no?: number;
    equipmentSubTypeId: number;
    equipmentSubTypeCode: string;
    equipmentSubTypeDesc: string;
    equipmentTypeId: number;
    status: boolean;
}

export const defaultEquipmentSubType = {
    equipmentSubTypeId: 0,
    equipmentTypeId: 0,
    equipmentSubTypeCode: '',
    equipmentSubTypeDesc: '',
    status: false
};
