export interface ItemCategory {
    s_no?: number;
    itemCatId: number;
    itemCatCode: string;
    itemCatDesc: string;
    status: boolean;
}

export const defaultItemCategory = {
    itemCatId: 0,
    itemCatCode: '',
    itemCatDesc: '',
    status: false
};
