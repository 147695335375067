export interface ProductPharmacopiea {
    s_no?: number;
    pharmacopieaId: number;
    pharmacopieaCode: string;
    pharmacopieaDesc: string;
    status: boolean;
}

export const defaultProductPharmacopiea = {
    pharmacopieaId: 0,
    pharmacopieaCode: '',
    pharmacopieaDesc: '',
    status: false
};
