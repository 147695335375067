export interface Itemtype {
    s_no?: number;
    ItemtypeId: number;
    ItemtypeCode: string;
    ItemtypeDesc: string;
	state: boolean;
    status: boolean;
}

export const defaultItemtype = {
    ItemtypeId: 0,
    ItemtypeCode: '',
    ItemtypeDesc: '',
    status: false,
	state: false
};