import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridHeader
  , GridHeaderEx
  , EquipmentOperatingTiming
  , defaultEquipmentOperatingTiming
} from 'src/app/model';
import {
  UtilService
  , MsgService
  , AppConfigService
  , UserType
  , EquipmentOperatingTimingService,
  unitShifts
} from 'src/app/service';
import { SelectItem, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-equipment-operating-timing',
  templateUrl: './equipment-operating-timing.component.html',
  styleUrls: ['./equipment-operating-timing.component.scss']
})
export class EquipmentOperatingTimingComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<EquipmentOperatingTiming>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: EquipmentOperatingTiming = defaultEquipmentOperatingTiming;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public equipmentAvailablecheck: boolean = false;
 selectedValues= false;
  public equipmentMasterList: Array<SelectItem> = [];
  public selectedEquipment: Array<EquipmentOperatingTiming>;

  public equipmentShifts: Array<MenuItem> = [...unitShifts];
  public selectedEquipmentShifts: Array<string> = [];

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
   { field: 'equipmentName', header: 'Equipment Name', isVisible: true, isDefault: true },
    { field: 'Availablests', header: 'Equipment Available', isVisible: true, isDefault: true },
	
    { field: 'equipmentShifts', header: 'Equipment Shifts', isVisible: true, isDefault: true },
    { field: 'nonAvailableReason', header: 'no Available Reason', isVisible: true, isDefault: true },
    { field: 'hoursNormalWageRate', header: 'Hours Normal WageRate', isVisible: true, isDefault: true },
    { field: 'hoursOvertimeWageRate', header: 'Hours Overtime WageRate', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: EquipmentOperatingTimingService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultEquipmentOperatingTiming);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              if( e.equipmentAvailabile == 1){
                e['Availablests'] ="Available";
               
              }else{
                e['Availablests'] ="Not Available";
              }
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getEquipments().subscribe(data => {
      this.equipmentMasterList = data.map(ele => {
        return { label: ele.equipmentName+'  ('+ele.equipmentCode+ ') ', value: ele };
      });
    });
  }

  public getProdName(id: string) {
    const prod = this.equipmentMasterList.find(e => e.value.unitId + '' === id);
    return prod ? prod.value.unitName : '';
  }


  public onEquipmentChange(event) {
    console.log(event);
    this.dataObj.equipmentName = event.value.equipmentName;
    this.dataObj.equipmentId = event.value.equipmentId;
  }
  public equipmentAvailablechange(event){

	  if(this.equipmentAvailablecheck == true){
      this.dataObj.equipmentAvailabile = 1;
  	}else{
      this.dataObj.equipmentAvailabile = 0;
  	}

  }
  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultEquipmentOperatingTiming);
    if (event == null) {
      this.isAddDetails = true;
    } else {
       
        this.isEditDetails = true;
   
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    const equipment = this.equipmentMasterList.find(e => e.value.equipmentId == this.dataObj.equipmentId);
    this.selectedEquipment = equipment ? equipment.value : equipment;
    const idList = this.dataObj.equipmentShifts ? this.dataObj.equipmentShifts.split(',') : [];
    const items = [];
//	console.log(idList);
    for (const val of idList) {
      const item = this.equipmentShifts.find(e => e['value'] == val);
      if (item) { items.push(item['value']); }
    }
    this.selectedEquipmentShifts = items;
  
    if( this.dataObj.equipmentAvailabile == 1){
      this.equipmentAvailablecheck=true;
     
    }else{
      this.equipmentAvailablecheck=false;
    }
    console.log(this.dataObj.equipmentAvailabile);
    console.log(this.equipmentAvailablecheck);
//	console.log(this.selectedEquipmentShifts);
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: EquipmentOperatingTiming) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: EquipmentOperatingTiming) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    // TODO: get all the list of prod join with comma.

    let result = false;
    if (
      this.dataObj.id === 0 ||
     // this.dataObj.equipmentAvailabile === 0 ||
      this.dataObj.nonAvailableReason === 0 ||
      this.dataObj.hoursNormalWageRate === 0 ||
      this.dataObj.hoursOvertimeWageRate === 0 ||
      this.dataObj.equipmentShifts.trim() === '') {

      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
	  console.log(this.dataObj);
    this.dataObj.equipmentShifts = this.selectedEquipmentShifts.join(',');
    if (this.dataObj.equipmentShifts.trim().length !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Equipment code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.selectedEquipmentShifts = [];
    this.dataObj = Object.assign({}, defaultEquipmentOperatingTiming);
    this.equipmentAvailablecheck = false;
  }
}
