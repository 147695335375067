export interface ItemForm {
    s_no?: number;
    itemFormId: number;
    itemFormCode: string;
    itemFormDesc: string;
    itemTransMode: string;
    itemStorageMode: string;
    status: boolean;
}

export const defaultItemForm = {
    itemFormId: 0,
    itemFormCode: '',
    itemFormDesc: '',
    itemTransMode: '',
    itemStorageMode: '',
    status: false
};
