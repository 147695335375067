import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  EquipmentType
  , EquipmentSubType
  , defaultEquipmentMaster
  , GridHeaderEx
  , EquipmentMaster
  , GridHeader
  , ProductMaster
} from 'src/app/model';
import {
  EquipmentMasterService
  , UtilService
  , MsgService
  , AppConfigService
  , UserType
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-equipment-master',
  templateUrl: './equipment-master.component.html',
  styleUrls: ['./equipment-master.component.scss']
  
})
export class EquipmentMasterComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<EquipmentMaster>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: EquipmentMaster = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public equipmentTypeList: Array<SelectItem> = [];
  public selectedType: SelectItem;
  public equipmentSubTypeList: Array<SelectItem> = [];
  public equipmentSubTypeListMain: Array<SelectItem> = [];

  public selectedSubType: SelectItem;
  public productMasterList: Array<SelectItem> = [];
  public selectedProduct: SelectItem;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'equipmentCode', header: 'Code', isVisible: true, isDefault: true },
    { field: 'equipmentName', header: 'Name', isVisible: true, isDefault: true },
    { field: 'equipmentTypeDesc', header: 'Type', isVisible: true, isDefault: true },
    { field: 'equipmentSubTypeDesc', header: 'SubType', isVisible: true, isDefault: true },
    { field: 'equipmentCapacity', header: 'Capacity', isVisible: true, isDefault: true },
    { field: 'equipmentMake', header: 'Manufacturer', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product', isVisible: true, isDefault: true },
    { field: 'dateCommission', header: 'Date', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: EquipmentMasterService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  public getDate(d: Date) {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    month = (month.length < 2) ? '0' + month : month;
    day = (day.length < 2) ? '0' + day : day;
    return [day, month, d.getFullYear()].join('-');
  }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultEquipmentMaster);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getEquipmentTypes()
      .subscribe(data => {
        this.equipmentTypeList = data.map(ele => {
          return { label: ele.equipmentDesc, value: ele };
        });
      });

    this.dataService.getProductMasterRecords()
      .subscribe(data => {
        this.productMasterList = data.map(ele => {
          return { label: ele.productMasterName, value: ele };
        });
      });

    this.dataService.getEquipmentSubTypes()
      .subscribe(data => {
        this.equipmentSubTypeListMain = data.map(ele => {
          return { label: ele.equipmentSubTypeDesc, value: ele };
        });
      });
  }

  public onProductChange(event) {
    this.dataObj.productId = (event.value as ProductMaster).productMasterId;
  }

  public onTypeChange(event) {
    this.dataObj.equipmentTypeId = (event.value as EquipmentType).equipmentId;
    this.getUpdatedSubTypes();
  }

  public onSubTypeChange(event) {
    this.dataObj.equipmentSubTypeId = (event.value as EquipmentSubType).equipmentSubTypeId;
  }

  public onSelect(event) {
    this.dataObj.dateCommission = new Date(event);
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultEquipmentMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    if (this.dataObj.equipmentTypeId + '' !== '0') {
      const equipment = this.equipmentTypeList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.equipmentId + '' == this.dataObj.equipmentTypeId + '');
      this.selectedType = equipment ? equipment.value : equipment;
    }

    this.getUpdatedSubTypes();

    if (this.dataObj.equipmentSubTypeId + '' !== '0') {
      const subType = this.equipmentSubTypeList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.equipmentSubTypeId + '' == this.dataObj.equipmentSubTypeId + '');
      this.selectedSubType = subType ? subType.value : subType;
    }

    // tslint:disable-next-line: triple-equals
    const prod = this.productMasterList.find(e => e.value.productMasterId == this.dataObj.productId);
    this.selectedProduct = prod ? prod.value : prod;
  }

  private getUpdatedSubTypes() {
    this.selectedSubType = undefined;
    this.equipmentSubTypeList =
      [...this.equipmentSubTypeListMain.filter(e => e.value.equipmentTypeId === this.dataObj.equipmentTypeId)];
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: EquipmentMaster) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public deleteData(obj: EquipmentMaster) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.equipmentCode.trim() === '' ||
      this.dataObj.equipmentTypeId === 0 ||
      this.dataObj.equipmentSubTypeId === 0 ||
      this.dataObj.productId === 0 ||
      this.dataObj.equipmentName.trim() === '' ||
      this.dataObj.equipmentMake.trim() === '' ||
      this.dataObj.dateCommission === undefined) {       
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    console.log(this.dataObj);
    if (this.dataObj.equipmentCode.trim().length !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Equipment code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.selectedType = undefined;
    this.selectedSubType = undefined;
    this.selectedProduct = undefined;
    this.dataObj = Object.assign({}, defaultEquipmentMaster);
  }

}
