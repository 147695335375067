import { Component, OnInit, OnDestroy } from '@angular/core';

import {
  GridHeader,
  DataConfirmNProject,
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  AppConfigService,
  PlancomparisonService,
} from 'src/app/service';
import { Subscription } from 'rxjs';
import { SalesForecastService } from 'src/app/service/transaction/sales-forecast.service';

@Component({
  selector: 'app-sales-forecast',
  templateUrl: './sales-forecast.component.html',
  styleUrls: ['./sales-forecast.component.scss']
})
export class SalesForecastComponent implements OnDestroy {



  private subscription = new Subscription();
  public dataArray: Array<any>;
  public dataForecastArray: Array<any> = [];
  public projectedDataArray: Array<any> = [];
  public selectedRows: Array<any> = [];
  public plansList: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: DataConfirmNProject = {} as DataConfirmNProject;
  public isEdited = false;
  public isPlanEdited = false;
   public order_status = false;
public selectedplan_idone =0;
  public orderFromDate: Date = new Date();
  public orderToDate: Date = new Date();

  private clonedProjectedRecord: { [s: string]: {}; } = {};
  private clonedConfirmedRecord: { [s: string]: {}; } = {};

  // public oldPriVal: string;
  public get isCreator() { 
    return this.util.getUserRole === UserType.Creator;
	
	
	
  }

  public get dlgHeader() {
    return this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  public confirmCols: Array<GridHeader> = [
    { field: 's_no', header: 'S No' },
    { field: 'custCode', header: 'Customer Code' },
    { field: 'custName', header: 'Customer Name' },
    { field: 'customerPurchaseOrder', header: 'Order Number' },
    { field: 'customerPurchaseDate', header: 'Order Date' },
    { field: 'prodCode', header: 'Product Code' },
    { field: 'prodName', header: 'Product Name' },
    { field: 'prodSpecs', header: 'Specifications' },
    { field: 'orderQty', header: 'Order Qty' },
	{ field: 'finished_qty', header: 'Finished Qty' },
    { field: 'dispatchedQty', header: 'Dispatched Qty' },
    { field: 'balanceQty', header: 'Balance Qty' },
    { field: 'priority', header: 'Priority' },
	{ field: 'status', header: 'Action' }
  ];

  public projectedCols: Array<GridHeader> = [
    { field: 's_no', header: 'S No' },
    { field: 'intordCode', header: 'Internal Order No.' },
    { field: 'intordDate', header: 'Order Date' },
    { field: 'prodCode', header: 'Product Code' },
    { field: 'prodName', header: 'Product Name' },
    { field: 'uomDesc', header: 'UOM' },
    { field: 'prodSpecs', header: 'Specifications' },
    { field: 'projectedQty', header: 'Projected Qty' },
	{ field: 'finished_qty', header: 'Finished Qty' },
	{ field: 'balance_qty', header: 'Balance Qty' },
    { field: 'priority', header: 'Priority' },
	{ field: 'status', header: 'Action' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: SalesForecastService,
    public util: UtilService,
    private PlancomparisonService: PlancomparisonService,
    private config: AppConfigService
  ) {
 
	
	if(this.util.getUserRoleid() == 4){
		this.isPlanEdited = true;
	}
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
             if(e.api_prod == 1){
                e['canEdit'] = action.canEdit;
              }else{
                e['canEdit'] = false;				
              }
			  
              
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.subscription.add(
      this.dataService.projectedDataList.subscribe((data) => {
        this.projectedDataArray = [];
        if (data.length) {
          this.projectedDataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getTableData() {
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getConfirmedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
      this.dataService.getProjectedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
    }
  }
  
   public newPlangenerate() {
    if (this.orderFromDate && this.orderToDate) {
		
		this.dataService.getGeneratenewplanId(this.orderFromDate, this.orderToDate).subscribe((data) => {		
		});
    this.PlancomparisonService.getsaleforcastplans(this.orderFromDate, this.orderToDate)
    .subscribe(data => {
      this.plansList = data.map(ele => {
    this.selectedplan_idone = ele.plan_id;
        return { label:"Plan Number - "+ ele.plan_id, value: ele.plan_id };
      });
      this.dataService.getConfirmedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
      this.dataService.getProjectedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
    });
    }
  }
	public get_plans(){
		this.PlancomparisonService.getsaleforcastplans(this.orderFromDate, this.orderToDate)
      .subscribe(data => {
        this.plansList = data.map(ele => {
			this.selectedplan_idone = ele.plan_id;
          return { label:"Plan Number - "+ ele.plan_id, value: ele.plan_id };
        });
        console.log(this.selectedplan_idone);
      });
		
	}
  public onCriticalityChangeone(event) {
    this.selectedplan_idone = event.value;
    console.log(this.selectedplan_idone);
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getConfirmedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
      this.dataService.getProjectedRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
    }
	// this.userObj.userRoleId = (event.value.id).userRoleId;
	
  }
  public updateData() {
    this.dataObj.lstOfProjectedOrders = this.projectedDataArray;
    this.dataObj.lstOfConfirmOrders = this.dataArray;
    this.dataService.updateData(this.dataObj,this.orderFromDate, this.orderToDate).subscribe((data) => {
      this.resetFormData();
    });
  }

  public saveAsDraft() {
    this.dataObj.lstOfProjectedOrders = this.projectedDataArray;
    this.dataObj.lstOfConfirmOrders = this.dataArray;
    this.dataService.saveDraft(this.dataObj,this.orderFromDate, this.orderToDate).subscribe((data) => {
      this.resetFormData();
    });
  }

  private resetFormData() {
    this.isEdited = false;
    this.projectedDataArray = [];
    this.getTableData();
  }


  public cancelDlg() {
    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
    this.orderToDate = new Date();
    this.resetFormData();
  }


  // ---------------------------------

  public onRowConfirmedInit(row) {
    this.clonedConfirmedRecord[row.s_no] = { ...row };
  }

  public onRowConfirmedSave(row) {
    delete this.clonedConfirmedRecord[row.s_no];
	
	const result = this.dataArray.filter(f=>{
		
		if(f.api_prod == 0 && f.api_id == row.prodId ){
			
			return f.priority = row.priority;
			
		}
		
		
	});
	this.dataArray = [...this.dataArray];
	
    this.isEdited = true;
  }

  public onRowConfirmedCancel(row, index: number) {
    this.dataArray[index] = this.clonedConfirmedRecord[row.s_no];
    delete this.clonedConfirmedRecord[row.s_no];
  }

  public onRowProjectedInit(row) {
    this.clonedProjectedRecord[row.s_no] = { ...row };
  }

  public onRowProjectedSave(row) {
    delete this.clonedProjectedRecord[row.s_no];
	const result = this.dataArray.filter(f=>{
		
		if(f.api_prod == 0 && f.api_id == row.prodId ){
			
			return f.priority = row.priority;
			
		}
		
		
	});
    this.isEdited = true;
  }

  public onRowProjectedCancel(row, index: number) {
    this.dataArray[index] = this.clonedProjectedRecord[row.s_no];
    delete this.clonedProjectedRecord[row.s_no];
  }

  // ----------------------------------

  // public onRowEditInit(row) {
    // // tslint:disable-next-line: no-string-literal
    // this.oldPriVal = row['priority'];
  // }
  // public onRowEditSave(row) {
    // this.isEdited = true;
  // }
  // public onRowEditCancel(row, ri: number) {
    // // tslint:disable-next-line: no-string-literal
    // row['priority'] = this.oldPriVal;
  // }
}
