import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  ProcessConfig,
  defaultProcessConfig,
  ProductUOM,
  ProcessStep,
  PCItem,
  defaultPCItem,
  EquipmentMaster,
  ProcessParams
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  ProcessConfigService,
  AppConfigService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-process-config',
  templateUrl: './process-config.component.html',
  styleUrls: ['./process-config.component.scss']
})
export class ProcessConfigComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<PCItem> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: ProcessConfig = defaultProcessConfig;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public productMasterList: Array<SelectItem> = [];
  public selectedProduct: SelectItem;

  public UOMList: Array<SelectItem> = [];
  public selectedProcessPramUOM: ProductUOM;
  public selectedBatchUOM: ProductUOM;

  public processStepList: Array<SelectItem> = [];
  public selectedProcessStep: ProcessStep;

  public processParamList: Array<SelectItem> = [];
  public selectedProcessParam: ProcessParams;

  public equipmentList: Array<SelectItem> = [];
  public selectedEquipments: Array<EquipmentMaster> = [];

  public criticalityLevels: Array<SelectItem> =
    [{ label: 'Low', value: 1 }, { label: 'Medium', value: 2 }, { label: 'High', value: 3 }];
  public selectedCriticality = this.criticalityLevels[0].value;

  public itemObj: PCItem = defaultPCItem;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'pharmacopiDesc', header: 'Pharmacopiea', isVisible: true, isDefault: true },
    { field: 'uomCode', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'batchSize', header: 'Batch Size', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S No' },
    { field: 'pcstepId', header: 'StepId' },
    { field: 'pcstepName', header: 'Process Step' },
    { field: 'equipmentIds', header: 'Equipments' },
    { field: 'pcparamName', header: 'Param Name' },
    { field: 'pcparamMin', header: 'Min' },
    { field: 'pcparamMax', header: 'Max' },
    { field: 'pcparamUomDesc', header: 'UOM' },
    { field: 'cycleTime', header: 'CycleTime' },
    { field: 'noOfPeople', header: 'NoOf People' },
    { field: 'stepCriticality_row', header: 'Step Criticality' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: ProcessConfigService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public onProductChange(event) {
    this.dataObj = event.value;
  }

  public onStdBatchUOMChange(event) {
    this.dataObj.stdBatchUom = event.value.uomId;
  }

  public onProcessUOMChange(event) {
    this.itemObj.pcparamUom = event.value.uomId;
  }

  public onEquipmentChange(event) {
    if (this.selectedEquipments) {
      const ids = [];
      this.selectedEquipments.forEach(e => {
        ids.push(e.equipmentCode);
      });
      this.itemObj.equipmentIds = ids.join(',');
    }
  }

  public onProcessStepChange(event) {
    this.itemObj.pcstepId = event.value.pcstepId;
    this.itemObj.pcstepName = event.value.pcstepName;
  }

  public onProcessParamChange(event) {
    this.itemObj.pcparamId = event.value.pcparamId;
    this.itemObj.pcparamName = event.value.pcparamName;
  }

  public onCriticalityChange(event) {
    this.itemObj.stepCriticality = Number(event.value);
  }

  private loadDropDownLists() {

    this.dataService.getProductsData()
      .subscribe(data => {
        this.productMasterList = data.map(ele => {
          return { label: ele.prodName, value: ele };
        });
      });

    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
      });

    this.dataService.getEquipmentMasters()
      .subscribe(data => {
        this.equipmentList = data.map(ele => {
          return { label: ele.equipmentName+'  ('+ele.equipmentCode+ ') ', value: ele };
        });
      });

    this.dataService.getProcessConfigSteps()
      .subscribe(data => {
        this.processStepList = data.map(ele => {
          return { label: ele.pcstepName, value: ele };
        });
      });

    this.dataService.getProcessConfigParams()
      .subscribe(data => {
        this.processParamList = data.map(ele => {
          return { label: ele.pcparamName, value: ele };
        });
      });
  }

  public addToItemList() {

    if (this.selectedProcessPramUOM === undefined ||
      this.selectedProcessStep === undefined ||
      this.selectedEquipments.length === 0 ||
      this.itemObj.pcparamMin === 0 ||
      this.itemObj.pcparamMax === 0
    ) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide valid data to add' });
    } else {
      this.itemObj.pcparamUomDesc = this.selectedProcessPramUOM.uomDesc;
      this.itemObj.pcstepName = this.selectedProcessStep.pcstepName;
      if(this.itemObj.stepCriticality == 1){
        this.itemObj.stepCriticality_row = 'Low';
			  
			}else if(this.itemObj.stepCriticality == 2){
				this.itemObj.stepCriticality_row = 'Medium';
				
			}else if(this.itemObj.stepCriticality == 3){
				this.itemObj.stepCriticality_row = 'High';
			}
      const tmp = Object.assign({}, this.itemObj);
      tmp.s_no = this.itemDataArray.length + 1;
      const list = [... this.itemDataArray];
      list.push(tmp);
      this.itemDataArray = list;

      // Reset all the properties.
      this.selectedProcessPramUOM = undefined;
      this.selectedProcessParam = undefined;
      this.selectedProcessStep = undefined;
      this.selectedEquipments = [];
      this.itemObj.pcparamMin = 0;
      this.itemObj.pcparamMax = 0;
      this.itemObj.noOfPeople = 0;
      this.itemObj.cycleTime = 0;
    }
  }

  public deleteItemRow(event) {
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.pcstepId === event.pcstepId), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  public updateData() {
    this.dataObj.items = this.itemDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: ProcessConfig) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultProcessConfig);

    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    const prod = this.productMasterList
      // tslint:disable-next-line: triple-equals
      .find(e => e.value.prodId == this.dataObj.prodId);

    this.selectedProduct = prod ? prod.value : undefined;

    const stdUOM = this.UOMList.find(e => e.value.uomId == this.dataObj.stdBatchUom);

    this.selectedBatchUOM = stdUOM ? stdUOM.value : undefined;
    this.dataObj.stdBatchUomDesc = stdUOM ? stdUOM.value.uomDesc : '';
    this.dataService.getItemsList(this.dataObj.pconfigId)
      .subscribe(dataList => {
        this.itemDataArray = dataList.map((ele, indx) => {
          // tslint:disable-next-line: no-string-literal
			if(ele.stepCriticality == 1){
			  ele['stepCriticality_row'] = 'Low';
			  
			}else if(ele.stepCriticality == 2){
				ele['stepCriticality_row'] = 'Medium';
				
			}else if(ele.stepCriticality == 3){
				ele['stepCriticality_row'] = 'High';
			}
          ele['s_no'] = indx + 1;
          return ele;
        });
      });
  }

  public deleteData(obj: ProcessConfig) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.prodId === 0 ||
      // tslint:disable-next-line: triple-equals
      this.dataObj.stdBatchSize == 0 ||
      // tslint:disable-next-line: triple-equals
      this.dataObj.stdBatchUom == 0
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    this.dataObj.items = this.itemDataArray;
    if (this.dataObj.prodId !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Process config details' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.selectedProduct = undefined;
    this.itemDataArray = [];
    this.selectedCriticality = this.criticalityLevels[0].value;
    this.selectedBatchUOM = undefined;
    this.dataService.getDataRecords();
    // TODO: reset all the selected items.
    this.dataObj = Object.assign({}, defaultProcessConfig);
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + '/pharma/uploads/specs_doc/' + file;
  }
}
