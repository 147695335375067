import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  GridHeader,
  ItemForm,
  defaultItemForm,
  GridHeaderEx
} from 'src/app/model';
import { MsgService, ItemFormService,UtilService,UserType } from 'src/app/service';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ItemForm>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: ItemForm = defaultItemForm;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }
  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'itemFormId', header: 'Id', isVisible: false, isDefault: true },
    { field: 'itemFormCode', header: 'Form Code', isVisible: true, isDefault: true },
    { field: 'itemFormDesc', header: 'Form Description', isVisible: true, isDefault: true },
    { field: 'itemTransMode', header: 'Trans Mode', isVisible: true, isDefault: true },
    { field: 'itemStorageMode', header: 'Storage Mode', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,    
    public util: UtilService,
    private dataService: ItemFormService
  ) { }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultItemForm);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
    }
  }

  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.itemFormId).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: ItemForm) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: ItemForm) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: ItemForm) {
    this.dataService.updateStatus(obj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
  }

}


