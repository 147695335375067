import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsgService, UserService, LeftMenuService, UtilService } from 'src/app/service';
import { GridHeader, GridHeaderEx, MasterActions, User, defaultUser, AdminMenu } from '../../../model';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserRolesService } from 'src/app/service/master/user-roles.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public userArray: Array<User> = [];
  public isDeleteDetails = false;
  public isRoleDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editUserRolesList: any = {};
  public userObj: User = defaultUser;

  private menusFromDB: Array<AdminMenu> = [];
  public menus: Array<{ name: string, menuItemsList: Array<{ name: string, value: string }>, selected?: [] }> = [];
  public selectedRoles = [];
  public userRoleList: Array<SelectItem> = [];
  public isResetPassword = false;
  public isUserRoles = false;
  public filteredUserRoles: Array<SelectItem> = [];

  public get isEditShow() {
    return this.isEditDetails;
  }

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
  }

  public get isAddShow() {
    return this.isAddDetails;
  }

  public set isAddShow(value: boolean) {
    this.isAddDetails = value;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  private get pageName() {
    return this.userSvc.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'userFName', header: 'First Name', isVisible: true, isDefault: true },
    { field: 'userLName', header: 'Last Name', isVisible: true, isDefault: true },
    { field: 'userLogin', header: 'Login Id', isVisible: true, isDefault: true },
    { field: 'userRoleCode', header: 'Role', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  constructor(
    private userRoleSvc: UserRolesService,
    private userSvc: UserService,
    private msgSvc: MsgService,
    public utilSvc: UtilService,
    private leftMenuSvc: LeftMenuService) {

    const userRoleList = this.userRoleSvc.userRolesList.subscribe((data) => {
      this.userRoleList = [];
      if (data.length) {
        this.userRoleList = data.filter(e => e.status).map(ele => {
          return { label: ele.userRole, value: ele.userRoleId };
        });
		console.log(userRoleList);
      } else {
        this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit for Tenants' });
      }
   
    });

    const uList = this.userSvc.userList.subscribe((data) => {
      this.userArray = [];
      if (data.length) {
        this.userArray = data.map((e, indx) => {
          e.s_no = indx + 1;
          return e;
        });
      } else {
        this.msgSvc.addInfoMsg({ summary: 'User', detail: 'No data exit for UserRoles' });
      }
    });

    // this.subscription.add(
    //   this.leftMenuSvc.menuList.subscribe((value) => {
    //     if (value) {
    //       this.menusFromDB = value;
    //       this.initMenus();
    //     }
    //   }));
    // this.leftMenuSvc.getMenuFromDB();

    this.subscription.add(uList);
//	 this.loadDropDownLists();
    this.subscription.add(userRoleList);
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    // Get UserRoles
    this.userSvc.getDataRecords();
     this.userRoleSvc.getRolesData();
	//  this.loadDropDownLists();
	
	  
  }


  // private loadDropDownLists() {
    // this.userRoleSvc.getRolesData()
      // .subscribe(data => {
        // this.userRoleList = data.map(ele => {
          // return { label: ele.userRole, value: ele };
        // });
      // });
	  // this.userRoleSvc.getRolesData()
      // .subscribe(data => {
        // this.filteredUserRoles = data.map(ele => {
          // return { label: ele.userRole, value: ele.userRoleId };
        // });
      // });
  // }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initMenus() {
    // Menu Headers
    const menuHeaders = this.menusFromDB.filter(m => m.menuParentId === 0);

    for (const menu of menuHeaders) {
      const subMenus = this.menusFromDB.filter(m => m.menuParentId === menu.menuID);
      const menuItems = subMenus.map(m => {
        return { name: m.menuDesc, value: m.menuID + '' };
      });
      this.menus.push({ name: menu.menuDesc, menuItemsList: menuItems });
    }
  }

  public userPasswordReset(event) {
    this.userObj.userId = event;
    this.isResetPassword = true;
  }
 tcode : string;
  public resetPassword() {
	 this.userObj.password = this.tcode
	   console.log("the code :" + this.tcode);
    this.userSvc.userPasswordReset(this.userObj).subscribe((data) => {
      this.isResetPassword = false;
    });
  }

  public changeRole(event) {
    this.isUserRoles = true;
    console.log(event);
    this.userObj = event;
  }

  public changeUserRole(event) {
	   //const fData = new FormData();
	 
    const userObj = {
      userId: this.userObj.userId,
      userRoleCode: this.userObj.userRoleId
    };
	 console.log(userObj);
    this.userSvc.changeUserRole(userObj).subscribe((data) => {
      this.isUserRoles = false;
	  this.userSvc.getDataRecords();
    });
  }

  public addData(event): void {
    this.userObj = Object.assign({}, defaultUser);
    if (event == null) {
      this.isAddDetails = true;
    }
  }

  public editData(event) {
    this.userObj = Object.assign({}, event ? event : defaultUser);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
	 // this.bindDropDownList();
    }
  }

  public roleData(event): void {
    this.userObj = event;
    this.isRoleDetails = true;
  }

  public updateData() {
    if (this.isAddDetails) {
      this.userSvc.addData(this.userObj).subscribe((data) => {
        this.userSvc.getDataRecords();
        this.isAddDetails = false;
      });
    } else {
		console.log(this.userObj);
      this.userSvc.updateData(this.userObj).subscribe((data) => {
        this.userSvc.getDataRecords();
      });
    }
    this.isEditDetails = false;
  }

  public deleteData(user: User) {
    this.isDeleteDetails = true;
    this.userObj = user;
  }

  public viewData(user: User) {
    this.userObj = user;
    this.isViewDetails = true;
  }

  public DeleteDetails() {
    const dataObj = { userId: this.userObj.userId };
    this.userSvc.deleteData(dataObj).subscribe((data) => {
      this.userSvc.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public updateStatus(user: User) {
    this.userSvc.updateStatus(user);
  }

  public cancelEditDlg() {
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.isViewDetails = false;
  }

  public saveRoleDetails() {
    let selectedIds = {};
    selectedIds = JSON.stringify(this.selectedRoles.map(({ value }) => value));
    const permissionObj = {
      userId: this.userObj.userId,
      userPermissions: selectedIds
    };
    this.userSvc.rolesPermissions(permissionObj).subscribe((data) => {
      this.userSvc.getDataRecords();
    });
    this.isRoleDetails = false;
  }

  public onUserRolesChange(event) {
	  console.log(event);
    this.userObj.userRoleId = event.value.value;
	// this.userObj.userRoleId = (event.value.id).userRoleId;
	
  }

}
