import { Injectable } from '@angular/core';
import {
  HttpRequest
  , HttpHeaders
  , HttpHandler
  , HttpEvent
  , HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { ApiActions } from './util.service';
import { AuthenticationService } from './authentication.service';
import { BusyCursorService } from './busy-cursor.service';

const defaultContentType = 'application/json';

@Injectable({
  providedIn: 'root'
})
export class HttpDataService implements HttpInterceptor {

  constructor(
    private busy: BusyCursorService,
    private config: AppConfigService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  private endRequest() {
    setTimeout(() => { this.busy.hide(); }, this.config.hideDelay);
  }

  private startRequest() {
    this.busy.show();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoginRequest = request.url.includes(this.config.logInApi[ApiActions.Get]);
    let header: HttpHeaders;

    // If it is login request we need to bypass the token expire check.
    if (!isLoginRequest) {
      if (this.authenticationService.isTokenExpired) {
        this.authenticationService.logout();
        this.router.navigate(['/account/login']);
        throw new Error('Token Expired!');
      }
      // Update headers...
      const token = localStorage.getItem('token');
      header = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      header.set('Content-Type', defaultContentType);
      header.set('Access-Control-Allow-Origin', '*');

    } else {
      header = new HttpHeaders().set('Content-Type', defaultContentType);
    }
    request = request.clone({ headers: header });
    // http request start ...
    this.startRequest();
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        // // Note: The following code may req later stages.
        // console.log('event--->>>', event);
        // }
        if (event instanceof HttpResponse) {
          // tslint:disable-next-line:no-string-literal
          if (event['status'] === 0) {
            //   'error' :  'Network connection',
          }
          return event;
        }
      }),
      finalize(() => {
        this.endRequest();
      }));
  }
}
