export interface ProductGrade {
    s_no?: number;
    gradeId: number;
    gradeCode: string;
    gradeDesc: string;
    status: boolean;
}

export const defaultProductGrade = {
    gradeId: 0,
    gradeCode: '',
    gradeDesc: '',
    status: false
};
