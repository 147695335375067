export interface RefMasterField {
    s_no?: number;
    fieldId: number;
    fieldName: string;
    status: number;

}


export const defaultRefMasterField = {
    fieldId: 0,
    fieldName: '',
    status: 0

};
