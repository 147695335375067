import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsgService, LeftMenuService, UserRolesService } from 'src/app/service';
import { GridHeader, GridHeaderEx, UserRoles, MasterActions, defaultUserRoles, AdminMenu } from '../../../model';
import { Subscription } from 'rxjs';

interface RoleConfig {
  name: string;
  menuItemsList: Array<{ name: string, value: string }>;
  selected?: Array<string>;
}

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy, MasterActions {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public userRolesArray: Array<UserRoles> = [];
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public editUserRolesList: any = {};
  public userRolesObj: UserRoles = defaultUserRoles;
  private menusFromDB: Array<AdminMenu> = [];
  public menus: Array<RoleConfig> = [];
  public selectedRoles = [];
  public isRoleDetails = false;
  public menuList = [];

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  private get pageName() {
    return this.userRoleSvc.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'userRoleId', header: 'User Role Id', isVisible: false, isDefault: true },
    { field: 'userRole', header: 'User Role', isVisible: true, isDefault: true },
    // { field: 'userRoleMngr', header: 'Role Manager', isVisible: true, isDefault: true },
    { field: 'userRoleMenuIds', header: 'RoleMenuIds', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  constructor(
    private userRoleSvc: UserRolesService,
    private msgSvc: MsgService,
    private leftMenuSvc: LeftMenuService
  ) {

    const uList = this.userRoleSvc.userRolesList.subscribe((data) => {
      this.userRolesArray = [];
      if (data.length) {
        this.userRolesArray = data.map((e, indx) => {
          e.s_no = indx + 1;
          return e;
        });
      } else {
        this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit for UserRoles' });
      }
    });
    this.subscription.add(uList);
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.subscription.add(
      this.leftMenuSvc.menuListForUserRoles.subscribe((value) => {
        if (value) {
          this.menusFromDB = value;
          this.initMenus();
        }
      }));
    this.leftMenuSvc.getMenuFromDBForUserRoles();
    // Get UserRoles
    this.userRoleSvc.getDataRecords();
  }

  private initMenus() {
    // Menu Headers
    // tslint:disable-next-line: triple-equals
    const menuHeaders = this.menusFromDB.filter(m => m.menuParentId == 0);

    for (const menu of menuHeaders) {
      // tslint:disable-next-line: triple-equals
      const subMenus = this.menusFromDB.filter(m => m.menuParentId == menu.menuID);
      if (subMenus && subMenus.length) {
        const menuItems = subMenus.map(m => {
          return { name: m.menuDesc, value: m.menuID.toString() };
        });
        this.menus.push({ name: menu.menuDesc, menuItemsList: menuItems });
      }
    }
    this.menus.forEach(e => {
      this.menuList = this.menuList.concat(e.menuItemsList);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editData(event): void {
    this.userRolesObj = Object.assign({}, event ? event : defaultUserRoles);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
    }
  }

  public updateData() {
    if (this.isAddDetails) {
      this.userRoleSvc.addData(this.userRolesObj).subscribe((data) => {
        this.userRoleSvc.getDataRecords();
      });
    } else {
      this.userRoleSvc.updateData(this.userRolesObj).subscribe((data) => {
        this.userRoleSvc.getDataRecords();
      });
    }
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public deleteData(userRoles: UserRoles) {
    this.isDeleteDetails = true;
    this.userRolesObj = userRoles;
  }

  public viewData(userRoles: UserRoles) {
    this.userRolesObj = userRoles;
    this.isViewDetails = true;
  }

  public DeleteDetails() {
    this.userRoleSvc.deleteData(this.userRolesObj.userRoleId).subscribe((data) => {
      this.userRoleSvc.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public updateStatus(userRoles: UserRoles) {
    this.userRoleSvc.updateStatus(userRoles);
  }

  public cancelEditDlg() {
    this.isAddDetails = false;
    this.isEditDetails = false;
    this.isViewDetails = false;
  }

  public roleData(event): void {
    this.userRolesObj = event;
    const roleList = this.userRolesObj.userRoleMenuIds.split(',');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.menus.length; i++) {
      const selected: Array<any> = [];
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < this.menus[i].menuItemsList.length; j++) {
        const tmp = this.menus[i].menuItemsList[j].value;
        // tslint:disable-next-line: triple-equals
        if (roleList.some(e => e == tmp)) {
          selected.push(this.menus[i].menuItemsList[j]);
        }
      }
      this.menus[i].selected = selected;
    }
    this.isRoleDetails = true;
  }

  public saveRoleDetails() {
    this.selectedRoles = [];
    this.menus.forEach(menu => {
      if (menu.selected) {
        menu.selected.forEach(m => this.selectedRoles.push(m));
      }
    });

    const selectedIds = this.selectedRoles.map(({ value }) => Number(value));
    const parentIds = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < selectedIds.length; i++) {
      // tslint:disable-next-line: triple-equals
      const elements = this.menusFromDB.filter(x => x.menuID == selectedIds[i]);
      const parentId = elements && elements.length ? elements[0].menuParentId : undefined;
      if (!parentIds.some(e => e === parentId)) {
        parentIds.push(parentId);
      }
    }

    selectedIds.push(...parentIds);
    this.userRolesObj.userRoleMenuIds = selectedIds.join(',');
    this.userRoleSvc.updateData(this.userRolesObj).subscribe((data) => {
      this.userRoleSvc.getDataRecords();
    });
    this.isRoleDetails = false;
  }

  public cancelRoleDetails() {
    this.isRoleDetails = false;
    this.menus.forEach(menu => { menu.selected = []; });
  }

}
