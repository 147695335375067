import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  ItemMaster
  , GridHeaderEx
  , defaultItemMaster
  , GridHeader
  , ItemCategory
  , ItemGrade
  , ItemClass
  , ItemForm
} from 'src/app/model';
import {
  MsgService
  , ItemMasterService
  , UtilService
  , UserType
  , AppConfigService
} from 'src/app/service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
//myApp.filter('unsafe', function($sce) { return $sce.trustAsHtml; });
@Component({
  selector: 'app-item-master',
  templateUrl: './item-master.component.html',
  styleUrls: ['./item-master.component.scss']
})
export class ItemMasterComponent implements OnInit, OnDestroy {

  @ViewChild('specLink', { static: true }) specLink: ElementRef;
  @ViewChild('msdsLink', { static: true }) msdsLink: ElementRef;

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ItemMaster>;
  public selectedColumns: Array<GridHeader>;
  public dataObj: ItemMaster = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public categoryList: Array<SelectItem> = [];
  public selectedCategory: SelectItem;
  public gradeList: Array<SelectItem> = [];
  public selectedGrade: SelectItem;
  public itemClassList: Array<SelectItem> = [];
  public selectedClass: SelectItem;
  public formList: Array<SelectItem> = [];
  public selectedForm: SelectItem;
  public showPDF = false;
  public pdfSrc = '';

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'itemCode', header: 'Code', isVisible: true, isDefault: true },
    { field: 'itemDesc', header: 'Desc', isVisible: true, isDefault: true },
    { field: 'itemFormDesc', header: 'Form', isVisible: true, isDefault: true },
    { field: 'itemCategoryDesc', header: 'Category', isVisible: true, isDefault: true },
    { field: 'itemGradeDesc', header: 'Grade', isVisible: true, isDefault: true },
    { field: 'itemClassDesc', header: 'Class', isVisible: true, isDefault: true },
    { field: 'specLink', header: 'Spec Doc', isVisible: true, isDefault: true },
    { field: 'hsnCode', header: 'HSN Code', isVisible: true, isDefault: true },
    { field: 'msdsLink', header: 'MSDS Doc', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: ItemMasterService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultItemMaster);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    // // this.selectedColumns = this.columnsConfig
    // //   .map((e: GridHeaderEx) => {
    // //     return { field: e.field, header: e.header };
    // //   });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getCategories()
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label: ele.itemCatCode, value: ele };
        });
      });

    this.dataService.getGrades()
      .subscribe(data => {
        this.gradeList = data.map(ele => {
          return { label: ele.itemGradeCode, value: ele };
        });
      });

    this.dataService.getClasses()
      .subscribe(data => {
        this.itemClassList = data.map(ele => {
          return { label: ele.itemClassCode, value: ele };
        });
      });

    this.dataService.getForms()
      .subscribe(data => {
        this.formList = data.map(ele => {
          return { label: ele.itemFormCode, value: ele };
        });
      });
  }

  public onCategoryChange(event) {
    this.dataObj.itemCategoryId = (event.value as ItemCategory).itemCatId;
  }

  public onGradeChange(event) {
    this.dataObj.itemGradeId = (event.value as ItemGrade).itemGradeId;
  }

  public onClassChange(event) {
    this.dataObj.itemClassId = (event.value as ItemClass).itemClassId;
  }

  public onFormChange(event) {
    this.dataObj.itemFormId = (event.value as ItemForm).itemFormId;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultItemMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {

    if (this.dataObj.itemCategoryId) {
      const item = this.categoryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.itemCatId == this.dataObj.itemCategoryId);
      this.selectedCategory = item ? item.value : undefined;
    }
    if (this.dataObj.itemGradeId) {
      const item = this.gradeList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.itemGradeId == this.dataObj.itemGradeId);
      this.selectedGrade = item ? item.value : undefined;
    }
    
	
	if (this.dataObj.itemClassId) {
      const item = this.itemClassList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.itemClassId == this.dataObj.itemClassId);
      this.selectedClass = item ? item.value : undefined;
    }
	
	console.log('itemClassId', this.dataObj.itemClassId);	
	console.log('pharmaClassId',this.dataObj.pharmaClassId);
	
	
	if (this.dataObj.pharmaClassId) {
      const item = this.itemClassList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.itemClassId == this.dataObj.itemClassId);
		//console.log('item',JSON.stringify(item, null, 4));
      this.selectedClass = item ? item.value : undefined;
    }
    if (this.dataObj.itemFormId) {
      const item = this.formList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.itemFormId == this.dataObj.itemFormId);
      this.selectedForm = item ? item.value : undefined;
    }
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: ItemMaster) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: ItemMaster) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (this.dataObj.itemDesc.trim() === '' ||
      this.dataObj.itemCategoryId === 0 ||
      this.dataObj.itemGradeId === 0 ||
      this.dataObj.itemFormId === 0 ||
      this.dataObj.itemClassId === 0 ||
      this.dataObj.hsnCode.trim() === '' ||
      this.dataObj.specLink === undefined ||
      this.dataObj.msdsLink === undefined) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    // this.dataService.deleteData(this.dataObj.id).subscribe((data) => {
    //   this.dataService.getDataRecords();
    // });
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.dataObj.itemDesc.trim().length !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided item code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.specLink.nativeElement.value = '';
    this.msdsLink.nativeElement.value = '';
    this.selectedGrade = undefined;
    this.selectedCategory = undefined;
    this.selectedForm = undefined;
    this.selectedClass = undefined;
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultItemMaster);
  }

  public fileUpload(event, propName: string) {
    const file = event.target.files[0];
    this.dataObj[propName] = file;
  }

  public viewPdf(file: string) {
    return this.config.ipAddress + 'uploads/specs_doc/' + file;
  }

}
