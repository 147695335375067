import { Component, OnInit, OnDestroy } from '@angular/core';
import {CardModule} from 'primeng/card';
import {PanelModule} from 'primeng/panel';
import { SelectItem } from 'primeng/api';
import {
	GridHeaderEx,
  GridHeader,
  Plancomparison,
  DataConfirmNProject,
  defaultPlancomparison,
  MasterActions
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  AppConfigService,
} from 'src/app/service';
import { Subscription } from 'rxjs';
import { PlancomparisonService } from 'src/app/service/transaction/plancomparison.service';

@Component({
  selector: 'app-plancomparison',
  templateUrl: './plancomparison.component.html',
  styleUrls: ['./plancomparison.component.scss']
})
export class PlancomparisonComponent implements OnDestroy {

  private subscription = new Subscription();
  public dataArray: Array<any>;
  public cols: Array<GridHeader> = [];
  public itemDataArray: Array<Plancomparison>;
  public dataForecastArray: Array<any> = [];
  public projectedDataArray: Array<any> = [];
  public CompareOneDataArray: Array<any> = [];
  public CompareTwoDataArray: Array<any> = [];
  public ComparesummaryDataArray: Array<any> = [];
  public PlanDetailsDataArray: Array<any> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Plancomparison = {} as Plancomparison;
  public categoryList: Array<any> = [];
  public isEdited = false;
  public isPlanEdited = false;
   public plan_edit_status = 0;
  public isEditDetails = false;
  public iscomapreview = false;
  public isPlanDetailsview = false;
  public orderFromDate: Date = new Date();
  public orderToDate: Date = new Date();
  public selectedplan_idone = 0;
  public selectedplan_idtwo = 0;
  public detailsplan_id = 0;
 public selectedDailyproductionlog: Plancomparison;
 public selectedMake: Array<SelectItem> = [];
 public selectedplantwo: Array<SelectItem> = [];
 //public selectedMake: Array<SelectItem> = [];
  private clonedProjectedRecord: { [s: string]: {}; } = {};
  private clonedConfirmedRecord: { [s: string]: {}; } = {};
 public criticalityLevels: Array<any> = [{
        label: 'Inactive Production Plan',
        value: 0
    }, {
        label: 'Active Production Plan',
        value: 1
    }, {
        label: 'Completed Plan',
        value: 2
    }];
  // public oldPriVal: string;
  public get isCreator() { 
    return this.util.getUserRole === UserType.Creator;

  }

  public get dlgHeader() {
    return this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
	{ field: 's_no', header: 'S No', isVisible: true, isDefault: true  },
    { field: 'plan_id', header: 'Plan Id', isVisible: true, isDefault: true  },
    { field: 'plan_start_date', header: 'Plan Start Date', isVisible: true, isDefault: true  },
    { field: 'plan_end_date', header: 'Plan End Date', isVisible: true, isDefault: true  },
    { field: 'plan_remark', header: 'Plan Description', isVisible: true, isDefault: true  },
	{ field: 'stslabel', header: 'Used Plan', isVisible: true, isDefault: true  }
  ];

  public comapreone: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'CustName', header: 'Customer Name', isVisible: true, isDefault: true },
    { field: 'ProdName', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'ProdSpecs', header: 'Product Spec', isVisible: true, isDefault: true },
    { field: 'OrdQty', header: 'Order QTY', isVisible: true, isDefault: true },
    { field: 'UOM', header: 'UOM' , isVisible: true, isDefault: true},
    { field: 'CustDeliveryDate', header: 'Exp Delivery Date', isVisible: true, isDefault: true },
    { field: 'PlantDeliveryDate', header: 'Act Delivery Date', isVisible: true, isDefault: true },
    { field: 'stslabel', header: 'Delivery Status', isVisible: true, isDefault: true }
  ];
  
    public comapretwo: Array<GridHeaderEx> = [
      { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
      { field: 'CustName', header: 'Customer Name', isVisible: true, isDefault: true },
      { field: 'ProdName', header: 'Product Name', isVisible: true, isDefault: true },
      { field: 'ProdSpecs', header: 'Product Spec', isVisible: true, isDefault: true },
      { field: 'OrdQty', header: 'Order QTY', isVisible: true, isDefault: true },
      { field: 'UOM', header: 'UOM' , isVisible: true, isDefault: true},
      { field: 'CustDeliveryDate', header: 'Exp Delivery Date', isVisible: true, isDefault: true },
      { field: 'PlantDeliveryDate', header: 'Act Delivery Date', isVisible: true, isDefault: true },
      { field: 'stslabel', header: 'Delivery Status', isVisible: true, isDefault: true }
  ];
 public confirmCols: Array < GridHeader > = [{
            field: 's_no',
            header: 'S No'
        },
        {
            field: 'run_num',
            header: 'Run Number'
        },
        {
            field: 'batch_num',
            header: 'Batch Number'
        },
        {
            field: 'run_end_date',
            header: 'Run End Date'
        },
        {
            field: 'prod_code',
            header: 'prod Code'
        },
        {
            field: 'finished_qty',
            header: 'Finished QTY'
        },
        {
            field: 'batch_hold',
            header: 'Actions',
            tooltip: 'batch_comment'
        }
    ];
  constructor(
    private msgSvc: MsgService,
    private dataService: PlancomparisonService,
    public util: UtilService,
    private config: AppConfigService
  ) {
	//console.log(this.util.getUserRoleid());
	 this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig.filter(i => i.isVisible && i.isDefault)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
	if(this.util.getUserRoleid() == 4){
		this.isPlanEdited = true;
	}

    this.subscription.add(
      this.dataService.projectedDataList.subscribe((data) => {
        this.projectedDataArray = [];
        if (data.length) {
          this.projectedDataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
             e.status =  Number(e.status);
				if(e.status == 1){
					e.stslabel = '<span  pButton type="button" label="Success" class="p-button p-button-info p-component">Active Production Plan</span>';
				}else if(e.status == 2){
					e.stslabel = '<span  pButton type="button" label="Success" class="p-button p-button-success p-component">Completed Plan</span>';
				}else{
					e.stslabel = '<span  pButton type="button" label="Success" class="p-button p-button-danger p-component">Inactive Production Plan</span>';
				}
             // const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = true;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
	  this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.CompareOneDataArray = [];
        if (data.length) {
          this.CompareOneDataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              return e;
            });
        } else {
			this.CompareOneDataArray = [];
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
	  this.subscription.add(
      this.dataService.dataListtwo.subscribe((data) => {
        this.CompareTwoDataArray = [];
        if (data.length) {
			
          this.CompareTwoDataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
          e['canEdit'] = true;
          if(e.DelSts == 1){
            e['stslabel'] = '<span class="badge bg-success">'+ e.DeliveryStatus +'</span>';
           }else{
            e['stslabel'] = '<span class="badge bg-danger">'+ e.DeliveryStatus +'</span>';
           }
              return e;
            });
        } else {
			this.CompareTwoDataArray = [];
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      this.subscription.add(
        this.dataService.summarydata.subscribe((data) => {
          this.ComparesummaryDataArray = [];
          if (data.length) {        
            this.ComparesummaryDataArray = data.map((e, indx) => {
                if(indx == 0){
                  e['rowcolor'] = '#e9d185';
                }else{
                  e['rowcolor'] = '#60d6ef';
                }
              return e;
            });
          } else {
        this.ComparesummaryDataArray = [];
            this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
          }
        }));
      
	  this.subscription.add(
      this.dataService.detailsdata.subscribe((data) => {
        this.PlanDetailsDataArray = [];
        if (data.length) {
			
          this.PlanDetailsDataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
			   const action = this.util.getBatchstatuslevel(e.batch_hold);
          e['canEdit'] = true;
              return e;
            });
        } else {
			this.PlanDetailsDataArray = [];
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
  }
	public onCategoryChange(event) {
        this.dataObj.status = event.value;
        console.log(this.dataObj);
    }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getTableData() {
    if (this.orderFromDate && this.orderToDate) {
		this.dataService.getProjectedRecords(this.orderFromDate, this.orderToDate);
		
		this.dataService.getCategories(this.orderFromDate, this.orderToDate)
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label:"Plan Number - "+ ele.plan_id, value: ele };
        });
      });
      this.dataService.getCOmpareRecords(this.orderFromDate, this.orderToDate);
    }
  }
  public onRowEditInit() {
	   
   // this.clonedRecord[row.s_no] = { ...row };
  
  	
	this.iscomapreview = true;
  
  }
  public onCriticalityChange(event) {
	  console.log(event);
    this.selectedplan_idone = event.value.plan_id;
	// this.userObj.userRoleId = (event.value.id).userRoleId;
	
  }
    public onCriticalityChangeone(event) {
	  console.log(event);
    this.selectedplan_idtwo = event.value.plan_id;
	// this.userObj.userRoleId = (event.value.id).userRoleId;
	
  }
  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultPlancomparison);
    this.plan_edit_status = this.dataObj.status;
      this.isEditDetails = true;
    
  }

	public updateData(obj:Plancomparison) {
		this.updatecomment(obj);   
	}
	public onRowView(row:Plancomparison){
		this.isPlanDetailsview = true;
		this.detailsplan_id = row.plan_id;
		console.log(row);
		this.dataService.getPlanDetails(row.plan_id);
    }


  public updatecomment(row:Plancomparison) {
console.log(this.dataObj);
    this.dataService.updateStatus(this.orderFromDate, this.orderToDate,this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  private resetFormData() {
    this.isEdited = false;
	this.isEditDetails = false;
	this.isPlanDetailsview = false;
	this.dataService.getProjectedRecords(this.orderFromDate, this.orderToDate);
    this.projectedDataArray = [];
    this.getTableData();
  }
 

  // public cancelDlg() {
    // const dt = new Date();
    // dt.setDate(this.orderFromDate.getDate() - 30);
    // this.orderFromDate = dt;
    // this.orderToDate = new Date();
    // this.resetFormData();
  // }
    public cancelEditDlg() {
    this.isEditDetails = false;
  }
 public cancelDlg() {
    // this.resetFormData();
	this.iscomapreview = false;
  }
}
