import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  EquipmentMaster
  , EquipmentType
  , EquipmentSubType
  , ProductMaster
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EquipmentMasterService {

  public pageName = 'Equipment Master';
  public dataList = new Subject<Array<EquipmentMaster>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
		private msgSvc: MsgService,
		private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.equipmentMasterApi[apiOption];
  }

  public getEquipmentTypes(): Subject<Array<EquipmentType>> {
    const url = this.config.ipAddress + this.config.equipmentTypeApi[ApiActions.Get];
    const dataArray: Array<EquipmentType> = [];
    const data = new Subject<Array<EquipmentType>>();
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Equipmenttype.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getEquipmentSubTypes(): Subject<Array<EquipmentSubType>> {
    const url = this.config.ipAddress + this.config.equipmentSubTypeApi[ApiActions.Get];
    const dataArray: Array<EquipmentSubType> = [];
    const data = new Subject<Array<EquipmentSubType>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.Equipmentsubtype.map(element => {
            element.status = element.status == '1' ? true : false;
            dataArray.push(element);
          });
          data.next(dataArray);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getProductMasterRecords() {
    const productMasterList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList.filter(e => e.status > this.util.approvedStatus);
          productMasterList.next(list);
        }
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return productMasterList;
  }

  public getDataRecords() {
    const dataArray: Array<EquipmentMaster> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.equipmentMasterList) {
          response.result.equipmentMasterList.map(element => {
            const d = element.dateCommission.split('-');
            console.log(element.dateCommission, 'element.dateCommission');
            element.dateCommission = new Date(d[0], Number(d[1]) - 1, d[2], 0, 0, 0, 0);
            dataArray.push(element);
          });
        }
        this.dataList.next(dataArray);
      },
        err => {
            this.msgSvc.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objData: EquipmentMaster) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: EquipmentMaster) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: EquipmentMaster) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('equipmentStatus', objData.status + '');
    fData.append('equipmentId', objData.equipmentId + '');
    fData.append('eqptypeId', objData.equipmentTypeId + '');
    fData.append('eqpsubtypeId', objData.equipmentSubTypeId + '');
    fData.append('equipmentCode', objData.equipmentCode + '');
    fData.append('equipmentName', objData.equipmentName + '');
    fData.append('equipmentMake', objData.equipmentMake);
    fData.append('equipmentCapacity', objData.equipmentCapacity + '');
    fData.append('dateCommission', this.util.getDate(objData.dateCommission));
    fData.append('productId', objData.productId + '');
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
