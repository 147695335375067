import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { UnitLineMaster } from 'src/app/model';
import { LeftMenuService
  , MsgService
  , ProcessSchedulerService
  , UtilService } from 'src/app/service';
import 'linq-typed';
import { PlancomparisonService } from 'src/app/service/transaction/plancomparison.service';
import jsPDF from 'jspdf';
// @ts-ignore
require('jspdf-autotable');

@Component({
  selector: 'app-process-scheduler',
  templateUrl: './process-scheduler.component.html',
  styleUrls: ['./process-scheduler.component.scss']
})
export class ProcessSchedulerComponent implements OnInit {

  @ViewChild('pdfViewer', { static: true }) public pdfViewer;
  public pdfSrc = '';

  public schToDate = new Date();
  public schFromDate = new Date();

  private baseData: Array<UnitLineMaster> = [];

  public unitWiseList: Array<SelectItem> = [];
  public selectedUnitWise: any;
  public categoryList: Array<any> = [];
  public selectedplan_idone = 0;
  public unitLineWiseList: Array<SelectItem> = [];
  public selectedUnitLineWise: any;

  public equipmentWiseList: Array<SelectItem> = [];
  public selectedEquipmentWise: any;

  public productWiseList: Array<SelectItem> = [];
  public selectedProductWise: any;

  public tableData: Array<any>;
  public cols: any[];
  public showRpt = false;
  public myData: Array<any>;

  public get pageName() {
    return this.dataService.pageName;
  }

  private columnsPdf = [];
  constructor(
    private msgSvc: MsgService,
    private menuSvc: LeftMenuService,
    private dataService: ProcessSchedulerService,
	private PlancomparisonService: PlancomparisonService,
    public util: UtilService
  ) {
	  
	  const dt = new Date();
    dt.setDate(this.schFromDate.getDate() - 30);
    this.schFromDate = dt;
  }

  ngOnInit() {
    this.dataService.getUnitWise().subscribe(data => {
      this.baseData = data;
      const unitList = data.Select(e => {
        return { unitId: e.unitId, unitName: e.unitName };
      }).Distinct().ToArray();

      this.unitWiseList = unitList.map(ele => {
        return { label: ele.unitName, value: ele };
      });
    });

    this.dataService.getProductWiseList().subscribe(data => {
      this.productWiseList = data.map(ele => {
        return { label: ele.productMasterName, value: ele };
      });
    });
	
	this.PlancomparisonService.getCategories(this.schFromDate, this.schToDate)
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label:"Plan Number - "+ ele.plan_id, value: ele };
        });
      });
  }

	public get_plans(){
		this.PlancomparisonService.getCategories(this.schFromDate, this.schToDate)
      .subscribe(data => {
        this.categoryList = data.map(ele => {
			this.selectedplan_idone = ele.plan_id;
          return { label:"Plan Number - "+ ele.plan_id, value: ele };
        });
      });
		
	}


  public onUnitWiseChange(unit) {

    let unitList = [];
    this.equipmentWiseList = [];
    if (this.baseData.Count(e => e.unitId == unit.value.unitId)) {
      unitList = this.baseData.Where(e => e.unitId == unit.value.unitId).Select(x => {
        return { lineId: x.lineId, lineName: x.lineName };
      }).Distinct().ToArray();

      this.unitLineWiseList = unitList.map(ele => {
        return { label: ele.lineName, value: ele };
      });
    } else {
      this.unitLineWiseList = [];
    }
  }
  public onCriticalityChangeone(event) {
	  console.log(event);
    this.selectedplan_idone = event.value.plan_id;
	// this.userObj.userRoleId = (event.value.id).userRoleId;
	
  }
  public onUnitLineWiseChange(line) {
    const equipmentList = [];
    if (this.baseData.Count(e => e.lineId == line.value.lineId)) {
      const tmp = this.baseData.Where(e => e.lineId == line.value.lineId).Select(x => {
        return { ids: x.equipmentIds.split(','), names: x.equipmentNames.split(',') };
      }).ToArray();

      let resIds = [];
      let resNames = [];

      tmp.forEach(e => {
        resIds = resIds.concat(e.ids);
        resNames = resNames.concat(e.names);
      });

      resIds = resIds.filter((item, pos) => resIds.indexOf(item) === pos);
      resNames = resNames.filter((item, pos) => resNames.indexOf(item) === pos);

      for (let i = 0; i < resIds.length; i++) {
        if (resNames[i]) {
          equipmentList.push({ equipmentId: resIds[i], equipmentName: resNames[i] });
        }
      }

      this.equipmentWiseList = equipmentList.map(ele => {
        return { label: ele.equipmentName, value: ele };
      });

    } else {
      this.equipmentWiseList = [];
    }
  }

  public onProductWiseChange(event) {
  }

  public onEquipmentWiseChange(event) {
  }

  public preview() {
    const data = this.fillFilter();
    if(this.selectedplan_idone == 0){
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please Select Plan' });
    }else{
      this.dataService.getSchPreviewData(data).subscribe(sch => {
        this.myData = sch;
        this.columnsPdf = Object.keys(this.myData[0]);
        const cols = [];
        this.columnsPdf.forEach(col => {		
          cols.push({ field: col, header: col.replace('_', ' ') });
        });
        this.cols = cols;
        this.tableData = this.myData;
        this.showRpt = true;
        this.menuSvc.hideMenu();
      });
    }
   
  }

  private fillFilter() {
    const unitID = this.selectedUnitWise ? this.selectedUnitWise.unitId : null;
    const lineID = this.selectedUnitLineWise ? this.selectedUnitLineWise.lineId : null;
    const equipmentID = this.selectedEquipmentWise ? this.selectedEquipmentWise.equipmentId : null;
    const prodID = this.selectedProductWise ? this.selectedProductWise.productMasterId : null;
	const plan_id = this.selectedplan_idone;

    const data = {
      fromDate: this.schFromDate,
      toDate: this.schToDate,
      unitId: unitID,
      lineId: lineID,
      equipmentId: equipmentID,
      prodId: prodID,
	  plan_id: plan_id
    };
    return data;
  }

  public exportPdf() {
    const name = `${this.util.getDateFormat(this.schFromDate).toString().replace('/', '_')}`
      + `${this.util.getDateFormat(this.schToDate).toString().replace('/', '_')}`;

    const pdf = new jsPDF('l', 'mm', 'a1');

    pdf.text('Process Scheduler', 15, 10);

    const cols = this.columnsPdf.map(c => {
		
      return { title: c, dataKey: c };
    });
    // @ts-ignore
    pdf.autoTable(cols, this.myData);

    pdf.save('sch' + name + '.pdf');

  }
}
