export interface User {
    s_no?: number;
    userId?: number;
    userFName: string;
    userLName: string;
    userLogin: string;
    userRoleId: number;
    status: number;
    userManagerId: number;
    // userFromRoleIds: string;
    userRoleCode: string;
    // menuManageIds: string;
    password?: string;
}

export const defaultUser: User = {
    userId: 0,
    userFName: '',
    userLName: '',
    userLogin: '',
    userRoleId: 0,
    status: 0,
    userManagerId: 0,
    userRoleCode: '',
    password: ''
};
