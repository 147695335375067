export interface UnitWiseShiftTiming {
    s_no?: number;
    unitId: number;
    unitName?: string;
    unitShifts: string;
    breakhoursperDay: number;
    status: number;
    weeklyOffs: Array<string>;
    officialHolidays: Array<string>;
    noworkingDays: Array<string>;
    yearMonth: string;
}

export const defaultUnitWiseShiftTiming: UnitWiseShiftTiming = {
    unitId: 0,
    unitName: '',
    unitShifts: '',
    breakhoursperDay: 0,
    status: 0,
    weeklyOffs: void 0,
    officialHolidays: void 0,
    noworkingDays: void 0,
    yearMonth: ''
};
