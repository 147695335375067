export interface ItemMaster {
    s_no?: number;
    itemId: number;
    itemCode: string;
    itemDesc: string;
    status: number;
    itemCategoryId: number;
    itemGradeId: number;
    pharmaClassId: number;
	itemClassId: number;
    itemFormId: number;
    hsnCode: string;
    specLink: File;
    msdsLink: File;
    uom:string;
    uomId:number;
    stkqty:number;
    itemCategoryDesc?: string | undefined;
    itemGradeDesc?: string | undefined;
    itemClassDesc?: string | undefined;
    itemFormDesc?: string | undefined;
    itemMSDSDesc?: string | undefined;
}


export const defaultItemMaster: ItemMaster = {
    itemId: 0,
    itemCode: '',
    itemDesc: '',
    status: 0,
    itemCategoryId: 0,
    itemGradeId: 0,
    itemClassId: 0,
    pharmaClassId: 0,
    itemFormId: 0,
    hsnCode: '',
    stkqty:0,
    uomId:0,
    uom:'',
    specLink: undefined,
    msdsLink: undefined
};
