export interface InternalOrderItem {
    s_no?: number;
    intordCode: string;
    intordDate: Date;
    intordId: number;
    prodCode: string;
    prodId: number;
    prodName: string;
    prodSpecs: string;
    projectedQty: number;
    uomCode: string;
    uomDesc: string;
    projectedUOM: number;
    status: number;
}

export interface InternalOrder {
    s_no?: number;
    intordCode: string;
    intordDate: Date;
    intordId: number;
    ISOM: Array<InternalOrderItem>;
    status: number;
}

export const defaultInternalOrder = {
    intordCode: '',
    intordDate: new Date(),
    intordId: 0,
    ISOM: [],
    status: 0
};

export const defaultInternalOrderItem: InternalOrderItem = {
    intordId: 0,
    intordCode: '',
    intordDate: undefined,
    prodCode: '',
    prodId: 0,
    prodName: '',
    prodSpecs: '',
    projectedQty: 0,
    uomCode: '',
    uomDesc: '',
    projectedUOM: 0,
    status: 0
};

