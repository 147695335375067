export interface UnitMaster {
    s_no?: number;
    unitId: number;
    unitDesc: string;
    unitName: string;
    unitAddress: string;
    unitLicenseNo: string;
    noOfLines: number;
    gstinNo: string;
    manufacturedProds: string;
    status: number;
}

export const defaultUnitMaster: UnitMaster = {
    unitId: 0,
    unitName: '',
    unitDesc: '',
    unitAddress: '',
    unitLicenseNo: '',
    noOfLines: 0,
    gstinNo: '',
    manufacturedProds: '',
    status: 0
};
