export class Dailyproductionlog {
	
	s_no?: number;
    s_id?: number;
    col_id: number;
    run_num: string;
    batch_num: string;
    run_end_date: string;
    batch_comment: string;
    prod_id: number;
    finished_qty: number;
    rec_status: number;
	prod_code: number;
	batch_id: number;
	plan_id: number;
    batch_hold: number;
	
}
export const defaultDailyproductionlog = {
    s_id: 0,
    col_id: 0,
    run_num: '',
    batch_num: '',
    run_end_date: '',
    batch_comment: '',
    prod_id: 0,
    finished_qty: 0,
    rec_status: 0,
	prod_code: 0,
	batch_id: 0,
	plan_id: 0,
    batch_hold: 0
};