export interface CustomerType {
    s_no?: number;
    custtypeId: number;
    custtypeName: string;
    custtypeDesc: string;
    status: boolean;
}

export const defaultCustomerType: CustomerType = {
    custtypeId: 0,
    custtypeName: '',
    custtypeDesc: '',
    status: false
};

