/**
 * Service Master actions common to all services.
 */
export interface ServiceActions {
    /**
     * Page name of the Component and its service.
     */
    pageName: string;

    /**
     * Insert the object with the properties in DB.
     * @param Object as reference.
     */
    addData(object: {});

    /**
     * Updates the object properties in DB.
     * @param Object as reference.
     */
    updateData(object: {});

    /**
     * Deletes the particular record from DB with the given Id.
     * @param id Identity value of the record to delete.
     */
    deleteData(id: number);

    /**
     * Gets the data records fetched from the DB.
     */
    getDataRecords();

    // Note : Til now this is not required so commented.
    //  /**
    //   * Gets a data record fetched from the DB.
    //   * @param id Identity value of the record to fetch.
    //   */
    // getDataRecord(id: number);
}
