import { BrowserModule } from '@angular/platform-browser';
import {CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy ,PathLocationStrategy} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { PrimeNGModule } from './prime-module';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {ContextMenuModule} from 'primeng/contextmenu';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgbDatepickerModule, NgbDropdownModule, NgbAlertModule, NgbToastModule, NgbButtonsModule, NgbTabsetModule, NgbAccordionModule, NgbModalModule, NgbProgressbarModule, NgbPopoverModule, NgbTooltipModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AppServiceProvider, BusyCursorService
} from 'src/app/service';
import { HttpDataService } from './service/common/http-data.service';

import { TableModule } from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { TreeModule } from 'primeng/tree'; 
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListboxModule } from 'primeng/listbox';
//admin
import { UserComponent } from 'src/app/pages/admin/user/user.component';
import { UserRolesComponent } from 'src/app/pages/admin/user-roles/user-roles.component';
import { AdminMenuComponent } from 'src/app/pages/admin/admin-menu/admin-menu.component';
//master
import { ProductMasterComponent } from 'src/app/pages/master/product-master/product-master.component';
import { ProductSpecificationComponent } from 'src/app/pages/master/product-specification/product-specification.component';
import { BatchIdentityComponent } from 'src/app/pages/master/batch-identity/batch-identity.component';
import { ItemMasterComponent } from 'src/app/pages/master/item-master/item-master.component';
import { IntermediatesComponent } from 'src/app/pages/master/intermediates/intermediates.component';
import { EquipmentMasterComponent } from 'src/app/pages/master/equipment-master/equipment-master.component';
import { CustomerMasterComponent } from 'src/app/pages/master/customer-master/customer-master.component';
import { UnitMasterComponent } from 'src/app/pages/master/unit-master/unit-master.component';
import { UnitLineMasterComponent } from 'src/app/pages/master/unit-line-master/unit-line-master.component';
import { MasterFormulaComponent } from 'src/app/pages/master/master-formula/master-formula.component';
import { ProcessConfigComponent } from 'src/app/pages/master/process-config/process-config.component';
// sub-master
import { FieldsComponent } from 'src/app/pages/submaster/fields/fields.component';
import { FieldSeperatorComponent } from 'src/app/pages/submaster/field-seperator/field-seperator.component';
import { ProductCategoryComponent } from 'src/app/pages/submaster/product-category/product-category.component';
import { ProductPhysicalFormComponent } from 'src/app/pages/submaster/product-physical-form/product-physical-form.component';
import { ProductGradeComponent } from 'src/app/pages/submaster/product-grade/product-grade.component';
import { ProductPharmacopieaComponent } from 'src/app/pages/submaster/product-pharmacopiea/product-pharmacopiea.component';
import { ProductParameterComponent } from 'src/app/pages/submaster/product-parameter/product-parameter.component';
import { ProducttypeComponent } from 'src/app/pages/submaster/producttype/producttype.component';
import { ItemFormComponent } from 'src/app/pages/submaster/item-form/item-form.component';
import { ItemCategoryComponent } from 'src/app/pages/submaster/item-category/item-category.component';
import { ItemClassComponent } from 'src/app/pages/submaster/item-class/item-class.component';
import { ItemGradeComponent } from 'src/app/pages/submaster/item-grade/item-grade.component';
import { ItemtypeComponent } from 'src/app/pages/submaster/itemtype/itemtype.component';
import { EquipmentTypeComponent } from 'src/app/pages/submaster/equipment-type/equipment-type.component';
import { EquipmentSubTypeComponent } from 'src/app/pages/submaster/equipment-sub-type/equipment-sub-type.component';
import { LineMasterComponent } from 'src/app/pages/submaster/line-master/line-master.component';
import { CustomerTypeComponent } from 'src/app/pages/submaster/customer-type/customer-type.component';
import { ProcessConfigStepsComponent } from 'src/app/pages/submaster/process-config-steps/process-config-steps.component';
import { ProcessConfigParamsComponent } from 'src/app/pages/submaster/process-config-params/process-config-params.component';

// production
import { UnitWiseShiftTimingComponent } from 'src/app/pages/scheduling/unit-wise-shift-timing/unit-wise-shift-timing.component';
import { EquipmentOperatingTimingComponent } from 'src/app/pages/scheduling/equipment-operating-timing/equipment-operating-timing.component';
import { EquipmentLineClearanceTimingsComponent } from 'src/app/pages/scheduling/equipment-line-clearance-timings/equipment-line-clearance-timings.component';
import { CustomerOrderComponent } from 'src/app/pages/scheduling/customer-order/customer-order.component';
import { InternalOrderComponent } from 'src/app/pages/scheduling/internal-order/internal-order.component';
import { SalesForecastComponent } from 'src/app/pages/scheduling/sales-forecast/sales-forecast.component';
import { LotPriorityPlanningComponent } from 'src/app/pages/scheduling/lot-priority-planning/lot-priority-planning.component';
import { ProductionOrderSequenceGeneratorComponent } from 'src/app/pages/scheduling/production-order-sequence-generator/production-order-sequence-generator.component';
import { ProcessSchedulerComponent } from 'src/app/pages/scheduling/process-scheduler/process-scheduler.component';
import { PlancomparisonComponent } from 'src/app/pages/scheduling/plancomparison/plancomparison.component';
import { DailyproductionlogComponent } from 'src/app/pages/scheduling/dailyproductionlog/dailyproductionlog.component';
import { DispatchQuantityComponent } from 'src/app/pages/scheduling/dispatch-quantity/dispatch-quantity.component';
//import { TopsearchComponent } from 'src/app/pages/topsearch/topsearch.component';

import { ProductinventoryComponent } from 'src/app/pages/inventory/products-inventory/products-inventory.component';
import { RawmaterialsinventoryComponent } from 'src/app/pages/inventory/raw-materials-inventory/raw-materials-inventory.component';
@NgModule({
  declarations: [
    AppComponent,UserComponent,UserRolesComponent,AdminMenuComponent,ProductMasterComponent,ProductSpecificationComponent,BatchIdentityComponent,ItemMasterComponent,IntermediatesComponent,EquipmentMasterComponent,CustomerMasterComponent,UnitMasterComponent,UnitLineMasterComponent,MasterFormulaComponent,ProcessConfigComponent,FieldsComponent,FieldSeperatorComponent,ProductCategoryComponent,ProductPhysicalFormComponent,ProductGradeComponent,ProductPharmacopieaComponent,ProductParameterComponent,ProducttypeComponent,ItemFormComponent,ItemCategoryComponent,ItemClassComponent,ItemGradeComponent,ItemtypeComponent,EquipmentTypeComponent,EquipmentSubTypeComponent,LineMasterComponent,CustomerTypeComponent,ProcessConfigStepsComponent,ProcessConfigParamsComponent,UnitWiseShiftTimingComponent,EquipmentOperatingTimingComponent,EquipmentLineClearanceTimingsComponent,CustomerOrderComponent,InternalOrderComponent,SalesForecastComponent,LotPriorityPlanningComponent,ProductionOrderSequenceGeneratorComponent,ProcessSchedulerComponent,PlancomparisonComponent,DailyproductionlogComponent,DispatchQuantityComponent,ProductinventoryComponent,RawmaterialsinventoryComponent
  ],

  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    DropzoneModule,
    AppRoutingModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbToastModule,
    NgbTabsetModule,
    NgbButtonsModule,
    NgbNavModule,
    SplitButtonModule,
    InputNumberModule,
    NgbAccordionModule,
    ToastModule,
    TreeModule,
    ContextMenuModule,
    SelectButtonModule,
    ListboxModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTooltipModule,
    PrimeNGModule,
    TableModule,
    DropdownModule,
    ButtonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
  providers: [BusyCursorService,
    MessageService,
    AppServiceProvider,
    { provide: HTTP_INTERCEPTORS, useClass: HttpDataService, multi: true },
	{provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
