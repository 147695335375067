export interface Intermediates {
    s_no?: number;
    productMasterId: number;
    productMasterCode: string;
    productMasterName: string;
    status: number;
    productCategoryId: number;
    productGradeId: number;
    pharmaCopiId: number;
    productUOM: number;
    productSpecDoc: File | string;
	productMsdsDoc: File | string;
    productForm: number;
	productForm_id: number;
    productCategoryDesc?: string | undefined;
	productFormdesc?: string | undefined;
    pharmaCopiDesc?: string | undefined;
    productGrade?: string | undefined;
    pharmaDesc?: string | undefined;
	Make?: string | undefined;
	Apiproduct?: string | undefined;
	ApiName?: string | undefined;
}

export const defaultIntermediates = {
    productMasterId: 0,
    productMasterCode: '',
    productMasterName: '',
    productCategoryId: 0,
    productGradeId: 0,
    pharmaCopiId: 0,
    productUOM: 0,
    productSpecDoc: '',
	productMsdsDoc: '',
    pharmaCopiDesc: '',
	Make: '',
	ApiName: '',
	Apiproduct: '',
    productForm: 0,
	productForm_id: 0,
    status: 0
};