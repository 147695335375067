import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';

export enum MsgSeverity {
    success = 'success',
    info = 'info',
    warn = 'warn',
    error = 'error',
    clear = 'clear'
}

@Injectable({
  providedIn: 'root'
})
export class MsgService {

  constructor(private msgService: MessageService) { }

  public addSuccessMsg(msg: Message) {
    msg.severity = MsgSeverity.success;
    setTimeout(() => { this.msgService.add(msg); }, 10);
  }

  public addInfoMsg(msg: Message) {
    msg.severity = MsgSeverity.info;
    setTimeout(() => { this.msgService.add(msg); }, 10);
  }

  public addWarnMsg(msg: Message) {
    msg.severity = MsgSeverity.warn;
    setTimeout(() => { this.msgService.add(msg); }, 10);
  }

  public addErrorMsg(msg: Message) {
    msg.severity = MsgSeverity.error;
    setTimeout(() => { this.msgService.add(msg); }, 10);
  }

  public add(severity: string, msg: Message) {
    msg.severity = severity;
    setTimeout(() => { this.msgService.add(msg); }, 10);
  }

  public clear() {
    this.msgService.clear();
  }

  public getMsgObserver() {
    return this.msgService.messageObserver;
  }
}
