import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusyCursorService {
  public isShowBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  public show() {
    setTimeout(() => { this.isShowBusy.next(true); }, 10);
  }

  public hide(waitTime: number = 10) {
    setTimeout(() => { this.isShowBusy.next(false); }, waitTime);
  }
}
