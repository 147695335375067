import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import {
  Intermediates
  , GridHeaderEx
  , defaultIntermediates
  , GridHeader
} from 'src/app/model';
import { MsgService, IntermediatesService,ProductMasterService, UtilService, UserType, AppConfigService } from 'src/app/service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-intermediates',
  templateUrl: './intermediates.component.html',
  styleUrls: ['./intermediates.component.scss']
})
export class IntermediatesComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Intermediates>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public selectedCategory : Array<SelectItem> = [];
  public dataObj: Intermediates = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public selectedMake: Array<SelectItem> = [];
  public categoryList: Array<SelectItem> = [];
   public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }
 public criticalityLevels: Array<SelectItem> =
    [{ label: 'Make', value: 'Make' }, { label: 'Buy', value:'Buy' }];
  public selectedCriticality = this.criticalityLevels[0].value;
  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
	{ field: 'productMasterId', header: 'Id', isVisible: false, isDefault: true },
	{ field: 'ApiName', header: 'API Name', isVisible: true, isDefault: true },
    { field: 'productMasterCode', header: 'Intermediate Code', isVisible: true, isDefault: true },
    { field: 'productMasterName', header: 'Intermediate Name', isVisible: true, isDefault: true },
	
	{ field: 'Make', header: 'Make/Buy', isVisible: true, isDefault: true },
    { field: 'productSpecDoc', header: 'Spec Doc', isVisible: false, isDefault: true },
	{ field: 'productMsdsDoc', header: 'Msds Doc', isVisible: false, isDefault: true },
    { field: 'productFormdesc', header: 'Form', isVisible: false, isDefault: false },
    { field: 'productCategoryDesc', header: 'Category', isVisible: true, isDefault: true },
    { field: 'pharmaCopiDesc', header: 'Pharma Copiea', isVisible: true, isDefault: true },
    { field: 'productGrade', header: 'Release Profile', isVisible: false, isDefault: true },
    { field: 'pharmaDesc', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];


  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: IntermediatesService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {

    this.dataObj = Object.assign({}, defaultIntermediates);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataTableRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = false;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = true;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
this.loadDropDownLists();
  }
  private loadDropDownLists() {
    this.dataService.getCategories()
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label: ele.productMasterName, value: ele };
        });
      });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

 

 

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultIntermediates);
    if (event == null) {
      this.isAddDetails = true;
	  
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {

    if (this.dataObj.Make) {
      this.selectedMake = this.criticalityLevels
        // tslint:disable-next-line: triple-equals
        .find(e => e.value == this.dataObj.Make).value;
    }
 
  }
 public onCategoryChange(event) {
    //this.dataObj.productCategoryId = (event.value as ProductCategory).categoryId;
	this.dataArray = [];
	console.log(event.value.productMasterId);
	this.dataService.getApiproducts(event.value.productMasterId)
      .subscribe(dataList => {
        this.dataArray = dataList.map((e, indx) => {
            e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = false;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = true;
              return e;
        });
      });
  }
  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
	  this.dataService.getDataTableRecords();
    });
  }

  public viewData(obj: Intermediates) {
    this.dataObj = obj;
    this.isViewDetails = true;

  }



  public isFormValid() {
     return true;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }



	private resetFormData() {
		this.isViewDetails = false;
		this.isEditShow = false;
		this.dataObj = Object.assign({}, defaultIntermediates);

	}


  public onCriticalityChange(event) {
    this.dataObj.Make = event.value;
  }
  public viewPdf(file: string) {
    return this.config.ipAddress + '/uploads/specs_doc/' + file;
  }
}
