export interface RefItemClass {
	s_no?: number;
    RefItemClassId: number;
	itemClassId: number;
    itemClassCode: string;
    itemClassDesc: string;
    status: boolean;
	
}

export const defaultRefItemClass = {
    RefItemClassId: 0,
	itemClassId: 0,
    itemClassCode: '',
    itemClassDesc: '',
    status: false
};
