import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GridHeader
  , GridHeaderEx
  , EquipmentLineClearanceTimings
  , defaultEquipmentLineClearanceTimings
} from 'src/app/model';
import {
  UtilService
  , MsgService
  , AppConfigService
  , UserType, EquipmentLineClearanceTimingsService
} from 'src/app/service';
import { SelectItem, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-equipment-line-clearance-timings',
  templateUrl: './equipment-line-clearance-timings.component.html',
  styleUrls: ['./equipment-line-clearance-timings.component.scss']
})
export class EquipmentLineClearanceTimingsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<EquipmentLineClearanceTimings>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: EquipmentLineClearanceTimings = defaultEquipmentLineClearanceTimings;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public equipmentMasterList: Array<SelectItem> = [];
  public selectedEquipment: Array<EquipmentLineClearanceTimings>;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'equipmentName', header: 'Equipment Name', isVisible: true, isDefault: true },
    { field: 'batchChangeOver', header: 'Batch ChangeOver', isVisible: true, isDefault: true },
    { field: 'restart8hoursIdeal', header: 'Restart 8Hrs Ideal', isVisible: true, isDefault: true },
    { field: 'productChangeOver', header: 'Product ChangeOver', isVisible: true, isDefault: true },
    { field: 'startAfterMaintenance', header: 'startAfterMaintenance', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: EquipmentLineClearanceTimingsService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultEquipmentLineClearanceTimings);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getEquipments().subscribe(data => {
      this.equipmentMasterList = data.map(ele => {
        return { label:  ele.equipmentName+'  ('+ele.equipmentCode+ ') ', value: ele };
      });
    });
  }

  public getProdName(id: string) {
    const prod = this.equipmentMasterList.find(e => e.value.unitId + '' === id);
    return prod ? prod.value.unitName : '';
  }


  public onEquipmentChange(event) {
    this.dataObj.equipmentId = event.value.equipmentId;
    this.dataObj.equipmentName = event.value.equipmentName;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultEquipmentLineClearanceTimings);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    const equipment = this.equipmentMasterList.find(e => e.value.equipmentId == this.dataObj.equipmentId);
    this.selectedEquipment = equipment ? equipment.value : equipment;
  }

  public updateData() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: EquipmentLineClearanceTimings) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: EquipmentLineClearanceTimings) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (
      this.dataObj.equipmentId === 0 ||
      this.dataObj.batchChangeOver === 0 ||
      this.dataObj.productChangeOver === 0 ||
      this.dataObj.restart8hoursIdeal === 0 ||
      this.dataObj.startAfterMaintenance === 0) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.dataObj.equipmentId !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Equipment code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultEquipmentLineClearanceTimings);
  }

}
