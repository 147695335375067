import { AfterViewInit, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DispatchQuantity, GridHeader, GridHeaderEx } from 'src/app/model';
import { UserType, MsgService, UtilService, AppConfigService, DispatchQuantityService, UserRoleValue } from 'src/app/service';

import { SelectItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { UserRolesService } from 'src/app/service/master/user-roles.service';

@Component({
  selector: 'app-dispatch-quantity',
  templateUrl: './dispatch-quantity.component.html',
  styleUrls: ['./dispatch-quantity.component.scss']
})
export class DispatchQuantityComponent implements AfterViewInit {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<DispatchQuantity>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public orderFromDate: Date = new Date();
  public orderToDate: Date = new Date();
  public ordersList: Array<any> = [];
  public productList: Array<any> = [];
  public selectedOrder: any;
  private editedItemsList: Array<number> = [];
  public UOMList: Array<SelectItem> = [];
  public selectedUOM: SelectItem;

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get pageName() {
    return this.dataService.pageName;
  }
  private clonedRecord: { [s: string]: DispatchQuantity; } = {};

  /** grid columns and data fields */
  public confirmCols: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'customerPurchaseOrder', header: 'Customer PO', isVisible: true, isDefault: true },
    { field: 'customerPurchaseOrderDate', header: 'OrderDate', isVisible: true, isDefault: true },
    { field: 'custCode', header: 'Customer Code', isVisible: true, isDefault: true },
    { field: 'prodCode', header: 'Product Code', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'prodSpecs', header: 'Product SPecs', isVisible: true, isDefault: true },
    { field: 'orderQuantity', header: 'Order Qty', isVisible: true, isDefault: true },
    { field: 'BatchNumber', header: 'Batch Number', isVisible: true, isDefault: true },
    { field: 'FinishedQty', header: 'Finished Qty', isVisible: true, isDefault: true },
    { field: 'dispatchQuantity', header: 'Dispatch Qty', isVisible: true, isDefault: true },
    { field: 'UOM', header: 'UOM', isVisible: true, isDefault: true },
  ];

  public pDropdownStyle = {};

  constructor(
    private http: HttpClient,
    private msgSvc: MsgService,
    private dataService: DispatchQuantityService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    // this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
          // Load orderList.
          const distinctBy = (prop, arr) => [...new Set(arr.map(o => o[prop]))]
          const result = distinctBy('customerPurchaseOrder', this.dataArray)
          this.ordersList = [
            ... new Set(
              result.map(e => {
              console.log(e);
              return { label: e, value: e };
            }))
          ];
          //product list productList
          const productresult = distinctBy('prodCode', this.dataArray)
          this.productList = [
            ... new Set(
              productresult.map(e => {
              console.log(e);
              return { label: e, value: e };
            }))
          ];
          
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele.uomCode };
        });
      });
  }

  ngAfterViewInit() {
  }

  public getTableData() {
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getDataRecords(this.orderFromDate, this.orderToDate);
    }
  }

  public updateData() {
    const editedList = this.getListOfItemsToSave();
    if (editedList.length) {
      this.dataService.updateData(editedList).subscribe((data) => {
        this.resetFormData();
      });
    }
  }

  public saveAsDraft() {
    const editedList = this.getListOfItemsToSave(true);
    this.dataService.saveDraft(editedList).subscribe((data) => {
      this.resetFormData();
    });
  }
  public onUmoChange(event) {
   //this.dataArray.UOM = event.value;

  }
  private getListOfItemsToSave(isDraftSave = false) {
    const editedList = [];
    // tslint:disable-next-line: triple-equals
    if (this.dataArray.some(r => r.dispatchQuantity == 0) && !isDraftSave) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide Dispatch Quantity for all.' });
    } else {
      if (this.editedItemsList.length) {
        this.dataArray.forEach(row => {
          if (this.editedItemsList.some(e => e === row.s_no)) {
            editedList.push(Object.assign({}, row));
          }
        });
      }
    }
    return editedList;
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public cancelDlg() {
    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
    this.orderToDate = new Date();
    this.resetFormData();
  }

  private resetFormData() {
    this.editedItemsList = [];
    this.getTableData();
  }

  public onRowEditInit(row) {
    this.clonedRecord[row.s_no] = { ...row };
  }

  public onRowEditSave(row, index: number) {
    const dQty = row.dispatchQuantity;
    const fQty = row.FinishedQty;

    if (dQty => 0 && dQty <= Number(row.FinishedQty)) {
      delete this.clonedRecord[row.s_no];
      if (!this.editedItemsList.some(e => e === row.s_no)) {
        this.editedItemsList.push(row.s_no);
      }
    } else {
      this.onRowEditCancel(row, index);
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Invalid Dispatch Quantity' });
    }
  }

  public onRowEditCancel(row, index: number) {
    this.dataArray[index] = this.clonedRecord[row.s_no];
    delete this.clonedRecord[row.s_no];
  }

}
