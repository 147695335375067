import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeaderEx,
  GridHeader,
  ProductUOM, InternalOrder, defaultInternalOrder, InternalOrderItem, defaultInternalOrderItem
} from 'src/app/model';
import {
  UtilService,
  MsgService,
  UserType,
  AppConfigService,
  InternalOrderService
} from 'src/app/service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-internal-order',
  templateUrl: './internal-order.component.html',
  styleUrls: ['./internal-order.component.scss']
})
export class InternalOrderComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<any>;
  public itemDataArray: Array<any> = [];
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: InternalOrder = defaultInternalOrder;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public itemObj: InternalOrderItem = Object.assign({}, defaultInternalOrderItem);

  public productMasterList: Array<SelectItem> = [];
  public selectedProduct: SelectItem;

  public UOMList: Array<SelectItem> = [];
  public selectedUOM: ProductUOM;

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  public get pageName() {
    return this.dataService.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'intordCode', header: 'Internal Order Code', isVisible: true, isDefault: true },
    { field: 'intordDate', header: 'Internal Order Date', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public itemCols = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'prodCode', header: 'Product Code', isVisible: true, isDefault: true },
    { field: 'prodName', header: 'Product Name', isVisible: true, isDefault: true },
    { field: 'prodSpecs', header: 'Specifications', isVisible: true, isDefault: true },
    { field: 'uomCode', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'projectedQty', header: 'Projected Qty', isVisible: true, isDefault: true }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: InternalOrderService,
    public util: UtilService,
    private config: AppConfigService
  ) {
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
  }

  ngOnInit() {
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public onProductChange(event) {
    const prod = event.value;
    this.itemObj.prodId = prod.productMasterId;
    this.itemObj.prodName = prod.productMasterName;
    this.itemObj.prodCode = prod.productMasterCode;
  }

  public onUOMChange(event) {
    this.itemObj.projectedUOM = event.value.uomId;
    this.itemObj.uomCode = event.value.uomCode;
  }

  public deleteItemRow(event) {
    this.itemDataArray.splice(
      this.itemDataArray.findIndex(e => e.s_no === event.s_no), 1);
    this.itemDataArray.forEach((ele, ind) => { ele.s_no = ind + 1; });
  }

  private loadDropDownLists() {

    this.dataService.getProductsData()
      .subscribe(data => {
        this.productMasterList = data.map(ele => {
          return { label: ele.productMasterName, value: ele };
        });
      });

    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
      });
  }

  public updateData() {
    this.dataObj.ISOM = this.itemDataArray;
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.resetFormData();
    });
  }

  public viewData(obj: InternalOrder) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultInternalOrder);

    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    this.itemDataArray = this.dataObj.ISOM.map((ele, indx) => {
      // tslint:disable-next-line: no-string-literal
      ele.s_no = indx + 1;
      // console.log(this.UOMList, ' <--- list of items, element -->', ele);
      ele.uomCode = this.UOMList.find(e => e.value.uomId == ele.projectedUOM).value.uomCode;
      return ele;
    });
  }

  public deleteData(obj: InternalOrder) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    let result = false;
    if (
      this.dataObj.intordId === 0 ||
      this.dataObj.intordCode.length === 0 ||
      this.dataObj.intordDate === undefined ||
      this.dataObj.ISOM.length === 0
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public addToItemList() {
    if (this.itemObj.prodId === 0 || this.itemObj.prodSpecs.trim().length === 0 ||
      this.itemObj.projectedQty === 0) {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provide valid data to add' });
    } else {
      const tmp = Object.assign({}, this.itemObj);
      tmp.s_no = this.itemDataArray.length + 1;
      tmp.uomCode = this.selectedUOM.uomCode;
      const list = [... this.itemDataArray];
      list.push(tmp);
      this.itemDataArray = list;

      // Reset all the properties.
      this.selectedProduct = undefined;
      this.selectedUOM = undefined;
      this.itemObj = Object.assign({}, defaultInternalOrderItem);
    }
  }

  public cancelEditDlg() {
    this.resetFormData();
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    this.dataObj.ISOM = this.itemDataArray;
    if (this.dataObj.intordCode.trim() !== '') {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Process config details' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.selectedProduct = undefined;
    this.itemDataArray = [];
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultInternalOrder);
  }

}
