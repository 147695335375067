export interface ProductPhysicalForm {
    s_no?: number;
    categoryId: number;
    categoryCode: string;
    categoryDesc: string;
    status: boolean;
}

export const defaultProductPhysicalForm = {
    categoryId: 0,
    categoryCode: '',
    categoryDesc: '',
    status: false
};
