export interface LineMaster {
    s_no?: number;
    lineId: number;
    lineName: string;
    status: boolean;
}

export const defaultLineMaster = {
    lineId: 0,
    lineName: '',
    status: false,
};
