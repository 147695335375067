import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LotPriorityPlanning, GridHeader } from 'src/app/model';
import { UserType, MsgService, UtilService, AppConfigService, LotPriorityPlanningService,PlancomparisonService } from 'src/app/service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-lot-priority-planning',
  templateUrl: './lot-priority-planning.component.html',
  styleUrls: ['./lot-priority-planning.component.scss']
})
export class LotPriorityPlanningComponent {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<LotPriorityPlanning>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj = {};
  public orderFromDate: Date = new Date();
  public orderToDate: Date = new Date();
  public selectedplan_idone =0;
  public LotPriorityPlanningList: Array<SelectItem> = [];
  public selectedLotPriorityPlanning: LotPriorityPlanning;
  private editedItemsList: Array<number> = [];
  public plansList: Array<any> = [];
  public isSubmitBtnDisabled = true;

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get pageName() {
    return this.dataService.pageName;
  }
  private clonedRecord: { [s: string]: LotPriorityPlanning; } = {};

  /** grid columns and data fields */
  public confirmCols: Array<GridHeader> = [
    { field: 'priorityNum', header: 'Priority' },
    { field: 'customerPurchaseOrder', header: 'Customer PO' },
    { field: 'custPODate', header: 'Customer PO Date' },
    { field: 'custCode', header: 'Customer Code' },
    { field: 'prodCode', header: 'Product Code' },
    { field: 'orderQuantity', header: 'QTY' },
	{ field: 'finished_qty', header: 'Finished QTY' },
	{ field: 'balance_qty', header: 'Balance QTY' },
    { field: 'UOM', header: 'UOM' },
    { field: 'deliveryDate', header: 'Delivery Date' },
    { field: 'batchSize', header: 'Batch Output' },
    { field: 'numberOfLots', header: 'Split Order No.Of LOTS' },
    { field: 'transitTime', header: 'Transit Time/Cycle Time' },
    { field: 'dispatchDate', header: 'Factory Dispatch Date/Ready Date' },
    { field: 'prodBatchesNumber', header: 'No.Of Production Batches' },
	{ field: 'status', header: 'Action' }
  ];

  constructor(
    private msgSvc: MsgService,
    private dataService: LotPriorityPlanningService,
    public util: UtilService,    
    private PlancomparisonService: PlancomparisonService,
    private config: AppConfigService
  ) {
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              if(e.api_prod == 1){
                e['canEdit'] = action.canEdit && this.util.getRecWith(e.recWith);
              }else{
                e['canEdit'] = false;				
              }
			  
              // e['canEdit'] = action.canEdit && this.util.getRecWith(e.recWith);
              //console.log(e['canEdit'], ' <-- Can Edit   , RecWith Same Role -->', this.util.getRecWith(e.recWith));
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
	  
	  const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
  }

  private resetFormData() {
    this.editedItemsList = [];
    this.getTableData();
  }

  public getTableData() {
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getDataRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
    }
  }
  public get_plans(){
		this.PlancomparisonService.getsaleforcastplans(this.orderFromDate, this.orderToDate)
      .subscribe(data => {
        this.plansList = data.map(ele => {
			this.selectedplan_idone = ele.plan_id;
          return { label:"Plan Number - "+ ele.plan_id, value: ele.plan_id };
        });
        console.log(this.selectedplan_idone);
      });
		
	}
  public onCriticalityChangeone(event) {
    this.selectedplan_idone = event.value;
    console.log(this.selectedplan_idone);
    if (this.orderFromDate && this.orderToDate) {
      this.dataService.getDataRecords(this.orderFromDate, this.orderToDate,this.selectedplan_idone);
    }
  }
  public onRowEditInit(row: LotPriorityPlanning) {
    this.isSubmitBtnDisabled = false;
    this.clonedRecord[row.s_no] = { ...row };
  }

  public onRowEditSave(row, index: number) {

    // tslint:disable-next-line: no-string-literal
    const deliveryDt = row['deliveryDate'];
    const tTime = row.transitTime;
    const d = new Date(deliveryDt);
    d.setDate(d.getDate() - tTime);
    row.dispatchDate = d;
	const result = this.dataArray.filter(f=>{
		if(f.api_prod == 0 && f.api_id == row.prodId ){
					f.dispatchDate = row.dispatchDate;
					f.transitTime = row.transitTime;
			  f.numberOfLots = row.numberOfLots;
			 return f;
			
		}
		
		
	});
	this.dataArray = [...this.dataArray];
	
    let invalid = false;
    // console.log(row.dispatchDate.getTime()+"....."+row.deliveryDate.getTime());
    if (row.dispatchDate.getTime() > row.deliveryDate.getTime()) {
      invalid = true;
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Invalid Transit Time' });
    }

    // split lot
    if (Number(row.splitorderlots) > Number(row.ordQty) / 2) {
      invalid = true;
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Split Order No.Of LOTS should be grater then Order Quantity' });
    }

    if (invalid) {
      this.onRowEditCancel(row, index);
    } else {
      this.editedItemsList.push(row.s_no);
    }
  }

  public onRowEditCancel(row: LotPriorityPlanning, index: number) {
    this.dataArray[index] = this.clonedRecord[row.s_no];
    delete this.clonedRecord[row.s_no];
  }

  public updateData() {
    const editedList = [];
    this.dataArray.forEach(row => {
      editedList.push(Object.assign({}, row));
    });

    if (editedList.length) {
      this.dataService.updateData(this.orderFromDate, this.orderToDate, editedList).subscribe((data) => {
        this.resetFormData();
      });
    }
  }

  
  public saveAsDraft() {
     const editedList = [];
    this.dataArray.forEach(row => {
      editedList.push(Object.assign({}, row));
    });
    if (editedList.length) {
      this.dataService.saveDraft(this.orderFromDate, this.orderToDate, editedList).subscribe((data) => {
        this.resetFormData();
      });
    }
  }

  private updateEditedList() {
    const editedList = [];
    if (this.editedItemsList.length) {
      this.dataArray.forEach(row => {
        if (this.editedItemsList.some(e => e === row.s_no)) {
          editedList.push(Object.assign({}, row));
        }
      });
    }
    return editedList;
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public cancelDlg() {
    const dt = new Date();
    dt.setDate(this.orderFromDate.getDate() - 30);
    this.orderFromDate = dt;
    this.orderToDate = new Date();
    this.resetFormData();
  }


}
