import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProductUOM, ProductMaster, InternalOrder
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class InternalOrderService {

  public pageName = 'Internal Order';
  public dataList = new Subject<Array<InternalOrder>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.internalOrderApi[apiOption];
  }

  public getUOMCodes() {
    const url = this.config.ipAddress + this.config.productUOMApi[ApiActions.Get];
    const data = new Subject<Array<ProductUOM>>();

    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const result = response.result.UnitsOfMeasurement;
          data.next(result);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return data;
  }

  public getProductsData() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          const list = response.result.productMasterList.filter(e => e.status > this.util.approvedStatus);
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getDataRecords() {
    let resultList = new Array<InternalOrder>();
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.InternalSalesOrderMaster) {
          resultList = response.result.InternalSalesOrderMaster.map(e => {
            e.intordDate = new Date(e.intordDate);
            e.status = e.recStatus;
            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public updateData(objData: InternalOrder) {
    objData.status = this.util.getSaveStatus();
    return this.Save(this.getUrl(ApiActions.Update), objData);
  }

  public saveDraft(objData: InternalOrder) {
    objData.status = this.util.getDraftStatus();
    return this.Save(this.getUrl(ApiActions.DraftSave), objData);
  }

  private Save(action: string, objData: InternalOrder) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();

    fData.append('intordId', objData.intordId + '');
    fData.append('internalOrderCode', objData.intordCode + '');
    fData.append('internalOrderDate', this.util.getDate(objData.intordDate));
    fData.append('status', objData.status + '');

    const items = objData.ISOM.map(e => {
      return {
        prodId: e.prodId,
        prodSpecs: e.prodSpecs,
        projectedQty: e.projectedQty,
        uomId: e.projectedUOM
      };
    });

    fData.append('items', JSON.stringify(items));

    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
