import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  GridHeaderEx
  , GridHeader
  , MasterActions
  , Customer, defaultCustomer
} from 'src/app/model';
import { Subscription } from 'rxjs';
import { MsgService, UtilService, AppConfigService,UserType } from 'src/app/service';
import { CustomerMasterService } from 'src/app/service/master/customer-master.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-customer-master',
  templateUrl: './customer-master.component.html',
  styleUrls: ['./customer-master.component.scss']
})
export class CustomerMasterComponent implements OnInit, OnDestroy, MasterActions {
  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<Customer>;
  public selectedRows: Array<any> = [];
  public CountryList: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: Customer = defaultCustomer;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;
  public selectedcountry: SelectItem;
  public customerTypeList: Array<SelectItem> = [];
  public selectedCustomerType: SelectItem;
  public editCallTypeList: any = {};

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'custId', header: 'Id', isVisible: false, isDefault: true },
    { field: 'custCode', header: 'Customer Code', isVisible: true, isDefault: true },
    { field: 'country_name', header: 'Customer Country', isVisible: true, isDefault: true },
    { field: 'custAddress', header: 'Customer Address', isVisible: true, isDefault: true },
    { field: 'custtypeCode', header: 'Customer Type', isVisible: true, isDefault: true },
    { field: 'custName', header: 'Customer Name', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }


  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }
  constructor(
    private msgSvc: MsgService,
    private dataService: CustomerMasterService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {

    this.dataObj = Object.assign({}, defaultCustomer);
    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data.map((e, indx) => {
            e.s_no = indx + 1;
            e['custtypeCode'] = this.getCustomerCode(e.custtypeId);
            return e;
          });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));
      
    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getCustomerCode(custTypeId: number) {
    // tslint:disable-next-line: triple-equals
    const custType = this.customerTypeList.find(c => c.value.custtypeId  == custTypeId );
    return custType ? custType.label : '';
  }

  private loadDropDownLists() {
    this.dataService.getCustomerTypes()
      .subscribe(data => {
        this.customerTypeList = data.map(ele => {
          return { label: ele.custtypeName, value: ele };
        });
        this.dataService.getDataRecords();
        this.dataService.getCountryList();
      });
      this.dataService.getCountryList()
      .subscribe(data => {
        this.CountryList = data.map(ele => {
          return { label: ele.country_name, value: ele };
        });
      });
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultCustomer);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    if (this.dataObj.custtypeId) {
      const item = this.customerTypeList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.custtypeId == this.dataObj.custtypeId);
      this.selectedCustomerType = item ? item.value : undefined;
    }
    if (this.dataObj.custCountry) {
      const item = this.CountryList
        // tslint:disable-next-line: triple-equals
        .find(e => e.value.country_id == this.dataObj.custCountry);
      this.selectedcountry = item ? item.value : undefined;
    }
  }

  public onCustomerTypeChange(event) {
    this.dataObj.custtypeId = (event.value as Customer).custtypeId;
  }
  public onCountryChange(event) {
    console.log(event);
    this.dataObj.custCountry = event.value.country_id ;
  }
  
  public updateData() {
    if (this.isAddDetails) {
      this.createDetails();
    } else {
      this.updateCallType();
    }
  }

  public createDetails() {
    this.dataService.addData(this.dataObj).subscribe((data) => {
      if (data) {
        this.dataService.getDataRecords();
      }
    }, err => {
      this.msgSvc.addErrorMsg(err);
    });
    this.isAddDetails = false;
  }

  public cancelEditDlg() {
    this.isViewDetails = false;
    this.isAddDetails = false;
    this.isEditDetails = false;
  }

  public updateCallType() {
    this.dataService.updateData(this.dataObj).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isEditDetails = false;
  }

  public deleteDetails() {
    this.dataService.deleteData(this.dataObj.custId).subscribe((data) => {
      this.dataService.getDataRecords();
    });
    this.isDeleteDetails = false;
  }

  public deleteData(obj: Customer) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public viewData(obj: Customer) {
    this.dataObj = obj;
    this.isViewDetails = true;
  }

  public updateStatus(obj: Customer) {
    this.dataService.updateStatus(obj);
  }
}
