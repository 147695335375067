export interface Producttype {
    s_no?: number;
    ProducttypeId: number;
    ProducttypeCode: string;
    ProducttypeDesc: string;
	state: boolean;
    status: boolean;
}

export const defaultProducttype = {
    ProducttypeId: 0,
    ProducttypeCode: '',
    ProducttypeDesc: '',
    status: false,
	state: false
};