export interface RefItemGrade {
	
	s_no?: number;
    RefItemGradeId: number;
	itemGradeId: number;
    itemGradeCode: string;
    itemGradeDesc: string;
    status: boolean;
	
}

export const defaultRefItemGrade = {
    RefItemGradeId: 0,
	itemGradeId: 0,
    itemGradeCode: '',
    itemGradeDesc: '',
    status: false
};
