export interface EquipmentLineClearanceTimings {
    s_no?: number;
    equipmentId: number;
    equipmentName?: string;
    batchChangeOver: number;
    productChangeOver: number;
    restart8hoursIdeal: number;
    startAfterMaintenance: number;
    status: number;
}

export const defaultEquipmentLineClearanceTimings: EquipmentLineClearanceTimings = {
    equipmentId: 0,
    equipmentName:'',
    batchChangeOver: 0,
    productChangeOver: 0,
    restart8hoursIdeal: 0,
    startAfterMaintenance: 0,
    status: 0
};

