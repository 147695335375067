export interface ProjectedOrders {
    s_no?: number;
    intordId: number;
	plan_id: number;
    intordCode: string;
    intordDate: Date;
    prodId: number;
    prodName?: string;
    prodCode?: string;
    uomId: number;
    uomDesc?: string;
    prodSpecs: string;
    projectedQty: number;
    priority: string;
}

export interface ConfirmOrder {
    s_no?: number;
    prodId: number;
	plan_id: number;
    prodCode: string;
    prodName: string;
    prodSpecs: string;
    customerOrderId: number;
    custId: number;
    custName: string;
    customerPurchaseOrder: string;
    customerPurchaseDate: Date;
    orderQty: number;
    dispatchedQty: number;
    balanceQty: number;
    priority: number;
}

// export const defaultProjectedOrders: ProjectedOrders = {
//     intordId: 0,
//     intordCode: '',
//     intordDate: undefined,
//     prodId: 0,
//     prodName: '',
//     prodSpecs: '',
//     projectedQty: 0,
//     uomId: 0,
//     uomDesc: '',
//     priority: 0
// };

export interface DataConfirmNProject {
    status: number;
    lstOfConfirmOrders: Array<ConfirmOrder>;
    lstOfProjectedOrders: Array<ProjectedOrders>;
}
