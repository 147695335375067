export interface DispatchQuantity {
    s_no?: number;
    customerOrderId: number;
    customerPurchaseOrderDate: string;
    custCode: string;
    prodCode: string;
    prodName: string;
    prodId: number;
    prodSpecs: string;
    orderQuantity: number;
    dispatchQuantity: number;
    custId: number;
    UOM: string;
    UOM_ID:number;
    custName?: string;
    customerPurchaseOrder: string;
    status: number;
    BatchNumber:string;
    FinishedQty: number;
}