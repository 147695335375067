export interface MasterFormula {
    s_no?: number;
    formulaId: number;
    prodId: number;
    isBatch: string;
    batchQty: number;
    uomId: number;
    UOM?: string;
    prodName?: string;
    output?: string;
    items: Array<FormulaItem>;
    status: number;
}

export interface FormulaItem {
    s_no?: number;
    itemId: number;
    quantity: number;
    uomId: number;
    itemCode?: string;
    itemDesc?: string;
    UOM?: string;
	is_intermediate:number;
}

export const defaultMasterFormula: MasterFormula = {
    formulaId: 0,
    prodId: 0,
    isBatch: '0',
    batchQty: 0,
    uomId: 0,
    items: [],
    status: 0
};

