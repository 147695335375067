import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UnitMaster
  , GridHeader
  , GridHeaderEx
  , defaultUnitMaster
  , UnitWiseShiftTiming
  , defaultUnitWiseShiftTiming
} from 'src/app/model';
import {
  UtilService
  , MsgService
  , AppConfigService
  , UserType
  , UnitWiseShiftTimingService, unitShifts
} from 'src/app/service';
import { MenuItem, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unit-wise-shift-timing',
  templateUrl: './unit-wise-shift-timing.component.html',
  styleUrls: ['./unit-wise-shift-timing.component.scss']
})
export class UnitWiseShiftTimingComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<UnitWiseShiftTiming>;
  public selectedRows: Array<any> = [];
  public selectedColumns: Array<GridHeader>;
  public dataObj: UnitWiseShiftTiming = defaultUnitWiseShiftTiming;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public unitMasterList: Array<SelectItem> = [];
  public selectedUnit: Array<UnitMaster>;

  public unitShifts: Array<MenuItem> = [...unitShifts];
  public selectedShiftList: Array<string> = [];

  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'unitName', header: 'Unit Name', isVisible: true, isDefault: true },
    { field: 'unitShifts', header: 'Unit Shifts', isVisible: true, isDefault: true },
    { field: 'breakhoursperDay', header: 'Break Hrs per Day', isVisible: true, isDefault: true },
    { field: 'weeklyOffshtml', header: 'Weekly Offs', isVisible: true, isDefault: true },
    { field: 'officialHolidayshtml', header: 'Official Holidays', isVisible: true, isDefault: true },
    { field: 'noworkingDayshtml', header: 'No Work Days', isVisible: true, isDefault: true },
    { field: 'status', header: 'Actions', isVisible: true, isDefault: true }
  ];

  public get pageName() {
    return this.dataService.pageName;
  }

  constructor(
    private msgSvc: MsgService,
    private dataService: UnitWiseShiftTimingService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultUnitWiseShiftTiming);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    this.selectedColumns = this.columnsConfig
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              const action = this.util.getRoleLevel(e.status);
              // tslint:disable-next-line: no-string-literal
              e['canDelete'] = action.canDelete;
              // tslint:disable-next-line: no-string-literal
              e['canEdit'] = action.canEdit;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {
    this.dataService.getUnitData().subscribe(data => {
      this.unitMasterList = data.map(ele => {
        return { label: ele.unitName, value: ele };
      });
    });
  }

  public getProdName(id: string) {
    const prod = this.unitMasterList.find(e => e.value.unitId + '' === id);
    return prod ? prod.value.unitName : '';
  }

  public onUnitShiftChange(event) {
    if (this.selectedShiftList) {
      // const ids = [];
      // this.selectedList.forEach(e => {
      //   ids.push((e as UnitMaster).unitId);
      // });
      // this.dataObj.unitShifts = ids.join(',');
    }
  }

  public onUnitChange(event) {
    // console.log(event);
    this.dataObj.unitId = event.value.unitId;
    this.dataObj.unitName = event.value.unitName;
  }

  public editData(event) {
    this.dataObj = Object.assign({}, event ? event : defaultUnitMaster);
    if (event == null) {
      this.isAddDetails = true;
    } else {
      this.isEditDetails = true;
      this.bindDropDownList();
    }
  }

  private bindDropDownList() {
    // tslint:disable-next-line: triple-equals
    const unit = this.unitMasterList.find(e => e.value.unitId == this.dataObj.unitId);
    this.selectedUnit = unit ? unit.value : unit;
    const idList = this.dataObj.unitShifts ? this.dataObj.unitShifts.split(',') : [];
    const items = [];
    for (const val of idList) {
      // tslint:disable-next-line: triple-equals disable-next-line: no-string-literal
      const item = this.unitShifts.find(e => e['value'] == val);
      // tslint:disable-next-line: no-string-literal
      if (item) { items.push(item['value']); }
    }
    this.selectedShiftList = items;
  }

  public updateData() {
    if (this.isValidateDates()) {
      this.dataService.updateData(this.dataObj).subscribe((data) => {
        this.resetFormData();
      });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please select all dates relates to same month & year.' });
    }
  }

  public viewData(obj: UnitWiseShiftTiming) {
    this.dataObj = obj;
    this.bindDropDownList();
    this.isViewDetails = true;
  }

  public deleteData(obj: UnitWiseShiftTiming) {
    this.dataObj = obj;
    this.isDeleteDetails = true;
  }

  public isFormValid() {
    // TODO: get all the list of prod join with comma.

    let result = false;
    if (
      this.dataObj.unitId === 0 ||
      this.dataObj.breakhoursperDay === 0 ||
      /* this.dataObj.noworkingDays && this.dataObj.noworkingDays.length === 0 ||
      this.dataObj.officialHolidays && this.dataObj.officialHolidays.length === 0 ||
      this.dataObj.weeklyOffs && this.dataObj.weeklyOffs.length === 0 || */
      this.dataObj.unitShifts && this.dataObj.unitShifts.trim() === '') {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  public cancelEditDlg() {
    try {
      this.resetFormData();
    } catch (ex) {

    }
  }

  public deleteDetails() {
    this.isDeleteDetails = false;
  }

  public saveAsDraft() {
    if (this.isValidateDates()) {

    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please select all dates relates to same month & year.' });
      return;
    }
    this.dataObj.unitShifts = this.selectedShiftList.join(',');

    if (!Array.isArray(this.dataObj.weeklyOffs)) {
      this.dataObj.weeklyOffs = this.util.toArrayOfDate(this.dataObj.weeklyOffs);
    }

    if (!Array.isArray(this.dataObj.noworkingDays)) {
      if (this.dataObj.noworkingDays != null || this.dataObj.noworkingDays != '') {
        this.dataObj.noworkingDays = this.util.toArrayOfDate(this.dataObj.noworkingDays);
      } else {
        this.dataObj.noworkingDays = null;
      }
    }

    if (!Array.isArray(this.dataObj.officialHolidays)) {
      if (this.dataObj.officialHolidays != null || this.dataObj.officialHolidays != '') {
        this.dataObj.officialHolidays = this.util.toArrayOfDate(this.dataObj.officialHolidays);
      } else {
        this.dataObj.officialHolidays = null;
      }
    }

    if (this.dataObj.unitName.trim().length !== 0) {
      this.dataService.saveDraft(this.dataObj).subscribe(val => { this.resetFormData(); });
    } else {
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Please provided Unit code' });
    }
  }

  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.dataService.getDataRecords();
    this.selectedShiftList = [];
    this.selectedUnit = undefined;
    this.dataObj = Object.assign({}, defaultUnitWiseShiftTiming);
  }

  private isValidateDates() {
    let monNYear = '';
    let result = true;

    monNYear = this.getMonthAndYear(new Date(this.dataObj.weeklyOffs[0]));
    if (this.dataObj.weeklyOffs && this.dataObj.weeklyOffs.length) {

      this.dataObj.weeklyOffs.forEach(e => {
        result = monNYear === this.getMonthAndYear(new Date(e));
        if (!result) { return; }
      });
      if (!result) { return false; }
    }

    if (this.dataObj.noworkingDays && this.dataObj.noworkingDays.length) {
      this.dataObj.noworkingDays.forEach(e => {
        result = monNYear === this.getMonthAndYear(new Date(e));
        if (!result) { return; }
      });
      if (!result) { return false; }
    }

    if (this.dataObj.officialHolidays && this.dataObj.officialHolidays.length) {
      this.dataObj.officialHolidays.forEach(e => {
        result = monNYear === this.getMonthAndYear(new Date(e));
        if (!result) { return; }
      });
      if (!result) { return false; }
    }

    if (result) { this.dataObj.yearMonth = monNYear; }

    return result;
  }

  private getMonthAndYear(d: Date) {
    let mm = d.getMonth() + 1 + '';
    mm = mm.length == 1 ? '0' + mm : mm;
    return d.getFullYear() + mm;
  }

  public dateSelect(event, property) {
    // this.dataObj[property] = event;
  }
}
