export interface Plancomparison {
    s_no?: number;
    plan_id: number;
    status: number;
    plan_remark: string;
	plan_name: string;
   
}

export const defaultPlancomparison = {
    plan_id: 0,
	plan_name: '',
    plan_remark: '',
	status:0
};

export interface Plancomparisondata {
    s_no?: number;
    cust_code: string;
  cust_po:string;
    lots_per_order:string;
    batches_per_lot:string;
    start_date:string;
    end_date:string;
    lot_qty:number;
    lot_value:number;
}