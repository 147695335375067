import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import {
  ProjectedOrders,
  ConfirmOrder,
  DataConfirmNProject
} from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SalesForecastService {

  public pageName = 'Order Prioritization';
  public dataList = new Subject<Array<any>>();
  public projectedDataList = new Subject<Array<any>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.salesForecastApi[apiOption];
  }

  public getProjectedRecords(fromDate: Date, toDate: Date,plan_id:number) {
    let resultList = new Array<ProjectedOrders>();
    const url = this.config.ipAddress + this.config.internalSalesOrderApi[ApiActions.Get];
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    fData.append('plan_id', plan_id+'');

    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.InternalSalesOrderList) {
          resultList = response.result.InternalSalesOrderList.map((e, i) => {
            e.s_no = i + 1;
            return e;
          });
          this.projectedDataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }

  public getConfirmedRecords(fromDate: Date, toDate: Date,plan_id:number) {
    let resultList = new Array<ConfirmOrder>();
    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(fromDate));
    fData.append('toDate', this.util.getDate(toDate));
    fData.append('plan_id', plan_id+'');
    this.http.post(this.getUrl(ApiActions.Get), fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.ConfirmedOrders) {
          resultList = response.result.ConfirmedOrders.map((e, i) => {
            e.customerPurchaseDate = new Date(e.customerPurchaseDate);
            e.deliveryDate = new Date(e.deliveryDate);
            e.s_no = i + 1;
            return e;
          });
          this.dataList.next(resultList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
  }
  public getGeneratenewplanId(fromDate: Date, toDate: Date) {
     return this.generatenewplanidsave(
      {
        confirmOrd: this.config.ipAddress + this.config.salesForecastApi[ApiActions.GetSingle]
      },fromDate,toDate);
  }
  public updateData(objData: DataConfirmNProject,fromDate: Date, toDate: Date) {
    objData.status = this.util.getSaveStatus();
    return this.Save(
      {
        confirmOrd: this.config.ipAddress + this.config.salesForecastApi[ApiActions.Update],
        projectedOrd: this.config.ipAddress + this.config.internalSalesOrderApi[ApiActions.Update]
      }
      , objData,fromDate,toDate);
  }

  public saveDraft(objData: DataConfirmNProject,fromDate: Date, toDate: Date) {
    objData.status = this.util.getDraftStatus();
    return this.Save(
      {
        confirmOrd: this.config.ipAddress + this.config.salesForecastApi[ApiActions.DraftSave],
        projectedOrd: this.config.ipAddress + this.config.internalSalesOrderApi[ApiActions.DraftSave]
      }
      , objData,fromDate,toDate);
  }

  private Save(api: { confirmOrd: string, projectedOrd: string }, objData: DataConfirmNProject,fromDate,toDate) {
    const responseMsg = new Subject<string>();
    // Confirm order data records ... save
    let fData = new FormData();
    const confirmData = objData.lstOfConfirmOrders.map(e => {
      return {
        customerOrderId: e.customerOrderId,
        prodId: e.prodId,
		plan_id: e.plan_id,
        prodSpecs: e.prodSpecs,
        quantity: e.orderQty,
        balanceQty: e.balanceQty,
        dispatchedQty: e.dispatchedQty,
        priority: e.priority,
        status: objData.status,
		fromDate:fromDate,
		toDate:toDate
      };
    });
	const projectedData = objData.lstOfProjectedOrders.map(e => {
      return {
        internalOrdId: e.intordId,
		prodId: e.prodId,
		plan_id: e.plan_id,
        prodSpecs: e.prodSpecs,
        quantity: e.projectedQty,
        priority: e.priority,
        status: objData.status,
		fromDate:fromDate,
		toDate:toDate
      };
    });
fData.append('fromDate', this.util.getDate(fromDate));
	fData.append('toDate', this.util.getDate(toDate));
    fData.append('data', JSON.stringify(confirmData));
	fData.append('data1', JSON.stringify(projectedData));
    this.http.post(api.confirmOrd, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    //  -------- Project order insert records...

    // fData = new FormData();
    // const projectedData = objData.lstOfProjectedOrders.map(e => {
      // return {
        // internalOrdId: e.intordId,
		// prodId: e.prodId,
		// plan_id: e.plan_id,
        // prodSpecs: e.prodSpecs,
        // quantity: e.projectedQty,
        // priority: e.priority,
        // status: objData.status,
		// fromDate:fromDate,
		// toDate:toDate
      // };
    // });
    // fData.append('data', JSON.stringify(projectedData));
	// fData.append('fromDate', this.util.getDate(fromDate));
	// fData.append('toDate', this.util.getDate(toDate));

    // this.http.post(api.projectedOrd, fData, { headers: undefined }).subscribe((response: any) => {
      // if (response) {
        // this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        // responseMsg.next('');
      // }
    // },
      // err => {
        // responseMsg.next(err);
      // });

    return responseMsg;
  }
  
  
   private generatenewplanidsave(api: { confirmOrd: string},fromDate,toDate) {
   
	const responseMsg = new Subject<string>();
    let fData = new FormData();   
	fData.append('fromDate', this.util.getDate(fromDate));
	fData.append('toDate', this.util.getDate(toDate));
    this.http.post(api.confirmOrd, fData, { headers: undefined }).subscribe((response: any) => {
		if (response) {
			this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
			responseMsg.next('');
		}
    },
    err => {
		responseMsg.next(err);
    });
    return responseMsg;
  }
}
