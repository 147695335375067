export interface Customer {
    s_no?: number;
    custId: number;
    custCode: string;
    custName: string;
    custCountry: number;
    country_name:string;
    custAddress: string;
    custtypeId: number;
    custtypeCode?: string;
    status: number;
}

export const defaultCustomer: Customer = {
    custId: 0,
    custCode: '',
    custName: '',
    custCountry: 0,
    custAddress: '',
    country_name:'',
    custtypeId: 0,
    custtypeCode: '',
    status: 0
};
