import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductMaster, UnitLineMaster } from 'src/app/model';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { ApiActions, UtilService } from '../common/util.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProcessSchedulerService {

  public pageName = 'Process Scheduler';
  public dataList = new Subject<Array<any>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  public getUnitWise() {
    const resultList = new Subject<Array<UnitLineMaster>>();
    const url = this.config.ipAddress + this.config.unitLineMasterApi[ApiActions.Get];
    const dataArray: Array<UnitLineMaster> = [];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.UnitlinemasterList) {
          resultList.next(response.result.UnitlinemasterList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });

    return resultList;
  }

  public getProductWiseList() {
    const resultList = new Subject<Array<ProductMaster>>();
    const url = this.config.ipAddress + this.config.productMasterApi[ApiActions.Get];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.productMasterList) {
          resultList.next(response.result.productMasterList);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public getSchPreviewData(filters: {
    fromDate: Date,
    toDate: Date,
    unitId: number,
    lineId: number,
    equipmentId: number,
    prodId: number,
	plan_id: number
  }) {
    const resultList = new Subject<Array<any>>();

    const fData = new FormData();
    fData.append('fromDate', this.util.getDate(filters.fromDate));
    fData.append('toDate', this.util.getDate(filters.toDate));
    fData.append('iUnit', filters.unitId + '');
    fData.append('lineNo', filters.lineId + '');
    fData.append('eqpCode', filters.equipmentId + '');
    fData.append('prodId', filters.prodId + '');
	fData.append('plan_id', filters.plan_id + '');

    const url = this.config.ipAddress + this.config.processSchApi[ApiActions.Get];
    this.http.post(url, fData, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          const list = response.result.SequenceschedulerDetails;
          resultList.next(list);
        }
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return resultList;
  }

  public generateScheduler(data: any) {

  }
}
