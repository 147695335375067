export interface BatchIdentity {
	s_no?: number;
	batch_field_id: number;
	no_fields: number;
	field_symbal: String;
	field1_name: String;
	field2_name: String;
	field3_name: String;
	field4_name: String;
	field5_name: String;
    status: number;

}
export const defaultBatchIdentity = {
    s_no: 0,
	batch_field_id: 0,
	no_fields: 0,
	field_symbal: '',
	field1_name: '',
	field2_name: '',
	field3_name: '',
	field4_name: '',
	field5_name: '',
    status: 0

};