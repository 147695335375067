import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MsgService } from './msg.service';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  constructor(
    private http: HttpClient,
    private msgSvc: MsgService
  ) { }

  public executeAction(formData: FormData, actionUrl: string, pageName: string) {
    const responseMsg = new Subject<string>();
    this.http.post(actionUrl, formData, { headers: undefined }).subscribe((response: any) => {
      if (response.status !== 'error') {
        this.msgSvc.addSuccessMsg({ summary: pageName, detail: response.status });
      } else {
        this.msgSvc.addErrorMsg({ summary: pageName, detail: response.Error });
      }
      responseMsg.next(response.status);
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }
}
