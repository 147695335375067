import { Injectable } from '@angular/core';
import { AppConfigService } from '../common/app-config.service';
import { HttpClient } from '@angular/common/http';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { MsgService } from '../common/msg.service';
import { User } from 'src/app/model';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public pageName = 'User Management';
  public userList = new Subject<Array<User>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    public util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.userApi[apiOption];
  }

  getDataRecord(id: number) {
    throw new Error('Method not implemented.');
  }

  public getDataRecords() {
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined }).subscribe((response: any) => {
      if (response && response.result) {
        const UserList: Array<User> = [];
        response.result.userslist.forEach(element => {
          // element = element as User;
          element.userStatus = element.userStatus ? true : false;
          UserList.push(element);
        });
        this.userList.next(UserList);
      }
    },
      err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

  public rolesPermissions(adminPermissionOj) {
    const responseMsg = new Subject<string>();
    // tslint:disable-next-line:max-line-length
    this.http.post(this.getUrl(ApiActions.Permissions), JSON.stringify(adminPermissionOj), { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.status.statusMessage });
        responseMsg.next(response.status.statusMessage);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }

  public addData(user: User) {
    return this.Save(this.getUrl(ApiActions.Create), user);
  }

  private Save(action: string, objData: User) {
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('userId', objData.userId + '');
    fData.append('userFName', objData.userFName + '');
    fData.append('userLName', objData.userLName);
    fData.append('userLogin', objData.userLogin);
   //  fData.append('userRoleId', objData.userRoleId + '');
  //  fData.append('userRoleId', '2');
    // fData.append('userManagerId', objData.userManagerId);
    fData.append('userManagerId', '1');
    fData.append('status', objData.status  ?  '1' : '0');
    this.http.post(action, fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }


  public updateData(user: User) {
    return this.Save(this.getUrl(ApiActions.Update), user);
  }

  public updateStatus(user: User) {
    const responseMsg = new Subject<string>();
    const userst = (user.status) ? 1 : 0;
    const fData = new FormData();
    fData.append('userId', user.userId + '');
    fData.append('userStatus', userst + '');
    this.http.post(this.getUrl(ApiActions.UpdateStatus), fData, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.status.statusMessage });
        responseMsg.next(response.status.statusMessage);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }


  public changeUserRole(dataObj) {
    const responseMsg = new Subject<string>();
    this.http.post(this.getUrl(ApiActions.ChangeRole),dataObj, { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next(response.result.status);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }
  
  public userPasswordReset(dataObj) {
    const responseMsg = new Subject<string>();
    this.http.post(this.getUrl(ApiActions.ChangePassword),JSON.stringify(dataObj), { headers: undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next(response.result.status);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }
  
  public deleteData(dataObj) {
    const responseMsg = new Subject<string>();
    this.http.post(this.getUrl(ApiActions.Delete), dataObj, { headers: undefined }).subscribe((response: any) => {
      if (response.status.statusMessage !== 'validation error') {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.status.statusMessage });
        responseMsg.next(response.status.statusMessage);
      } else {
        this.msgService.addErrorMsg({ summary: this.pageName, detail: response.Error });
      }
    },
      err => {
        responseMsg.next(err);
      });
    return responseMsg;
  }

}
