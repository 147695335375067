import {Component,OnInit,OnDestroy,ViewChild,ElementRef} from '@angular/core';
import {BatchIdentity,defaultBatchIdentity,GridHeaderEx,GridHeader} from 'src/app/model/';
import {MsgService,BatchIdentityService,UtilService,UserType,AppConfigService} from 'src/app/service';
import {Subscription} from 'rxjs';
import {
    SelectItem
} from 'primeng/api';

@Component({
    selector: 'app-batch-identity',
    templateUrl: './batch-identity.component.html',
    styleUrls: ['./batch-identity.component.scss']
})
export class BatchIdentityComponent implements OnInit, OnDestroy {

    public options = [{
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        },
        {
            label: '4',
            value: 4
        },
        {
            label: '5',
            value: 5
        },
    ];
    private subscription = new Subscription();
    public cols: Array < GridHeader > = [];
    public dataArray: Array < BatchIdentity > ;
    public selectedColumns: Array < GridHeader > ;
    public dataObj: BatchIdentity = undefined;
    public isDeleteDetails = false;
    public isAddDetails = false;
    public isEditDetails = false;
    public isViewDetails = false;
    public categoryList: Array < SelectItem > = [];
    public masterfeildList: Array < SelectItem > = [];
	public selectedOption: number;
	public selectedCategory: SelectItem;
	public selectedField1: SelectItem;
	public selectedField2: SelectItem;
	public selectedField3: SelectItem;
	public selectedField4: SelectItem;
	public selectedField5: SelectItem;

    public set isEditShow(value: boolean) {
        this.isEditDetails = value;
        this.isAddDetails = value;
    }

    public get isEditShow() {
        return this.isEditDetails || this.isAddDetails;
    }

    public get isCreator() {
        return this.util.getUserRole === UserType.Creator;
    }

    public get dlgHeader() {
        return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
    }

    /** grid columns and data fields */
    private columnsConfig: Array < GridHeaderEx > = [{
            field: 's_no',
            header: 'S No',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'no_fields',
            header: 'No of Fields',
            isVisible: true,
            isDefault: true
        },
		{
            field: 'field1_name',
            header: 'FIELD 1',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field_symbal',
            header: 'FS',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field2_name',
            header: 'FIELD 2',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field_symbal',
            header: 'FS',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field3_name',
            header: 'FIELD 3',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field_symbal',
            header: 'FS',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'field4_name',
            header: 'FIELD 4',
            isVisible: true,
            isDefault: true
        }, 
		{
            field: 'field_symbal',
            header: 'FS',
            isVisible: true,
            isDefault: true
        },
		{
            field: 'field5_name',
            header: 'FIELD 5',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'status',
            header: 'Actions',
            isVisible: true,
            isDefault: true
        }
    ];


    public get pageName() {
        return this.dataService.pageName;
    }

    constructor(
        private msgSvc: MsgService,
        private dataService: BatchIdentityService,
        public util: UtilService,
        private config: AppConfigService
    ) {}
    ngOnInit(): void {

        this.dataObj = Object.assign({}, defaultBatchIdentity);

        this.cols = this.columnsConfig.filter(i => i.isVisible)
            .map((e: GridHeaderEx) => {
                return {
                    field: e.field,
                    header: e.header
                };
            });

        this.selectedColumns = this.columnsConfig.map((e: GridHeaderEx) => {
            return {
                field: e.field,
                header: e.header
            };
        });
        this.dataService.getDataRecords();
        this.subscription.add(
            this.dataService.dataList.subscribe((data) => {
                this.dataArray = [];
                if (data.length) {
                    this.dataArray = data
                        .map((e, indx) => {
                            e.s_no = indx + 1;
                            //  const action = this.util.getRoleLevel(e.status);
                            // tslint:disable-next-line: no-string-literal
                            e['canDelete'] = false;
                            // tslint:disable-next-line: no-string-literal
                            e['canEdit'] = true;
                            return e;
                        });
                } else {
                    this.msgSvc.addInfoMsg({
                        summary: this.pageName,
                        detail: 'No data exit'
                    });
                }
            }));
        this.loadDropDownLists();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private loadDropDownLists() {
        this.dataService.getCategories().subscribe(data => {
            this.categoryList = data.map(ele => {
                return {
                    label: ele.fieldName,
                    value: ele.fieldSymbal
                };
            });
        });
        this.dataService.getbatchFeilds().subscribe(data => {
            this.masterfeildList = data.map(ele => {
                return {
                    label: ele.fieldName,
                    value: ele.fieldName
                };
            });
        });
    }
    public onOptionsSelected(event) {
		console.log(event);
        this.dataObj.no_fields = event.value;
    }
    public onselectedCategory(event) {
        this.dataObj.field_symbal = event.value;
    }
    public onfield1Change(event) {
        this.dataObj.field1_name = event.value;
    }
    public onfield2Change(event) {
        this.dataObj.field2_name = event.value;
    }
    public onfield3Change(event) {
        this.dataObj.field3_name = event.value;
    }
    public onfield4Change(event) {
        this.dataObj.field4_name = event.value;
    }
    public onfield5Change(event) {
        this.dataObj.field5_name = event.value;
    }

    public deleteDetails() {
        this.dataService.deleteData(this.dataObj.batch_field_id).subscribe((data) => {
            this.dataService.getDataRecords();
        });
        this.isDeleteDetails = false;
    }

    public deleteData(obj: BatchIdentity) {
        this.dataObj = obj;
        this.isDeleteDetails = false;
    }
    public editData(event) {
        this.dataObj = Object.assign({}, event ? event : defaultBatchIdentity);
        if (event == null) {
            this.isAddDetails = true;
        } else {
            this.isEditDetails = true;
			this.bindDropDownList();
        }
    }
	
	private bindDropDownList() {

		if (this.dataObj.no_fields) {
			const item = this.options.find(e => e.value == this.dataObj.no_fields);
			this.selectedOption = item ? item.value : 0;
		}
		if (this.dataObj.field_symbal) {
			const item = this.categoryList.find(e => e.value == this.dataObj.field_symbal);
			this.selectedCategory = item ? item.value : undefined;
		}		
		
		if (this.dataObj.field1_name) {
			const item = this.masterfeildList.find(e => e.value == this.dataObj.field1_name);
			this.selectedField1 = item ? item.value : undefined;
		}
		
		if (this.dataObj.field2_name) {
			const item = this.masterfeildList.find(e => e.value == this.dataObj.field2_name);
			this.selectedField2 = item ? item.value : undefined;
		}
		
		if (this.dataObj.field3_name) {
			const item = this.masterfeildList.find(e => e.value == this.dataObj.field3_name);
			this.selectedField3 = item ? item.value : undefined;
		}
		
		if (this.dataObj.field4_name) {
			const item = this.masterfeildList.find(e => e.value == this.dataObj.field4_name);
			this.selectedField4 = item ? item.value : undefined;
		}
		
		if (this.dataObj.field5_name) {
			const item = this.masterfeildList.find(e => e.value == this.dataObj.field5_name);
			this.selectedField5 = item ? item.value : undefined;
		}
	}
	
    public updateData() {
        if (this.isAddDetails) {
            this.createDetails();
        } else {
            this.updateCallType();
        }
    }

    public createDetails() {
		console.log(this.dataObj);
        this.dataService.Save(this.dataObj).subscribe((data) => {
            if (data) {
                this.dataService.getDataRecords();
            }
        }, err => {
            this.msgSvc.addErrorMsg(err);
        });
        this.isAddDetails = false;
		this.resetFormData();
    }

    public updateCallType() {
        this.dataService.updateData(this.dataObj).subscribe((data) => {
            this.resetFormData();
			
        });
    }

    public viewData(obj: BatchIdentity) {
        this.dataObj = obj;
        this.isViewDetails = true;
    }



    public isFormValid() {
        let result = false;
        return result;
    }

    public cancelEditDlg() {
        this.resetFormData();
    }

    private resetFormData() {
        this.isViewDetails = false;
        this.isEditShow = false;
        this.dataService.getDataRecords();
		this.loadDropDownLists();
		this.selectedOption= 0;
		this.selectedCategory= undefined;
		this.selectedField1= undefined;
		this.selectedField2= undefined;
		this.selectedField3= undefined;
		this.selectedField4= undefined;
		this.selectedField5= undefined;
        this.dataObj = Object.assign({}, defaultBatchIdentity);
		
    }

}