export interface ProcessConfig {
    s_no?: number;
    pconfigId: number;
    prodId: number;
    prodCode?: string;
    prodName?: string;
    pharmacopiDesc?: string;
    prodSpecsDoc?: string;
    prodUom: number;
    batchSize: number;
    uomDesc?: string;
    stdBatchSize: number;
    stdBatchUom: number;
    stdBatchUomDesc?: string;
    items: Array<PCItem>;
    status: number;
}

export interface PCItem {
    s_no?: number;
    pcstepId: number;
    pcstepName?: string;
    pcparamId: number;
    pcparamName?: string;
    pcparamMin: number;
    pcparamMax: number;
    pcparamUom: number;
    equipmentIds: string;
    pcparamUomDesc?: string;
    cycleTime: number;
    noOfPeople: number;
    stepCriticality_row?:string;
    stepCriticality: number;
}

export const defaultPCItem: PCItem = {
    pcstepId: 0,
    pcparamId: 0,
    pcparamMin: 0,
    pcparamMax: 0,
    pcparamUom: 0,
    equipmentIds: '',
    cycleTime: 0,
    noOfPeople: 0,
    stepCriticality: 0,
    stepCriticality_row: ''
};

export const defaultProcessConfig: ProcessConfig = {
    pconfigId: 0,
    prodId: 0,
    prodUom: 0,
    batchSize: 0,
    pharmacopiDesc: '',
    prodCode: '',
    uomDesc: '',
    stdBatchUom: 0,
    stdBatchUomDesc: '',
    stdBatchSize: 0,
    items: [],
    status: 0
};
