import { GridHeader } from './grid-header';
import { Subscription } from 'rxjs';

/**
 * Default actions for the dependent-master Forms.
 */
export interface MasterActions {
    /** The Dialog header to show on the UI */
    dlgHeader: string;
    /** Holds the flag weather the form is in Edit/Add mode. */
    isEditShow: boolean;

    cols: Array<GridHeader>;
    selectedRows: Array<{}>;
    selectedColumns: Array<GridHeader>;

    isDeleteDetails: boolean;
    isAddDetails: boolean;
    isEditDetails: boolean;
    isViewDetails: boolean;

    /**
     * Edit the data from grid.
     * @param obj Object value as reference.
     */
    editData(obj: {}): void;
    /**
     * Update the data.
     */
    updateData(): void;
    /**
     * Delete the data object from grid.
     * @param obj Object value as reference.
     */
    deleteData(obj: {}): void;
    /**
     * Cancel the Edit Dialog.
     */
    cancelEditDlg(): void;
    // /**
    //  * Event handler for Tenant drop-down on-change.
    //  * @param event Event arg as Tenant model
    //  */
    // onTenantChange(event: {});
}
