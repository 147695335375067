import {
    Component
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatTableModule  } from '@angular/material/table';
import { TagModule } from 'primeng/tag';
import {
    FormsModule
} from '@angular/forms';
import {
    Subscription
} from 'rxjs';
import {
    Dailyproductionlog,
    GridHeader,
    Productstepslog,
    defaultDailyproductionlog,
	defaultProductstepslog
} from 'src/app/model';
import {
    UserType,
    MsgService,
    UtilService,
    AppConfigService,
    DailyproductionlogService
} from 'src/app/service';
import {
    SelectItem
} from 'primeng/api';
	const USER_SCHEMA = {
		"obs_date": "date",
		"obsparam_val": "number",
		"isEdit": "isEdit"
	}
@Component({
    selector: 'dailyproductionlog',
    templateUrl: './dailyproductionlog.component.html',
    styleUrls: ['./dailyproductionlog.component.scss']
})
export class DailyproductionlogComponent {


    private subscription = new Subscription();
    public cols: Array < GridHeader > = [];
    public dataArray: Array < Dailyproductionlog > ;
    public itemDataArray: Array < Productstepslog > ; 
	//public dynamicArray: Array < ObserveProductSteps > ;
    public selectedRows: Array < any > = [];
    public selectedplan_id = 0;
    public selectedplanid = 0;
    public selectedbatch_id = 0;
	public selectedprod_id = 0;
    public selectedbatch_comment = '';
    public selectedColumns: Array < GridHeader > ;
    public dataObj: Dailyproductionlog = undefined;
	public dataObjOne: Productstepslog = undefined;
    public orderFromDate: Date = new Date();
    public orderToDate: Date = new Date();
	public defaultDate = new Date();
	public eqpdefaultDate = new Date();
	 public categoryList: Array<any> = [];
     public isViewDetails = false;
    public DailyproductionlogList: Array < SelectItem > = [];
    public selectedDailyproductionlog: Dailyproductionlog;
    private editedItemsList: Array < number > = [];
    public isEditDetails = false;
    public isEditStepDetails = false;
    public isBatchStatusDetails = false;
    public get isCreator() {
        return this.util.getUserRole === UserType.Creator;
    }
    public criticalityLevels: Array < SelectItem > = [{
        label: 'Running',
        value: 0
    }, {
        label: 'Hold',
        value: 1
    }, {
        label: 'Completed',
        value: 2
    }, {
        label: 'Cancled',
        value: 3
    }];
	
	//colors: SelectItem[];

	colors = [{"value":"1","name":'Orange'},{"value":"2","name":'Black'},{"value":"3","name": 'Gray'},{"value":"4","name": 'Blue'}];
	// colors = this.colorNames.map((value,name) => {
		// return { label: name, value: value }
	// });   
  
  
    public get pageName() {
        return this.dataService.pageName;
    }
    private clonedRecord: {
        [s: string]: Dailyproductionlog;
    } = {}; 
	private clonedRecords: {
        [s: string]: Productstepslog;
    } = {};
    public get dlgHeader() {
        return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
      }
    /** grid columns and data fields */
    public confirmCols: Array < GridHeader > = [{
            field: 's_no',
            header: 'S No'
        },
        {
            field: 'run_num',
            header: 'Run Number'
        },
		{
            field: 'batch_str_date',
            header: 'Batch Start Date'
        },
		{
            field: 'prod_code',
            header: 'Product Code'
        },
        {
            field: 'batch_num',
            header: 'Batch Number'
        },
		{
            field: 'batch_end_date',
            header: 'Batch End Date'
        },  
        
        {
            field: 'finished_qty',
            header: 'Finished QTY'
        },
        {
            field: 'batch_hold',
            header: 'Actions',
            tooltip: 'batch_comment'
        }
    ];
    public itemCols = [{
            field: 'step_id',
            header: 'Step Number',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'rstr_date',
            header: 'Start Date',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'pcstep_name',
            header: 'Step Name',
            isVisible: true,
            isDefault: true
        },
        // {
            // field: 'pcparam_name',
            // header: 'Parameter',
            // isVisible: true,
            // isDefault: true
        // },
        
        {
            field: 'eqp_code',
            header: 'Equipment Code',
            isVisible: true,
            isDefault: true
        },
        {
            field: 'rend_date',
            header: 'Estimated End Date',
            isVisible: true,
            isDefault: true
        },
		{
            field: 'actual_end_date',
            header: 'Actual End Date',
            isVisible: true,
            isDefault: true
        },		
        {
            field: 'reason_for_delay',
            header: 'Reason for delay',
            isVisible: true,
            isDefault: true
        },
		
        {
            field: 'step_qty',
            header: 'Finished QTY',
            isVisible: true,
            isDefault: true
        },
		{
            field: 'step_running_status',
            header: 'Step Running Status',
            isVisible: true,
            isDefault: true
        }
        
    ];
	
	
	//public dynamicArray: Array<ObserveProductSteps> = [];
	newDynamic: any = {};
	 dataRow: any = {};
	displayedColumns: string[] = ['obs_date', 'obsparam_val', 'isEdit'];
	//dataSource = this.dynamicArray;
	dataSchema = USER_SCHEMA;
    constructor(
        private msgSvc: MsgService,
        private dataService: DailyproductionlogService,
        public util: UtilService,
        private config: AppConfigService
    ) {

        this.dataObj = Object.assign({}, defaultDailyproductionlog);
		// this.dataRow = Object.assign({});
		
		this.dataObjOne = Object.assign({}, defaultProductstepslog);
		this.eqpdefaultDate.setHours(10);
		this.eqpdefaultDate.setMinutes(15);
        this.subscription.add(
            this.dataService.dataList.subscribe((data) => {
                this.dataArray = [];
                if (data.length) {
                    this.dataArray = data
                        .map((e, indx) => {
                            e.s_no = indx + 1;
                            const action = this.util.getBatchstatuslevel(e.batch_hold);
                            e['canEdit'] = action.canEdit;
                            return e;
                        });
                } else {
                    this.msgSvc.addInfoMsg({
                        summary: this.pageName,
                        detail: 'No data exit'
                    });
                }
            }));
    }

    private resetFormData() {
        this.editedItemsList = [];
        this.getTableData();
    }

    public getTableData() {
        if (this.orderFromDate && this.orderToDate) {
            this.dataService.getDataRecords(this.orderFromDate, this.orderToDate);
        }
    }

    public onRowStepEditInit(row: Dailyproductionlog) {
		this.dataObj = row;
console.log(this.dataObj);
        this.isBatchStatusDetails = true;
    }
    public onRowEditInit(row: Dailyproductionlog) {
        this.isEditDetails = true;
        // this.clonedRecord[row.s_no] = { ...row };
        this.selectedbatch_id = row.batch_id;
        this.selectedplan_id = row.s_id;
		this.selectedprod_id = row.prod_id;
        this.dataService.getStepsRecords(row.prod_id, row.batch_id,row.plan_id).subscribe((data) => {
            this.itemDataArray = [];
//this.dynamicArray = [];
            if (data.length) {
                this.itemDataArray = data
                    .map((e, indx) => {
                        e.s_no = indx + 1;
                        e['status'] = e.s_no;
                        e['canDelete'] = false;
                        e['canEdit'] = false;
						// e['orders']  = e.orders;
                        return e;
                    });
            } else {
                //this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
            }
        });
		this.dataService.getReasionsRecords()
      .subscribe(data => {
        this.categoryList = data.map(ele => {
          return { label:ele.delay_desc, value: ele.delay_id };
        });
      });

    }
    
public batch_status(row: Dailyproductionlog){
	this.isBatchStatusDetails = true;
	        this.selectedbatch_id = row.batch_id;
        this.selectedplan_id = row.s_id;
        this.selectedplanid = row.plan_id;
		this.selectedprod_id = row.prod_id;
}
    public onRowEditSave(row: Productstepslog) {
        this.dataRow = row;
		console.log(row);
		if(this.dataRow.reason_for_delay == ''){
			alert('Plaes Select Reason for Delay');
			this.onRowEditCancel(row);
			
		}else{
        //this.isBatchStatusDetails = true;
		this.dataService.updateData(this.selectedplan_id, this.selectedbatch_id, this.dataRow).subscribe((data) => {
            this.dataService.getStepsRecords(this.selectedprod_id, this.selectedbatch_id,row.plan_id).subscribe((data) => {
				this.itemDataArray = [];
				//this.dynamicArray = [];
				if (data.length) {
					this.itemDataArray = data
						.map((e, indx) => {
							e.s_no = indx + 1;
							e['status'] = e.s_no;
							e['canDelete'] = false;
							e['canEdit'] = true;
							// e['orders']  = e.orders;
							return e;
						});
				} else {
					//this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
				}
			});
        });
		}
        //  this.editedItemsList.push(row.s_no);

    }
	

    public onRowEditCancel(row: Productstepslog) {
       // this.dataArray[index] = this.clonedRecord[row.s_no];
      //  delete this.clonedRecord[row.s_no];
	  row['candropEdit'] = true;
	  row['canEdit'] = true;
	  row.step_running_status = 0;
    }
	public onRowEdit(row: Productstepslog) {
		row['canEdit'] = true;
		console.log(row);
		let dateToBeCheckOut = new Date(row.rend_date);
        let today = new Date();
		console.log(dateToBeCheckOut);console.log(today);
		row.actual_end_date = today;
		row.step_running_status = 1;
		var diff = Math.abs(today.getTime() - new Date(row.rend_date).getTime());
		var minutes = Math.floor((diff/1000)/60);
		if(minutes > 180){
			row['candropEdit'] = true;
		}else{			
			row['candropEdit'] = false;
		}
		this.clonedRecords[row.s_no] = { ...row };
	}
    public updateData(row: Dailyproductionlog) {

        this.dataService.updateData(this.selectedplan_id, this.selectedbatch_id, this.itemDataArray).subscribe((data) => {
            this.resetFormData();
        });

    }

    public onCategoryChange(event) {
        this.dataObj.batch_hold = event.value;
        console.log(this.dataObj);
    } 
	
	public onDelayChange(event,row: Productstepslog) {
		console.log(event);
        row.reason_for_delay = event.label;
		row.delay_id = event.value;
        console.log(this.itemDataArray);
    }
    public updateBatchData(row: Dailyproductionlog) {
        console.log(this.dataObj);
        this.isBatchStatusDetails = false;
        this.dataService.updateStatus(this.dataObj.s_id, this.dataObj.batch_hold, this.dataObj.batch_comment).subscribe((data) => {
            this.dataService.getDataRecords(this.orderFromDate, this.orderToDate);
        });
        //  this.editedItemsList.push(row.s_no);

    }
    // public saveAsDraft() {
    // const editedList = [];
    // this.dataArray.forEach(row => {
    // editedList.push(Object.assign({}, row));
    // });
    // if (editedList.length) {
    // this.dataService.saveDraft(this.orderFromDate, this.orderToDate, editedList).subscribe((data) => {
    // this.resetFormData();
    // });
    // }
    // }

    private updateEditedList() {
        const editedList = [];
        if (this.editedItemsList.length) {
            this.dataArray.forEach(row => {
                if (this.editedItemsList.some(e => e === row.s_no)) {
                    editedList.push(Object.assign({}, row));
                }
            });
        }
        return editedList;
    }

    public roleActionType() {
        return this.util.roleActionType();
    }
    public cancelEditDlg() {
        this.resetFormData();
        this.isBatchStatusDetails = false;
    }
    public cancelDlg() {
        this.resetFormData();
        this.isEditDetails = false;
    }
    public cancelStepdetailsDlg() {
        this.resetFormData();
        this.isEditStepDetails = false;
    }

	public addRow(event) {
		const order = {obs_id:Math.floor(Math.random() * 1000) ,"obs_date": "", "obsparam_val": 0, isEdit: true};
		event.orders = [order, ...event.orders];
		//console.log(this.dynamicArray);
		console.log(event);
	}

	public removeRow(event,obs_id) {
		event.orders = event.orders.filter(u => u.obs_id !== obs_id);
	}
	public saveSelectedRows(event) {
		//this.dynamicArray = this.dynamicArray.filter(u => u.obs_id !== obs_id);
		console.log(event);
		this.dataService.updateobserveationsData(event).subscribe((data) => {
            this.dataService.getStepsRecords(this.selectedprod_id, this.selectedbatch_id,this.selectedplanid).subscribe((data) => {
				this.itemDataArray = [];
				//this.dynamicArray = [];
				if (data.length) {
					this.itemDataArray = data
						.map((e, indx) => {
							e.s_no = indx + 1;
							e['status'] = e.s_no;
							e['canDelete'] = false;
							e['canEdit'] = true;
							// e['orders']  = e.orders;
							return e;
						});
				} else {
					//this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
				}
			});
        });
		
	}

}
