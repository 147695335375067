import { Injectable } from '@angular/core';
import { ApiActions, UtilService } from '../common/util.service';
import { Subject } from 'rxjs';
import { UnitMaster, UnitWiseShiftTiming } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { MsgService } from '../common/msg.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UnitWiseShiftTimingService {

  public pageName = 'Unit Wise Shift Timing';
  public dataList = new Subject<Array<UnitWiseShiftTiming>>();

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private msgService: MsgService,
    private util: UtilService,
    private router: Router
  ) { }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.unitShiftTimingApi[apiOption];
  }

  public getUnitData() {
    const result = new Subject<Array<UnitMaster>>();
    const url = this.config.ipAddress + this.config.unitMasterApi[ApiActions.Get];
    const dataArray: Array<UnitMaster> = [];
    this.http.post(url, null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result) {
          response.result.unitMasterList.map(e => {
            dataArray.push(e);
          });
        }
        result.next(dataArray);
      },
        err => {
            this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
        });
    return result;
  }


  public getDataRecords() {
    const dataArray: Array<UnitWiseShiftTiming> = [];
    this.http.post(this.getUrl(ApiActions.Get), null, { headers: undefined })
      .subscribe((response: any) => {
        if (response && response.result && response.result.unitshifttimingmasterList) {
          response.result.unitshifttimingmasterList.map(e => {
			  e.weeklyOffshtml = e.weeklyOffs;
            e.officialHolidayshtml = e.officialHolidays;
            e.noworkingDayshtml = e.noworkingDays;
            e.weeklyOffs = this.util.convert2DDMMYYYY(e.weeklyOffs);
            e.officialHolidays = this.util.convert2DDMMYYYY(e.officialHolidays);
            e.noworkingDays = this.util.convert2DDMMYYYY(e.noworkingDays);
			
            dataArray.push(e);
          });
        }
        this.dataList.next(dataArray);
      },
      err => {
          this.msgService.addErrorMsg({ summary: 'Login', detail: 'Invalid login.' });  setTimeout(() => { localStorage.clear();  this.router.navigate(['/account/login']); }, 1000);
      });
  }

  public updateData(objData: UnitWiseShiftTiming){
    return this.Save(this.getUrl(ApiActions.Update), objData, this.util.getSaveStatus());
  }

  public saveDraft(objData: UnitWiseShiftTiming){
    return this.Save(this.getUrl(ApiActions.DraftSave), objData, this.util.getDraftStatus());
  }

  private dateListToString(dates: Array<any>){
    const result = [];
    dates.forEach(d => {
	  	result.push(this.util.getDate(d));		
    });
    return result.join(',');
  }

  private Save(action: string, objData: UnitWiseShiftTiming, status) {
    
    const responseMsg = new Subject<string>();
    const fData = new FormData();
    fData.append('status', status);
    fData.append('unitId', objData.unitId + '');
    fData.append('unitShifts', objData.unitShifts + '');
    fData.append('breakhoursperDay', objData.breakhoursperDay + '');
    fData.append('weeklyOffs', this.dateListToString(objData.weeklyOffs));
    
    if(objData.officialHolidays != null){
      fData.append('officialHolidays', this.dateListToString(objData.officialHolidays));
    }else{
      fData.append('officialHolidays', '');
    }
	
    if(objData.noworkingDays != null){
      fData.append('noworkingDays', this.dateListToString(objData.noworkingDays));
    }else{
      fData.append('noworkingDays', '');
    }
    fData.append('yearMonth', objData.yearMonth);

    this.http.post(action, fData, { headers:undefined }).subscribe((response: any) => {
      if (response) {
        this.msgService.addSuccessMsg({ summary: this.pageName, detail: response.result.status });
        responseMsg.next('');
      }
    },
      err => {
        responseMsg.next(err);
      });

    return responseMsg;
  }

}
