import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  ItemMasterInventory
  , GridHeaderEx
  , defaultItemMasterInventory
  , GridHeader
  , ProductUOM
} from 'src/app/model';
import {
  MsgService
  , ItemMasterInventoryService
  , UtilService
  , UserType
  , AppConfigService
} from 'src/app/service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
//myApp.filter('unsafe', function($sce) { return $sce.trustAsHtml; });
@Component({
  selector: 'app-raw-materials-inventory',
  templateUrl: './raw-materials-inventory.component.html',
  styleUrls: ['./raw-materials-inventory.component.scss']
})
export class RawmaterialsinventoryComponent implements OnInit, OnDestroy {

  @ViewChild('specLink', { static: true }) specLink: ElementRef;
  @ViewChild('msdsLink', { static: true }) msdsLink: ElementRef;

  private subscription = new Subscription();
  public cols: Array<GridHeader> = [];
  public dataArray: Array<ItemMasterInventory>;
  public selectedColumns: Array<GridHeader>;
  public dataObj: ItemMasterInventory = undefined;
  public isDeleteDetails = false;
  public isAddDetails = false;
  public isEditDetails = false;
  public isViewDetails = false;

  public CountryList: Array<SelectItem> = [];
  public selectedcountry: SelectItem;
  public gradeList: Array<SelectItem> = [];
  public selectedGrade: SelectItem;
  public itemClassList: Array<SelectItem> = [];
  public selectedClass: SelectItem;
  public formList: Array<SelectItem> = [];
  public selectedForm: SelectItem;
  public showPDF = false;
  public pdfSrc = '';

  private editedItemsList: Array<number> = [];
  public UOMList: Array<SelectItem> = [];
  public selectedUOM: SelectItem;


  public set isEditShow(value: boolean) {
    this.isEditDetails = value;
    this.isAddDetails = value;
  }

  public get isEditShow() {
    return this.isEditDetails || this.isAddDetails;
  }

  public get isCreator() {
    return this.util.getUserRole === UserType.Creator;
  }

  public get dlgHeader() {
    return (this.isViewDetails ? 'View ' : (this.isEditDetails ? 'Edit ' : 'Add ')) + this.pageName;
  }

  /** grid columns and data fields */
  private columnsConfig: Array<GridHeaderEx> = [
    { field: 's_no', header: 'S No', isVisible: true, isDefault: true },
    { field: 'itemCode', header: 'Code', isVisible: true, isDefault: true },
    { field: 'itemDesc', header: 'Desc', isVisible: true, isDefault: true },
    { field: 'itemFormDesc', header: 'Form', isVisible: true, isDefault: true },
    { field: 'itemCategoryDesc', header: 'Category', isVisible: true, isDefault: true },
    { field: 'itemGradeDesc', header: 'Grade', isVisible: true, isDefault: true },
    { field: 'itemClassDesc', header: 'Class', isVisible: true, isDefault: true },
    { field: 'stkqty', header: 'Stock as on Date', isVisible: true, isDefault: true },
    { field: 'uom', header: 'UOM', isVisible: true, isDefault: true },
    { field: 'itmunit_price', header: 'Unit Price', isVisible: true, isDefault: true },
    { field: 'itemCurrencyCode', header: 'Currency', isVisible: true, isDefault: true },
  ];

  public get pageName() {
    return this.dataService.pageName;
  }
  private clonedRecord: { [s: string]: ItemMasterInventory; } = {};
  constructor(
    private msgSvc: MsgService,
    private dataService: ItemMasterInventoryService,
    public util: UtilService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.dataObj = Object.assign({}, defaultItemMasterInventory);

    this.cols = this.columnsConfig.filter(i => i.isVisible)
      .map((e: GridHeaderEx) => {
        return { field: e.field, header: e.header };
      });

    // // this.selectedColumns = this.columnsConfig
    // //   .map((e: GridHeaderEx) => {
    // //     return { field: e.field, header: e.header };
    // //   });
    this.dataService.getDataRecords();
    this.subscription.add(
      this.dataService.dataList.subscribe((data) => {
        this.dataArray = [];
        if (data.length) {
          this.dataArray = data
            .map((e, indx) => {
              e.s_no = indx + 1;
              e['canEdit'] = true;
              return e;
            });
        } else {
          this.msgSvc.addInfoMsg({ summary: this.pageName, detail: 'No data exit' });
        }
      }));

    this.loadDropDownLists();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public roleActionType() {
    return this.util.roleActionType();
  }

  public getStatusValue(status: number) {
    return this.util.statusValue(status);
  }

  private loadDropDownLists() {

      this.dataService.getCountryList()
      .subscribe(data => {
        this.CountryList = data.map(ele => {
          return { label: ele.currency_code, value: ele };
        });
      });
    this.dataService.getUOMCodes()
      .subscribe(data => {
        this.UOMList = data.map(ele => {
          return { label: ele.uomCode, value: ele };
        });
    });
  }

  public onUmoChange(row,event) {
    row.uom = (event.value as ProductUOM).uomCode;
    var x = (event.value as ProductUOM).uomId;
    var y: number = +x;
    row.uomId = y;
    console.log(this.dataObj);
  }
  public onCountryChange(row,event) {
    console.log(event);
    row.itm_currency = event.value.currency_id ;
  }


  public isFormValid() {
    let result = false;
    if (this.dataObj.itemDesc.trim() === '' ||
      this.dataObj.itemCategoryId === 0 ||
      this.dataObj.itemGradeId === 0 ||
      this.dataObj.itemFormId === 0 ||
      this.dataObj.itemClassId === 0 ||
      this.dataObj.hsnCode.trim() === '' ||
      this.dataObj.specLink === undefined ||
      this.dataObj.msdsLink === undefined) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }
  public onRowEditInit(row) {
    this.selectedcountry = row.itm_currency;
    this.selectedUOM = row.uom;
    this.clonedRecord[row.s_no] = { ...row };
  }

  public onRowEditSave(row, index: number) {
    var x = row.stkqty;
    var y: number = +x;
    const stkqty = y ;
    if (stkqty > 0 ) {
      delete this.clonedRecord[row.s_no];
      if (!this.editedItemsList.some(e => e === row.s_no)) {
        this.editedItemsList.push(row);
       
      }
      const itemid = row.itemId;
      const stkqty = row.stkqty;
      const uomId = row.uomId;
      const itmunit_price = row.itmunit_price;
      const itm_currency = row.itm_currency;
     // const editedList = this.getListOfItemsToSave();
      console.log( this.editedItemsList);
      
      this.dataService.saveDraft(itemid,stkqty,uomId,itmunit_price,itm_currency).subscribe(val => { this.editedItemsList = []; });
      this.dataService.getDataRecords();
    } else {
      this.onRowEditCancel(row, index);
      this.msgSvc.addWarnMsg({ summary: this.pageName, detail: 'Invalid Stock Quantity' });
    }
  }

  public onRowEditCancel(row, index: number) {
    this.dataArray[index] = this.clonedRecord[row.s_no];
    delete this.clonedRecord[row.s_no];
  }


  private resetFormData() {
    this.isViewDetails = false;
    this.isEditShow = false;
    this.specLink.nativeElement.value = '';
    this.msdsLink.nativeElement.value = '';
    this.selectedGrade = undefined;
    this.selectedcountry = undefined;
    this.selectedForm = undefined;
    this.selectedClass = undefined;
    this.dataService.getDataRecords();
    this.dataObj = Object.assign({}, defaultItemMasterInventory);
  }


}
