export interface ProductParameter {
    s_no?: number;
    parameterId: number;
    parameterCode: string;
    parameterDesc: string;
    status: number;
}

export const defaultProductParameter = {
    parameterId: 0,
    parameterCode: '',
    parameterDesc: '',
    status: 0
};
