export interface EquipmentOperatingTiming {
    s_no?: number;
    id: number;
    equipmentName?: string;
    equipmentAvailabile: number;
    equipmentId: number;
    equipmentShifts: string;
    status: number;
    isSelected: boolean;
    nonAvailableReason: number;
    hoursNormalWageRate: number;
    hoursOvertimeWageRate: number;
}


export const defaultEquipmentOperatingTiming: EquipmentOperatingTiming = {
    id: 0,
    equipmentId:0,
    equipmentAvailabile: 0,
    equipmentName: '',
    equipmentShifts: '',
    isSelected:false,
    status: 0,
    nonAvailableReason: 0,
    hoursNormalWageRate: 0,
    hoursOvertimeWageRate: 0,
};
